import { Component } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {Router} from "@angular/router";
import he from "he";
import {DataService} from "../../../service/data.service";
import {CustomValidators} from "../membre/input/CustomValidators";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-inscription-confirmee-cours',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  protected resetPasswordForm!:FormGroup;
  protected buttonStatus : 'loading' | 'idle' = 'idle';

  constructor(private fb : FormBuilder,
              private dataService: DataService,
              private router:Router) {
    // if (this.selectedMember == null || this.selectedCours == null || this.connectedAccount == null) {
    //   this.router.navigateByUrl("/");
    // }
  }

  async ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      email: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100}),
        CustomValidators.email()]],
    })
    // -> pour remplir les champs
    // this.registerForm.patchValue({
    //   email: 'test@test',
    // });
  }

  validateForm() {
    // mise à jour des états
    this.submitted = true;
    this.buttonStatus = "loading";
    this.sendEmail();
  }

  sendEmail(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.sendMailResetPassword(this.resetPasswordForm.get("email")?.value)
        .then((response: any) => {
          // Handle success if needed
          this.router.navigateByUrl(`connexion?reset-password=true`);
          // resolve();
        })
        .catch((error: any) => {
          console.log(error.error.message)
          const validator = CustomValidators.displayError(error.error.message);
          this.resetPasswordForm.get('email')?.addValidators(validator);
          this.resetPasswordForm.get('email')?.updateValueAndValidity();
          this.resetPasswordForm.get('email')?.removeValidators(validator)
          // reject(error);
        })
        .finally(() => {
          this.buttonStatus = "idle";
        });
    });
  }

  protected submitted: boolean = false;
}
