import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";

export class CustomValidators {
  public static required(message?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value === null || value === undefined || value === '') {
        return { required: { message : message??
        "Ce champ est requis"
        } }; // Utilisez le message passé en paramètre
      } else {
        return null;
      }
    };
  }
  public static validateDateOfBirth(group: AbstractControl): ValidationErrors | null {
    const day = group.get('day')?.value;
    const month = group.get('month')?.value;
    const year = group.get('year')?.value;

    if (!day || !month || !year) {
      return { validateDateOfBirth: { message: 'Date incomplète' }};
    }

    const date = new Date(year, month - 1, day);
    if (date.getFullYear() !== Number(year) || date.getMonth() !== Number(month - 1) || date.getDate() !== Number(day)) {
      return { validateDateOfBirth: { message: 'Veuillez entrer une date valide' } };
    }

    // Vérifier si la date est dans le futur
    const today = new Date();
    if (date > today) {
      return { validateDateOfBirth: { message: 'La date de naissance ne peut pas être dans le futur' } };
    }

    // Date valide
    return null;
  }

  static minmax({min, max, message}:{min?: number, max?: number, message?: string}) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const isSmaller = value <= (min??-Infinity);
      const isBigger = value >= (max??Infinity);

      if (isSmaller || isBigger) {
        let mes;
        if (isSmaller && !message) {
          mes = message??`Le champ ne doit pas être inférieur à ${min}.`;
        }
        if (isBigger && !message) {
          mes = message??`La valeur ne doit pas être supérieur à ${max}.`;
        }

        return { minmax: { message : mes} }; // Utilisez le message passé en paramètre
      } else {
        return null;
      }
    };
  }
  static min(min: number, message?: string) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const isSmaller = value < min;

      if (isSmaller) {
        const mes = message ?? `La valeur ne doit pas être inférieure à ${min}.`;
        return { min: { message: mes } };
      }

      return null; // Si la valeur est supérieure ou égale à min
    };
  }
  static max(max: number, message?: string) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const isBigger = value > max;

      if (isBigger) {
        const mes = message ?? `La valeur ne doit pas être supérieure à ${max}.`;
        return { max: { message: mes } };
      }

      return null; // Si la valeur est inférieure ou égale à max
    };
  }
  static numNat() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const pattern = /^\d{2}\.\d{2}\.\d{2}-\d{3}\.\d{2}$/; // Define the desired pattern
      if (value === '') return null;

      if (!pattern.test(value)) {
        return { numNat: { message: `Le numéro national doit correspondre au format 'XX.XX.XX-XXX.XX'.` } };
      } else {
        return null;
      }
    };
  }

  static maxLen({length, message}:{length: number, message?: string}) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value !== null && value !== undefined && value.length > length) {
        return { max: { message : message??
              `Le champ est trop long. Il ne doit pas excèder ${length} caractères.`
          } }; // Utilisez le message passé en paramètre
      } else {
        return null;
      }
    };
  }

  static email(message?: string) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value !== null && value !== undefined && Validators.email(control)) {
        return { email: { message : message??
              `L'email n'est pas valide.`
          } }; // Utilisez le message passé en paramètre
      } else {
        return null;
      }
    };
  }

  // Vérifie si les mots de passe sont égaux
  static confirmPassword(passwordControlName: string, message?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // const value = control.value;
      const passwordControl = control.parent?.get(passwordControlName);
      const confirmPasswordControl = control;

      if (passwordControl && confirmPasswordControl && passwordControl.value !== confirmPasswordControl.value) {
        return { pass: { message : message??
              `Les mots de passe ne correspondent pas.`
          } }; // Utilisez le message passé en paramètre
      } else {
        return null;
      }
    };
  }

  static displayError(message?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (message) {
        return { max: { message : message} }; // Utilisez le message passé en paramètre
      } else {
        return null;
      }
    };
  }

  static possibleValue(postCodeData: PostCodeData[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && postCodeData) {
        const formattedValue = `${value.LOCALITE} (${value.CODE_POSTAL})`;
        let isValid = postCodeData.some(entry => {
          const entryFormatted = `${entry.LOCALITE} (${entry.CODE_POSTAL})`;
          return entryFormatted === formattedValue;
        });
        if (!isValid) {
          return { possibleValue: { message: 'La valeur n\'est pas valide.' } };
        }
      }
      return null;
    };
  }
}

interface PostCodeData {
  NUMERO_CP: number;
  CODE_POSTAL: string;
  LOCALITE: string;
}

