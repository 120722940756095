export class SelectionMember {
  numero_personne: number;
  nom: string;
  prenom: string;
  date_naissance: Date;
  date_start: Date;
  age_start: Number;
  age_start_millis: Number;

  constructor(numero_personne = 0, nom = '', prenom = '', date_naissance = new Date(), dateStart:Date = new Date()) {
    this.numero_personne = numero_personne;
    this.nom = nom;
    this.prenom = prenom;
    this.date_naissance = date_naissance;
    this.date_start = dateStart;
    this.age_start = this.getAge()
    this.age_start_millis = this.getAgeMillis()
  }

  toString() {
    return this.nom + " " + this.prenom;
  }

  //age au début du stage
  getAge() {
    let ageDifMs = this.getAgeMillis();
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  getAgeMillis(): number {
    return this.date_start.getTime() - this.date_naissance.getTime();
  }
}
