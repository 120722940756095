import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../input/CustomValidators";
import {DataService} from "../../../../service/data.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PopupModalComponent} from "../../../common/popup-modal/popup-modal.component";
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {Member} from "../../../../models/Member";
import {NationalNumberPipe} from "../../../../pipes/national-number.pipe";
import {throwError} from "rxjs";


@Component({
  selector: 'app-modification-membre',
  templateUrl: './modification-membre.component.html',
  styleUrls: ['./modification-membre.component.css', '../../../common/styles/common.css']
})
export class ModificationMembreComponent implements OnInit, AfterViewInit {

  registerForm!:FormGroup;
  protected buttonStatus : 'loading' | 'idle' = 'idle';
  private loadingDialog!: MatDialogRef<any, any>;
  member: Member = new Member();

  protected readonly onsubmit = onsubmit;
  protected readonly JSON = JSON;

  constructor(private formBuilder : FormBuilder,
              private dataService: DataService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              private router: Router,
              private numNatPipe: NationalNumberPipe) {
  }

  // Fonction pour formater la date au format 'YYYY-MM-DD'
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Les mois vont de 0 à 11, donc +1 et formatage sur 2 chiffres
    const day = date.getDate().toString().padStart(2, '0'); // Formatage sur 2 chiffres

    return `${year}-${month}-${day}`;
  }

  ngOnInit(): void {
    this.member = JSON.parse(sessionStorage.getItem("selectedMember") || '{}');
    const dateDeNaissance = this.member && this.member.date_naissance ? new Date(this.member.date_naissance) : null;
    // const formattedDate = dateDeNaissance ? this.formatDate(dateDeNaissance) : null;

    // Séparer la date de naissance en jour, mois et année
    const day = dateDeNaissance ? dateDeNaissance.getDate() : null;
    const month = dateDeNaissance ? dateDeNaissance.getMonth() + 1 : null; // +1 car les mois sont indexés à partir de 0
    const year = dateDeNaissance ? dateDeNaissance.getFullYear() : null;

    // Création du formulaire
    this.registerForm = this.formBuilder.group({
      nom: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      prenom: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      // Champs pour la date de naissance décomposée
      dateNaissance: this.formBuilder.group({
        day: [day || '', CustomValidators.required()],
        month: [month || '', CustomValidators.required()],
        year: [year || '', CustomValidators.required()]
      }, {
        validator: CustomValidators.validateDateOfBirth
      }),
      // dateNaissance_day: [day || '', [
      //   CustomValidators.required()]],
      // dateNaissance_month: [month || '', [
      //   CustomValidators.required()]],
      // dateNaissance_year: [year || '', [
      //   CustomValidators.required()]],
      genre: ['', [
        CustomValidators.required()]],
      numNat: [this.member ? this.numNatPipe.transform(this.member.numero_national) : '', [
        CustomValidators.required(),
        CustomValidators.numNat()
      ]],
      email: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100}),
        CustomValidators.email()]],
      gsm: ['', [
        CustomValidators.required()]],
      sportPratique: ['', [
        CustomValidators.maxLen({length: 5000})]],
      commentaire: ['', [
        CustomValidators.maxLen({length: 5000})]],
      medecinTraitant_nom: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      medecinTraitant_tel: ['', [
        CustomValidators.required(),
      ]],
      anneeDernierRappelTetanos: ['', [
        CustomValidators.required(),
        CustomValidators.minmax({min: this.getCurrentYear() - 100, max: this.getCurrentYear()}),
      ]],
      allergies: ['', [
        CustomValidators.maxLen({length: 5000})]],
      medicaments: ['', [
        CustomValidators.maxLen({length: 5000})]],
      donnees_medicales_specifiques: ['', [
        CustomValidators.maxLen({length: 5000})]],
    })

    // Préremplissage des champs avec les données du membre
    this.registerForm.patchValue({
      // Infos personnelles
      nom: (this.member==null? "" : this.member.nom),
      prenom: (this.member==null? "" : this.member.prenom),
      // dateNaissance_day: day,
      // dateNaissance_month: month,
      // dateNaissance_year: year,
      dateNaissance: {
        day: day,
        month: month,
        year: year
      },
      genre: (this.member==null? "" : this.member.sexe),
      numNat: this.numNatPipe.transform(this.member.numero_national),
      email: (this.member==null? "" : this.member.email),
      gsm: (this.member==null? "" : this.member.gsm),
      sportPratique: (this.member==null? "" : this.member.sport_pratique),
      commentaire: (this.member==null? "" : this.member.commentaires),
      // Infos médicales
      medecinTraitant_nom: (this.member==null? "" : this.member.nom_medecin),
      medecinTraitant_tel: (this.member==null? "" : this.member.gsm_medecin),
      anneeDernierRappelTetanos: (this.member==null? "" : this.member.annee_tetanos),
      allegie: (this.member==null? "" : this.member.allergies),
      medicaments: (this.member==null? "" : this.member.medicaments),
      donneesMedicalSpecifiques: (this.member==null? "" : this.member.remarques_medicales),
    });
  }

  ngAfterViewInit() {
  }

  validateForm() {
    // Mark all controls as touched to trigger validation messages
    this.registerForm.markAllAsTouched();

    // Check if any of the form controls have invalid validators
    const invalidControls = Object.keys(this.registerForm.controls).filter(controlName =>
      this.registerForm.get(controlName)?.invalid
    );

    this.registerForm.patchValue({
      numNat: (this.registerForm.get("numNat")?.getRawValue().replace(/[.\-\s]/g, '')),
      nom: (this.registerForm.get('nom')?.getRawValue().toUpperCase()),
    })

    // If there are invalid controls, handle them accordingly
    if (invalidControls.length > 0) {
      // Handle the case when there are invalid controls
      console.log("Invalid validators found:", invalidControls);
      // You can show a message or perform any other action here
    } else {
      // All validators are valid, proceed with form submission
      this.submitForm();
    }
  }

  submitForm() {

    // mise à jour des états
    this.submitted = true;
    this.buttonStatus = "loading";

    // Fenêtre de chargement
    this.loadingDialog = this.dialog.open(PopupModalComponent, {
      disableClose: true,
      panelClass: 'loading-dialog-overlay',
      data: {
        titre: 'Chargement',
        width: 100,
      },
    });

    this.sendRequestEditMember(this.member.numero_compte, this.member.email);
  }

  protected readonly Object = Object;
  protected readonly console = console;
  protected readonly Array = Array;
  protected readonly Date = Date;
  protected submitted: boolean = false;

  protected getCurrentYear() : number {
    return new Date(Date.now()).getFullYear();
  }

  private sendRequestEditMember(numCompte: number, loginEmail: string) {

    // ENVOIE REQUETE VERS LE BACK-END
    this.dataService.updateMemberData((this.member==null? 0 : this.member.numero_personne),
      this.member.numero_compte, this.registerForm.value).subscribe(
      response => {
        if (response.status === 'ok') {
          this.toastr.success( "Le membre a été modifié avec succès", "Membre modifié", {
            timeOut: 5000,
            progressBar: true,
            closeButton: true,
            enableHtml: true,
            positionClass:"toast-top-full-width"
          });
          this.router.navigateByUrl('gestion-compte');
        }
      },
      error => {
        this.toastr.error( "Erreur lors de la modification du membre", "Modification membre", {
          timeOut: 5000,
          progressBar: true,
          closeButton: true,
          enableHtml: true,
          positionClass:"toast-top-full-width"
        });
        this.loadingDialog.close();
        this.buttonStatus = "idle";
      },
      () => {
        this.loadingDialog.close();
        this.buttonStatus = "idle";
      });
  }

  protected readonly throwError = throwError;
  protected readonly AbstractControl = AbstractControl;
}
