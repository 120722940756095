<main>
  <div class="back_element mx-3">
    <i class="fa-solid fa-chevron-left"></i>
<!--    <a routerLink="/inscription.stages.preference" class="link-secondary m-1 fw-bolder">Etape 2/3 : Ajouter vos préférences</a>-->
    <a (click)="togglePopup()" class="link-secondary m-1 fw-bolder">Etape 2/3 : Ajouter vos préférences</a>
    <app-confirmation-pop-up *ngIf="showPopup" [title]="'Retour'" [message]="'Êtes-vous sûr de vouloir revenir en au point précédent sans confirmer ?'" (close)="togglePopup()" (confirm)="goBack()"></app-confirmation-pop-up>
    <!--TODO verifier si c'est bien la bonne méthode de rooting -->
  </div>

  <h1 class="text-center fw-bold mt-3" [innerHTML]="'Etape 3/3'"></h1>
  <h2 class="text-center">{{this.stageName}}</h2>

  <hr>

  <h2 class="text-center fw-bold mt-3">Valider l'inscription</h2>
  <section class="text-center mt-3" *ngIf="!isPageLoaded()">
    <br><br><br><br><br><br> <!-- c'est pas bien, je sais... -->
    <div [ngClass]="{'spinner-border spinner-border-sm ': true}"></div>
    <div>Chargement...</div>
  </section>

  <section *ngIf="isPageLoaded()">
    <section *ngIf="!this.isReservation">
      <section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
        <!--    Montant-->
        <p *ngIf="this.reduction != null && this.reduction > 0">Vous bénéficiez d'une réduction de <b>{{this.reduction | currency:'EUR'}}</b>
          car vous avez déjà inscrit {{this.stageOriginPersonPrenom}} {{this.stageOriginPersonNom}} dans le stage {{this.stageOrigin?.name}} à la même période.</p>
        <section class="montant">
          <h3 class="fw-bold blue">Montant du Stage :</h3>
          <section>
            <p>{{this.priceWanted | currency:'EUR'}}</p>
          </section>
        </section>

        <!--    Acompte-->

        <section class="montant">
          <h3 class="fw-bold blue">Acompte :</h3>
          <section>
            <p>{{30 | currency:'EUR'}}</p>
          </section>
        </section>
        <!--    info acompte-->
        <section>
          <p>
            - Acompte à faire par versement sur le compte <b>BE23 0012 3458 6391</b>
          </p>
          <p>Avec en communication:</p>
          <section>
            <p class="encadre">
              STAGE : {{this.selectedMember.nom | uppercase}} - {{this.selectedMember.prenom}} - {{this.stageName}}
              - {{this.selectedStage.dateStart | date : 'yyyy-MM-dd'}}
            </p>

          </section>
          <p>A payer dans les 5 jours suivant l'inscription !</p>

        </section>
      </section>
      <!--    solde-->
      <section class="solde shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
        <h3 class="fw-bold blue">Solde</h3>
        <p>
          Il restera alors <b>{{this.priceAfterAcompt | currency:'EUR'}}</b> à payer :
        </p>
        <p>- Soit par virement <b>10 jours</b> avant le début du stage (conseillé)</p>
        <p>- Soit en liquide le premier jour du stage.</p>
      </section>
      <!--    divers-->
      <section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
        <h3 class="fw-bold blue">Divers</h3>
        <p>
          En souvenir du stage, des photos seront prises. Elles seront exposées dans la galerie de notre site, rubrique
          'Stages'.
        </p>
        <p>
          Si vous ne souhaitez pas voir figurer votre enfant sur ces photos, veuillez nous envoyer un mail à <b>info@dimension-sport.be</b>
        </p>
      </section>
    </section>
    <section *ngIf="this.isReservation">
      <section class="solde shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
        <h3 class="fw-bold blue">Inscription en liste d'attente</h3>
        <p>
          Nous prendrons en compte votre inscription comme réserve pour le stage <strong>{{this.stageName}}</strong> du <strong>{{this.selectedStage.dateStart | date :'dd-MM-YYYY'}}</strong> au <strong>{{this.selectedStage.dateEnd | date: 'dd-MM-YYYY'}}</strong>.
        <br>
          Vous figurerez sur la liste d'attente et vous serez prévenu dès qu'une place se libèrera.
        </p>
        <p>
          <u>Cliquez sur le bouton pour finaliser votre inscription comme réserve.</u>
        </p>
      </section>
    </section>
  </section>
  <app-floating-button *ngIf="isPageLoaded()"
    [text]="getButtonMessage()"
    (onClick)="handleForm()"
  >
  </app-floating-button>

</main>
