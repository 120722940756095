import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../../../../../service/data.service";
import he from 'he';
import {InscriptionStage} from "../../../../../models/inscriptionStage";
import {StageDetails} from "../../../../../models/StageDetails";
import {Member} from "../../../../../models/Member";
import {Account} from "../../../../../models/Account";
import {Clipboard} from "@angular/cdk/clipboard";
import {FooterService} from "../../../../common/footer/footer.service";
import {StageRecapLoadingStatus} from "./StageRecapLoadingStatus";
import {LoadingStatus} from "../../../../../models/FormLoadingStatus";


@Component({
  selector: 'app-recapitulatif',
  templateUrl: './recapitulatif.component.html',
  styleUrls: ['./recapitulatif.component.css']
})

export class RecapitulatifComponent implements OnInit {
  @ViewChild('communicationText') communicationText: any;


  protected selectedStage: StageDetails = JSON.parse(sessionStorage.getItem('selectedStage') || '{}');
  protected selectedMember: Member = JSON.parse(sessionStorage.getItem('selectedMember') || '{}');
  protected inscriptionInfos: InscriptionStage = JSON.parse(sessionStorage.getItem('inscriptionStage') || '{}');
  protected connectedAccompt: Account = JSON.parse(localStorage.getItem('selectedCompte') || '{}');

  protected priceWanted: number = -1;
  protected priceAfterAcompt?: number;

  protected stageName!: string;
  protected reduction: number | null = null;
  protected isThereAPromo: boolean = false;
  protected isReservation: boolean = false;

  protected stageOriginId: number = -1;
  protected stageOrigin: StageDetails | undefined;
  protected stageOriginPersonPrenom: string | undefined;
  protected stageOriginPersonNom: string | undefined;

  protected showPopup = false

  protected loadingStatus = new StageRecapLoadingStatus();

  constructor(private clipboard: Clipboard, private router: Router, private dataService: DataService, private footerService: FooterService) {
  }

  async ngOnInit() {
    if(Object.keys(this.selectedStage).length == 0) {
      this.router.navigateByUrl("/");
    } else {
      this.footerService.setFooterVisibility(true);
      this.priceWanted = this.getWantedPrice();
      // console.log('Date start : ' + this.selectedStage.dateStart);
      this.stageName = he.decode(this.selectedStage.name);

      this.isReservation = this.selectedStage.remainingSlots <= 0;
      // console.log("L'inscription est en reserve ?: " + this.isReservation);
      this.isThereAPromo = await this.promotionHandler();

      this.inscriptionInfos.priceAfterReduc = this.priceWanted;
      if (this.isThereAPromo) {
        // console.log('There\'s a promotion !');
        await this.getStageData();
      } else {
        this.loadingStatus.gettingStageData = LoadingStatus.LOADED;
        this.inscriptionInfos.reduction = 0;
      }
      sessionStorage.setItem('inscriptionStage', JSON.stringify(this.inscriptionInfos));
      sessionStorage.setItem('reservationStage', JSON.stringify(this.isReservation));
      this.priceAfterAcompt = this.priceWanted - 30;

      // console.log('RecapitulatifComponent - ngOnInit() : isThereAPromo : ' + this.isThereAPromo);
    }
  }

  private async promotionHandler(): Promise<boolean> {
    // console.log('RecapitulatifComponent - promotionHandler() : dateStart : ' + this.selectedStage.dateStart);
    // console.log('RecapitulatifComponent - promotionHandler() : dateEnd : ' + this.selectedStage.dateEnd);

    return new Promise<boolean>((resolve) => {
      this.dataService.PromotionAmountStage(
        this.connectedAccompt.numero_compte,
        this.inscriptionInfos.periode_journee,
        new Date(this.selectedStage.dateStart),
        new Date(this.selectedStage.dateEnd)
      ).subscribe({
        next: (response: StagePromotionResponse) => {
          if (response && response.reduction) {
            // Promotion available
            console.log('Promotion available');
            if (response.prix_plus_bas !== undefined && response.montant_reduction !== undefined) {
              // console.log('Lowest price: ', response.prix_plus_bas);
              // console.log('Reduction amount: ', response.montant_reduction);
              this.reduction = response.montant_reduction;
              this.priceWanted -= this.reduction;
              this.inscriptionInfos.reduction = this.reduction;
              this.inscriptionInfos.priceAfterReduc = this.priceAfterAcompt;
              this.stageOriginId = response.stage_correspondant;
              this.stageOriginPersonPrenom = response.personne_prenom;
              this.stageOriginPersonNom = response.personne_nom;
              sessionStorage.setItem('inscriptionInfos', JSON.stringify(this.inscriptionInfos));
            }
            // Here you can perform actions based on the promotion availability
            this.loadingStatus.promotionHandling = LoadingStatus.LOADED;
            resolve(true);
          } else {
            // No promotion available or error
            // console.log('No promotion available or error');
            this.loadingStatus.promotionHandling = LoadingStatus.LOADED;
            resolve(false);
          }
        },
        error: (error) => {
          // Error occurred
          console.error('Error fetching promotion data:', error);
          this.loadingStatus.promotionHandling = LoadingStatus.ERROR;
          resolve(false);
        }
      });
    });
  }





getWantedPrice(): number {
    let finalprice: number;
    if (this.inscriptionInfos.periode_journee == 'PM' || this.inscriptionInfos.periode_journee == 'AM') {
      finalprice = this.selectedStage.halfDayPrice;
    } else if (this.inscriptionInfos.periode_journee == 'JC') {
      finalprice = this.selectedStage.price;
    } else {
      return -1;
    }
    return finalprice;
  }

  async handleForm() {
    // TODO Soulever les erreurs en cas de requette ratée
    try {
      await this.addInscriptionToBD();
      // console.log("bonjour tout vas bien, j'aim es 10 doigts mes deux mains")
      await this.updateMedicalInformations();
      this.router.navigateByUrl("/inscription.stages.inscription-confirmee");
    } catch (error) {
      console.error(error);
      // Handle the error accordingly
    }
  }

  async addInscriptionToBD(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let idStage: number = this.selectedStage.id;
      let idPersonne: number = this.selectedMember.numero_personne;
      let periodOfDay: string = this.inscriptionInfos.periode_journee;
      let montantReduction: number | undefined = this.inscriptionInfos.reduction;
      let tailleTshirt: string | undefined = this.inscriptionInfos.tailleTShirt;
      let copains: string = this.inscriptionInfos.copain;
      let commentaires: string = this.inscriptionInfos.commentaire_copain;
      let employeur: string = this.selectedMember.profession_parents;
      // console.log('request start');

      this.dataService.addInscriptionStage(this.isReservation ,idStage, idPersonne, periodOfDay, montantReduction, tailleTshirt, copains, commentaires, employeur).subscribe({
        next: () => {
          // Handle the success data if needed
          // console.log('request success');
          resolve();
        },
        error: (err) => {
          // Handle the error here
          // console.error('request error', err);
          reject(err);
        },
        complete: () => {
          // console.log('request complete');
        }
      });
    });
  }


  private async updateMedicalInformations() {
    return new Promise<void>((resolve, reject) => {
      // console.log('request start');
      // console.log(this.inscriptionInfos);
      let idPersonne: number = this.selectedMember.numero_personne;
      let medecin: string = this.inscriptionInfos.info_medical.medecin;
      let gsm: string = this.inscriptionInfos.info_medical.gsm;
      let annee_tetanos: number = this.inscriptionInfos.info_medical.annee_tetanos;
      let allergies: string = this.inscriptionInfos.info_medical.allergies;
      let medicaments: string = this.inscriptionInfos.info_medical.medicaments;
      let remarques: string = this.inscriptionInfos.info_medical.remarques;

      this.dataService.updateMedicalInformations(idPersonne, medecin, gsm, annee_tetanos, allergies, medicaments, remarques).subscribe({
        next: () => {
          // Handle the success data if needed
          // console.log('request success');
          resolve();
        },
        error: (err) => {
          // Handle the error here
          // console.error('request error', err);
          reject(err);
        },
        complete: () => {
          // console.log('request complete');
        }
      });
    });
  }

  getButtonMessage() {
    if (this.isReservation) {
      return "Finaliser l'inscription en reserve";
    } else {
      return "Finaliser l'inscription";
    }
  }

  async getStageData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getStageDetails(this.stageOriginId).subscribe({
        next: (res:any) => {

          this.stageOrigin = new StageDetails(
            this.stageOriginId,
            he.decode(res.NOM),
            res.DESCRIPTIF,
            res.T_SHIRT,
            new Date(res.DATE_DEBUT),
            new Date(res.DATE_FIN),
            res.NOMBRE_JOURS,
            res.HEURE_DEBUT,
            res.HEURE_FIN,
            res.AGE_MIN,
            res.AGE_MAX,
            res.PRIX_JOURNEE,
            res.PRIX_DEMI_JOURNEE,
            res.REMAINING_SLOTS,
            res.NOM_LIEU,
            res.RUE,
            res.NUMERO,
            res.CODE_POSTAL,
            res.LOCALITE,
            res.GOOGLE
          )
          this.loadingStatus.gettingStageData = LoadingStatus.LOADED;
          resolve();
        },
        error: (error) => {
          this.loadingStatus.gettingStageData = LoadingStatus.ERROR;
          reject(error);
        }
      });
    });
  }

  togglePopup(){
    this.showPopup = !this.showPopup;
  }

  goBack(){
    this.router.navigateByUrl("/inscription.stages.preference");
  }

  // Fonction pour copier le texte dans le presse-papiers
  copyToClipboard() {
    const textToCopy = this.communicationText.nativeElement.innerText;
    this.clipboard.copy(textToCopy);
    alert("La communication a été copiée dans le presse-papiers !");
  }

  isPageLoaded() {
    return this.loadingStatus.gettingStageData !== LoadingStatus.LOADING && this.loadingStatus.promotionHandling !== LoadingStatus.LOADING;
  }
}

// Define an interface to represent the response structure
interface StagePromotionResponse {
  personne_prenom: string;
  personne_nom: string;
  reduction: boolean;
  prix_plus_bas?: number; // Optional property
  montant_reduction?: number; // Optional property
  stage_correspondant: number; // Optional property
}
