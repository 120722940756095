import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../service/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseDetails} from "../../../models/CourseDetails";
import * as he from "he";

@Component({
  selector: 'app-cours-details',
  templateUrl: './cours-details.component.html',
  styleUrls: ['./cours-details.component.css',
    '../../common/styles/common.css']
})
export class CoursDetailsComponent implements OnInit {
  id!:number
  cours: CourseDetails = new CourseDetails()
  readMore: boolean = false;
  initialized = false;

  constructor(private dataService:DataService, private route: ActivatedRoute, private router:Router) {

  }

  async ngOnInit() {
    // TODO gérer le cas d'un id incorrecte ou innaccessible
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    await this.getCoursData();
    // console.log(this.cours.dateStart1);
    // console.log(this.cours.dateEnd);
  }

  onReadMore() {
    this.readMore = !this.readMore
  }

  getCoursData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getCoursDetails(this.id).subscribe({
        next: (res:any) => {
          console.log(res)
          this.cours = new CourseDetails(
            this.id,
            he.decode(res.NOM),
            res.DESCRIPTIF,
            res.JOUR,
            res.HEURE,
            new Date (res.DATE_DEBUT), // Date debut1
            new Date (res.DATE_DEBUT), // Date debut2
            new Date (res.DATE_FIN),
            res.AGE_MIN,
            res.AGE_MAX,
            res.PRIX,
            res.PRIX_AU_SEMESTRE,
            res.REMAINING_SLOTS,
            res.NOM_LIEU,
            res.RUE,
            res.NUMERO,
            res.CODE_POSTAL,
            res.LOCALITE,
            res.GOOGLE,
            res.FORCER_COMPLET === 1,
            res.FORCER_COMPLET_SEMESTRE1 === 1,
            res.FORCER_COMPLET_SEMESTRE2 === 1,
            res.FORCER_COMPLET_TRIMESTRE === 1,
            res.FORCER_COMPLET_MOIS === 1
          )
          this.initialized = true
          resolve();
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  OnRegister() {
    sessionStorage.setItem("selectedCours", JSON.stringify(this.cours));
    if(localStorage.getItem('selectedCompte')){
      this.router.navigateByUrl(`inscription.cours.selection-membre-cours`);
    }else{
      this.router.navigateByUrl(`connexion`);
    }
  }

  protected readonly encodeURIComponent = encodeURIComponent;

  isFilled() {
    return this.cours.remainingSlots <= 0 || this.isForceFilled();
  }

  isNearlyFilled() {
    // not implemented yet
    return this.cours.remainingSlots <= 3 && this.cours.remainingSlots > 0;
  }

  private isForceFilled() {
    return this.cours.isFillForced || this.isPartiallyForceFilled()
  }

  private isPartiallyForceFilled() {
    return this.isAYoungChildCourse() && (this.cours.isFillForcedSem1 || this.cours.isFillForcedSem2)
  }

  private isAYoungChildCourse() {
    return true;
  }

}
