export class StageCard {
  public Id: number;
  public Title: string;
  public Age: string;
  public Place: string;
  public Period: string;
  public Date: string;
  public RemainingSlots: number;
  PrixDemiJournee: number;
  isFillForced: boolean;
  isFillForcedAm: boolean;
  isFillForcedPm: boolean;

  constructor(Id: number = 0, title: string = "", age: string = "", place: string = "", period: string = "",
              date: string = "", RemainingSlots: number = 0, PrixDemiJournee: number = 0,
              isFillForced: boolean = false, isFillForcedAm: boolean = false, isFillForcedPm: boolean = false) {
    this.Id = Id;
    this.Title = title;
    this.Age = age;
    this.Place = place;
    this.Period = period;
    this.Date = date;
    this.RemainingSlots = RemainingSlots;
    this.PrixDemiJournee = PrixDemiJournee;
    this.isFillForced = isFillForced;
    this.isFillForcedAm = isFillForcedAm;
    this.isFillForcedPm = isFillForcedPm;
  }


}
