import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";

/*
platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('ngsw-worker.js');
    }
  }).catch(err => console.error(err));
*/

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/ngsw-worker.js', {
          updateViaCache: 'all'
        }).then(registration => {
          console.log('ServiceWorker registration successful with scope: ', registration.scope);

          registration.addEventListener('updatefound', () => {
            console.log('New content is available; please refresh.');
            // Optionally, you can show a notification to the user
          });

          registration.addEventListener('updated', (registration) => {
            // Reload the page to apply the updated service worker.
            console.log('Service worker updated. Reloading the page.');
            location.reload();
          });

          registration.addEventListener('install', (event) => {
              console.log('Service worker updated/installed.');
          });

          registration.addEventListener('error', (error) => {
            console.error('Error during service worker registration:', error);
          });

          registration.addEventListener('offline', () => {
            console.log('No internet connection found. App is running in offline mode.');
          });

          registration.addEventListener('cached', () => {
            console.log('Content is cached for offline use.');
          });
        }).catch(error => {
          console.error('ServiceWorker registration failed:', error);
        });
      });
    }
  })
  .catch(err => console.error(err));

/*
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/ngsw-worker.js', {
      updateViaCache: 'all'
    }).then(registration => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }).catch(error => {
      console.error('ServiceWorker registration failed: ', error);
    });
  });
} else {
  console.error("Service workers are not supported by this browser.");
}*/

//***********************************************
// scroll to top
//***********************************************
//  const toTop = document.querySelector(".scroll-to-top") as HTMLElement;
//  window.addEventListener("scroll", () => {
//    if (window.pageYOffset > 100) {
//      toTop.classList.add("active");
//    } else {
//      toTop.classList.remove("active");
//    }
//  });
//
// toTop.addEventListener("click", () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth"
//   });
// });
