export class CourseCard {
  public Id: number;
  public Title: string;
  public Type: string;
  public Place: string;
  public PlaceNum: number;
  public Day: string;
  public Hour: string;
  public Age: string;
  public RemainingSlots: number;
  public isFillForced: boolean;
  public isFillForcedSem1: boolean;
  public isFillForcedSem2: boolean;
  public isFillForcedTri: boolean;
  public isFillForcedMois: boolean;
  public Saison: string;

  constructor(id: number = 0, title: string = "", type: string = "", place: string = "", placeNum: number = 0,
              day: string = "", hour: string = "", age: string = "", RemainingSlots: number = 0, isFillForced: boolean = false,
              isFillForcedSem1: boolean = false, isFillForcedSem2: boolean = false, isFillForcedTri: boolean = false,
              isFillForcedMois: boolean = false, saison: string = "") {
    this.Id = id;
    this.Title = title;
    this.Type = type;
    this.Place = place;
    this.PlaceNum = placeNum;
    this.Day = day;
    this.Hour = hour;
    this.Age = age;
    this.RemainingSlots = RemainingSlots;
    this.isFillForced = isFillForced;
    this.isFillForcedSem1 = isFillForcedSem1;
    this.isFillForcedSem2 = isFillForcedSem2;
    this.isFillForcedTri = isFillForcedTri;
    this.isFillForcedMois = isFillForcedMois;
    this.Saison = saison;
  }
}
