import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fIRSTNAMESurname'
})
export class FIRSTNAMESurnamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Split the input string into words
    const words = value.split(' ');

    // Capitalize the first word
    words[0] = words[0].toUpperCase();

    // Capitalize the first letter of each other word
    for (let i = 1; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // Join the words back into a single string
    return words.join(' ');
  }

}

