import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numeroTelephoneFixe'
})
export class NumeroTelephoneFixePipe implements PipeTransform {

  transform(value: string | number): string {
    if (!value) return '';

    const phone = value.toString().replace(/\D/g, ''); // Enlever tout sauf les chiffres

    if (phone.length < 3)
      return phone; // Si le numéro est trop court, on le retourne tel quel

    if (phone.length < 5)
      return `${phone.slice(0, 3)}/${phone.slice(3)}`; // Si le numéro est de la forme xxx/xx

    if (phone.length < 7)
      return `${phone.slice(0, 3)}/${phone.slice(3, 5)}.${phone.slice(5)}`; // Si le numéro est de la forme xxx/xx.xx

    // Formate le numéro en xxx/xx.xx.xx
    return `${phone.slice(0, 3)}/${phone.slice(3, 5)}.${phone.slice(5, 7)}.${phone.slice(7)}`;
  }

}
