import {Periode} from "./Periods";
import {getPeriodeByName, PeriodeDate} from "./PeriodeDate";


export interface Week{
  periodeDate: PeriodeDate,
  name: string,
  startDate: string,
  endDate: string
}

/**
 * Récupere les semaines d'une période sélectionnable (c'est à dire les semaines à partir de la semaine prochaine)
 * @param periodes les périodes sélectionnées
 */
export function getSelectionableWeeks(periodes: string[]): Week[] {
  const selectionableWeeks: Week[] = [];
  const allWeeks = getWeeksOfPeriods(periodes);
  const currentDate = new Date();
  console.log(periodes);
  console.log(allWeeks);

  allWeeks.forEach((week) => {
    console.log(currentDate, week.start);
    if (currentDate < week.start) {
      const start = formatDate(week.start);
      const end = formatDate(week.end);
      const weekName = week.name;
      console.log(weekName, start, end);

      selectionableWeeks.push({
        periodeDate: week.periodeDate,
        name: weekName,
        startDate: start,
        endDate: end,
      });
    }
  });

  console.log(selectionableWeeks);
  return selectionableWeeks;
}

/**
 * Formate une date au format dd/mm/yyyy
 * @param date
 */
function formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());
  return `${day}/${month}/${year}`;
}


export function getIntervalDates(week: Week): string[]{
  const arr = [];
  arr.push(convertDateFormat(week.startDate));
  arr.push(convertDateFormat(week.endDate));
  return arr;
}

export function convertDateFormat(dateString: string): string {
  const [day, month, year] = dateString.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}


export function containsDate(date: string, week: Week): boolean{
  return new Date(convertDateFormat(week.startDate)) <= new Date(date)
    && new Date(date)  <= new Date(convertDateFormat(week.endDate));
}




/**
 * Récupère les semaines correspondant aux périodes sélectionnées à partir de la semaine suivante de la semaine actuelle
 * @param periodes Les périodes sélectionnées
 * @returns Un tableau d'objets représentant les semaines correspondantes
 */
export function getWeeksOfPeriods(periodes: string[]): { periodeDate: PeriodeDate, name: string, start: Date; end: Date }[] {
  const selectedWeeks: { periodeDate: PeriodeDate, name: string, start: Date; end: Date }[] = [];
  const moisEnFrancais = [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ];

  // On prend chaque période sélectionnée
  periodes.forEach(periode => {
    let i = 1;
    let currentPeriod = getPeriodeByName(periode);
    const currentDate = new Date();
    let year = currentDate.getFullYear();

    // l'index du janvier est 0
    // Pour bien placer le cursore de la période entre le début de noel Ans + 0 et fin noel Ans + 1
    if (currentDate.getMonth() + 1 < 2 && currentPeriod!.periode == Periode.NOEL_NA) {
      // Si on est en janvier et qu'on regarde pour la période qui commence en décembre
      year -= 1;
    } else if (currentDate.getMonth() + 1 > 11 && currentPeriod!.periode !== Periode.NOEL_NA) {
      // Si on est en décembre et qu'on regarde pour la période de carnaval
      year += 1;
    }

    // On prend la date du premier lundi de la période
    let firstMonday = getFirstMonday(new Date(year, currentPeriod!.startMonth - 1, currentPeriod!.startDay));
    let monthName = moisEnFrancais[firstMonday.getMonth()];

    do {
      let endWeek = new Date(firstMonday);
      endWeek.setDate(endWeek.getDate() + 6);
      selectedWeeks.push({ periodeDate: currentPeriod!, name: `${monthName} ${i}`, start: firstMonday, end: endWeek });

      firstMonday = new Date(endWeek);
      firstMonday.setDate(firstMonday.getDate() + 1);

      // Réinitialisation de i pour chaque début de mois
      if (moisEnFrancais[firstMonday.getMonth()] !== monthName) {
        i = 1;
      } else {
        i++;
      }

      monthName = moisEnFrancais[firstMonday.getMonth()];
    } while (isMonthsRemaining(new Date(), firstMonday, currentPeriod!));
  })

  return selectedWeeks;
}


/**
 * Récupère le premier lundi d'une à partir d'une date donnée
 * @param date
 */
function getFirstMonday(date: Date): Date {
  const dayOfWeek = date.getDay(); // Jour de la semaine (0 pour dimanche, 1 pour lundi, ..., 6 pour samedi)
  if (dayOfWeek === 1) {
    // Si la date est déjà un lundi, on retourne la date actuelle
    return date;
  }
  const diff = 8 - dayOfWeek; // Nombre de jours à ajouter pour atteindre le lundi suivant
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff);
}


/**
 * On regarde si il reste des semaines à aller rechercher dans la période
 * @param currentDate la date courrante (uniquement pour faire des test de la méthode)
 * @param monday le prochain lundi de la semaine recherchée
 * @param currentPeriod la période actuelle de recherche (objet periodeDate)
 */
function isMonthsRemaining(currentDate: Date, monday: Date, currentPeriod: PeriodeDate){

  //Cas où on est à Toussaint ou en decembre et on regarde pour la période suivante (NOEL)
  if(currentDate.getMonth()+1 > 9 && currentPeriod.periode == Periode.NOEL_NA){
    return (monday.getMonth()+1 > 9 && monday.getMonth()+1 <= 12) || monday.getMonth()+1 < 2;
  }
  //Cas ou on est en janvier et on regarde pour les semaine de décembre dernier (jusqu'à fin carnaval)
  else if(currentDate.getMonth()+1 < 2 && currentPeriod.periode == Periode.NOEL_NA ){
    return monday.getMonth()+1 > 11 || monday.getMonth()+1 < 2;
  }

  else{
    return monday.getMonth() < currentPeriod?.endMonth!
  }
}



