import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../../../../../service/data.service";
import he from "he";
import {FooterService} from "../../../../common/footer/footer.service";

@Component({
  selector: 'app-inscription-confirmee',
  templateUrl: './inscription-confirmee.component.html',
  styleUrls: ['./inscription-confirmee.component.css']
})
export class InscriptionConfirmeeComponent implements OnInit{

  protected selectedStage = JSON.parse(sessionStorage.getItem('selectedStage') || '{}');
  protected selectedMember = JSON.parse(sessionStorage.getItem('selectedMember') || '{}');
  protected inscriptionInfos = JSON.parse(sessionStorage.getItem('inscriptionStage') || '{}');
  protected isReservationStage = JSON.parse(sessionStorage.getItem('reservationStage') || '{}');
  protected connectedAccount = JSON.parse(localStorage.getItem('selectedCompte') || '{}');

  protected emails: string[] = [];
  protected stageName!: string;
  constructor(private dataService: DataService, private router:Router, private footerService: FooterService) {
    if (this.selectedMember == null || this.selectedStage == null || this.connectedAccount == null) {
      this.router.navigateByUrl("/");
    }
  }

  async ngOnInit() {
    this.footerService.setFooterVisibility(true);
    this.stageName = he.decode(this.selectedStage.name);
    console.log("Reservation ?: " + this.isReservationStage)
    if (this.isReservationStage === false) {
      this.emails.push(this.connectedAccount.email);
      if (!this.emails.includes(this.connectedAccount.login)) {
        this.emails.push(this.connectedAccount.login)
      } else if (!this.emails.includes(this.connectedAccount.email2)) {
        this.emails.push(this.connectedAccount.email2)
      }
      await this.sendEmail();
    }
    this.destroyInscriptionObjects();
  }

  ngOnDestroy() {
    this.destroyInscriptionObjects();
  }

  sendEmail(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService
        .sendMailStageConfirmation(
          this.selectedMember.numero_personne,
          this.selectedStage.id,
          this.emails,
          this.selectedStage.price - this.inscriptionInfos.reduction,
          this.inscriptionInfos.periode_journee,
        )
        .subscribe(
          (response) => {
            // Handle success if needed
            resolve();
          },
          (error) => {
            // Handle error if needed
            reject(error);
          }
        );
    });
  }
  destroyInscriptionObjects() {
    sessionStorage.removeItem('selectedStage');
    sessionStorage.removeItem('selectedMember');
    sessionStorage.removeItem('inscriptionStage');
    sessionStorage.removeItem('reservationStage');
  }
  handleForm() {
    this.router.navigateByUrl("/");
  }
}
