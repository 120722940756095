import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.css']
})
export class FormGroupComponent implements OnInit {
  @Input() errorMessage: string | null=null;
  @Output() onElemClick = new EventEmitter<any>();

  mainForm!: FormGroup;
  loginForm!: FormGroup;
  resetPassword: boolean = false;
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute) {

    this.initFormControls();
    this.initMainForm();
  }

  ngOnInit(): void {
    this.resetPassword = !!this.route.snapshot.queryParamMap.get('reset-password');

  }

  private initMainForm(): void {
    this.mainForm = this.formBuilder.group({
      loginForm: this.loginForm,
    });
  }

  private initFormControls(): void {
    //this.passwordCtrl = this.formBuilder.control('', Validators.required);
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  goToInscription() {
    this.router.navigateByUrl('/creation-compte')
  }

}
