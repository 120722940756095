import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {RadioElement} from "../../../models/RadioElement";

@Component({
    selector: 'app-radio-input',
    templateUrl: './radio-input.component.html',
    styleUrls: ['./radio-input.component.css']
})
export class RadioInputComponent {
    @Input() values!: RadioElement[];
    @Input() title: string = 'default title';
    @Input() withBorder?: boolean = true;
    @Output() onElemClick = new EventEmitter<string>()

    ngOnInit() {
      this.checkIfOnlyOneChoice()
    }

    // Définition d'un watcher sur l'entrée 'values'
    ngOnChanges(changes: SimpleChanges): void {
      if(changes['values'] && changes['values'].currentValue) {
        this.checkIfOnlyOneChoice();
      }
    }

    checkIfOnlyOneChoice() {
      // Si une seule valeur, la cocher automatiquement
      if(this.values.length === 1) {
        console.log("radio-input.component.ts: checkIfOnlyOneChoice() - only one choice -> automatically checked")
        this.values[0].isChecked = true;
      }
      else {
        // Si plus d'une valeur, décocher toutes les valeurs
        this.values.forEach(value => {
          value.isChecked = false;
        });
      }
    }

    protected getRecords(map: Map<string, boolean>): { key: string, value: boolean }[] {
        let tab: { key: string, value: boolean }[] = [];

        console.log(map)
        map.forEach((v, k) => {
            tab.push({key: k, value: v});
        })

        return tab;
    }

    emitEvent(radio: RadioElement): void {
        this.onElemClick.emit(radio.value);
    }

}
