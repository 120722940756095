<app-return-link
  [route]="'/gestion-compte'"
  [text]="'Compte'">
</app-return-link>

<h1 class="text-center">Membre DS</h1>


<div class="personal-info-container shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
  <div class="menu-toggle" (click)="toggleMenu()">
    <span class="field2-section d-block mb-2">Informations personnelles</span>
    <span class="arrow" [class.up]="isMenuOpen">&#9660;</span>
  </div>
  <ul class="aboveList" [ngClass]="{'d-none':isMenuOpen}">
    <li>
      <label><i class="fa-regular fa-user"></i></label>
      <p>{{this.member.prenom + ' ' + this.member.nom}}</p>
    </li>
    <li>
      <label><i class="fa-regular fa-calendar"></i></label>
      <p>{{this.member.date_naissance | date:'dd/MM/yyyy'}}</p>
    </li>
  </ul>
  <ul class="menu" [class.active]="isMenuOpen">
    <li>
      <label><i class="fa-regular fa-user"></i></label>
      <p>{{this.member.prenom + ' ' + this.member.nom}}</p>
    </li>
    <li>
      <label><i class="fa-regular fa-calendar"></i></label>
      <p>{{this.member.date_naissance | date:'dd/MM/yyyy'}}</p>
    </li>
    <li>
      <label><i class="fa-solid fa-at"></i></label>
      <p>{{this.member.email}}</p>
    </li>
    <li>
      <label><i class="fa-solid fa-phone"></i></label>
      <p>{{this.member.gsm}}</p>
    </li>
    <li class="d-flex justify-content-center" [class.active]="isMenuOpen">
      <button class="button button-big-size blue text-decoration-none my-2 d-flex flex-column" (click)="onMemberEdit()">Modifier</button>
    </li>
  </ul>
</div>

<hr class="section-separator">

<!-- stages en cours -->
<h2 class="field-section text-center">Stages en cours </h2>
<span class="ds-message m-4">
  <span [ngClass]="{'spinner-border spinner-border-sm ': loadingStatus.stages === LoadingStatus.LOADING}">
  </span>
  <span *ngIf="loadingStatus.stages === LoadingStatus.LOADED && this.currentStages.length === 0">Ce membre n'est incrit à aucun stage actuel ou futur</span>
</span>

<!-- RÉSULTAT STAGES-->
<app-stage-card-recap
  class="mb-3"
  *ngFor="let stage of this.currentStages"
  [Stage]="stage"
  [Member]="member">
</app-stage-card-recap>


<hr class="section-separator">

  <!-- cours inscrits -->
<h2 class="field-section text-center">Cours inscrits </h2>
<span class="ds-message m-4">
  <span [ngClass]="{'spinner-border spinner-border-sm ': loadingStatus.courses === LoadingStatus.LOADING}">
  </span>
  <span *ngIf="loadingStatus.courses === LoadingStatus.LOADED && this.currentCours.length === 0">Ce membre n'est incrit à aucun cours actuel ou futur</span>
</span>

  <app-course-card-recap
    class="mb-3"
    *ngFor="let course of this.currentCours"
    [Course]="course">

  </app-course-card-recap>

<hr class="section-separator">

<div #lockElement class="field-focus historique-box lock-scroll-target">
  <h2 class="field-section text-center">Historique des activités</h2>
  <span class="ds-message m-4">
    <span [ngClass]="{'spinner-border spinner-border-sm ': loadingStatus.stages === LoadingStatus.LOADING}">
    </span>
  </span>
  <div class="filter-box form-group">
  <!--  <label for="search-type">Search Type:</label>-->
    <select id="search-type" class="filter-options form-control ng-pristine ng-valid ng-touched" style="width:50%;" [(ngModel)]="searchType">
      <option value="stage">Stages</option>
      <option value="cours">Cours</option>
    </select>
    <span class="selected-arrow">&#x25BC;</span>

    <input type="text" class="filter-input form-control ng-pristine ng-valid ng-touched" [(ngModel)]="searchQuery" (input)="onSearchQueryChange()" (focus)="onFocus()" (blur)="onBlur()" placeholder="Filtrer les résultas...">

  </div>

  <!-- Display search results -->
  <div *ngIf="searchType === 'stage'">
  <!--  <h3>Stage Results</h3>-->
    <div *ngIf="this.filteredStages.length == 0" class="w-75 mx-auto">
      <p>Aucun résultat ne correspond à votre recherche</p>
    </div>
    <app-stage-card-recap
      class="mb-3"
      *ngFor="let stage of this.filteredStages"
      [Stage]="stage"
      [Member]="member">
    </app-stage-card-recap>
  </div>

  <div *ngIf="searchType === 'cours'">
  <!--  <h3>Cours Results</h3>-->
    <div *ngIf="this.filteredCours.length == 0" class="w-75 mx-auto">
      <p>Aucun résultat ne correspond à votre recherche</p>
    </div>
    <app-course-card-recap
      class="mb-3"
      *ngFor="let course of this.filteredCours"
      [Course]="course">
    </app-course-card-recap>
  </div>
</div>

<br><br>


