export class CourseRecap {
  public NumeroInscriptionCours: number;
  public NumeroPersonne: number;
  public NumeroInstanceCours: number;
  public NumeroCompte: number;
  public Prix: number;
  public Reduction: number;
  public Acompte: number;
  public Acompte2: number;
  public Solde: number;
  public Remboursement: number;
  public NomCours: string;
  public JourInstanceCours: string;
  public HeureInstanceCours: string;
  public DateDebutInstanceCours: Date;
  public DateFinInstanceCours: Date;
  public Lieu: string;
  public Periode : string;
  public PrixAnnee : number;
  public PrixPremierSemestre : number;
  public PrixSecondSemestre : number;
  public Saison : string;

  constructor(NumeroInscriptionCours: number = 0,
              NumeroPersonne: number = 0,
              NumeroInstanceCours: number = 0,
              NumeroCompte: number = 0,
              Prix: number = 0,
              Reduction: number = 0,
              Acompte: number = 0,
              Acompte2: number = 0,
              Solde: number = 0,
              Remboursement: number = 0,
              NomCours: string = "",
              JourInstanceCours: string = "",
              HeureInstanceCours: string = "",
              DateDebutInstanceCours: Date = new Date(),
              DateFinInstanceCours: Date = new Date(),
              Lieu: string = "",
              Periode : string = "",
              PrixAnnee : number = 0,
              PrixPremierSemestre : number = 0,
              PrixSecondSemestre : number = 0,
              Saison : string = "") {
    this.NumeroInscriptionCours = Number(NumeroInscriptionCours);
    this.NumeroPersonne = Number(NumeroPersonne);
    this.NumeroInstanceCours = Number(NumeroInstanceCours);
    this.NumeroCompte = Number(NumeroCompte);
    this.Prix = Number(Prix);
    this.Reduction = Number(Reduction);
    this.Acompte = Number(Acompte);
    this.Acompte2 = Number(Acompte2);
    this.Solde = Number(Solde);
    this.Remboursement = Number(Remboursement);
    this.NomCours = NomCours;
    this.JourInstanceCours = JourInstanceCours;
    this.HeureInstanceCours = HeureInstanceCours;
    this.DateDebutInstanceCours = DateDebutInstanceCours;
    this.DateFinInstanceCours = DateFinInstanceCours;
    this.Lieu = Lieu;
    this.Periode = Periode;
    this.PrixAnnee = Number(PrixAnnee);
    this.PrixPremierSemestre = Number(PrixPremierSemestre);
    this.PrixSecondSemestre = Number(PrixSecondSemestre);
    this.Saison = Saison;
  }
}
