export class Account {

  numero_compte: number;
  login: string;
  email: string;
  rue: string;
  numero: string;
  code_postal: string;
  numero_cp: number;
  localite: string;
  pays: string;
  telephone: string;
  gsm: string;
  nom: string;
  gsm2: string;
  nom2: string;
  newsletter: '0' | '1';
  prenom: string;
  prenom2: string;
  email2: string;
  numeroNational: string;

  constructor({
                numero_compte = 0,
                login = '',
                email = '',
                rue = '',
                numero = '',
                code_postal = '',
                numero_cp = 0,
                localite = '',
                pays = '',
                telephone = '',
                gsm = '',
                nom = '',
                gsm2 = '',
                nom2 = '',
                newsletter = '0',
                prenom = '',
                prenom2 = '',
                email2 = '',
                numeroNational = '',
              }: Partial<Account> = {}) {
    this.numero_compte = numero_compte;
    this.login = login;
    this.email = email;
    this.rue = rue;
    this.numero = numero;
    this.code_postal = code_postal;
    this.numero_cp = numero_cp;
    this.localite = localite;
    this.pays = pays;
    this.telephone = telephone;
    this.gsm = gsm;
    this.nom = nom;
    this.gsm2 = gsm2;
    this.nom2 = nom2;
    this.newsletter = newsletter as '0' | '1';
    this.prenom = prenom;
    this.prenom2 = prenom2;
    this.email2 = email2;
    this.numeroNational = numeroNational;
  }

}
