<app-return-link
  [route]="'/home'"
  [text]="'Accueil'">
</app-return-link>

<h1 class="text-center">Stages</h1>

<!-- Filtres-->
<div class="d-flex justify-content-between align-items-start mx-3 mb-2">
  <span class="field-section">Filtres</span>
  <!-- reset-->
  <button class="refresh-filter" (click)="StageEvent.onResetFiltersClick.call(this)">
    Effacer filtres
  </button>
</div>


<!-- Périodes -->
<app-check-list
  [values]="Model.periods"
  [title]="'Périodes'"
  [status]="stagesStatusProvider.periods"
  (onElemClick)="Stream.periods$.next($event)">
  <input #periodsInput type="text" class="form-control">

</app-check-list>


<!-- Semaines choix multiple-->
<app-record-list
  [values]="Model.weeks"
  [title]="'Dates disponibles'"
  [status]="stagesStatusProvider.weeks"
  (onRecordClick)="Stream.weeks$.next($event)">
  <input #weeksInput type="text" class="form-control">

</app-record-list>


<!-- Lieux choix multiple-->
<app-multiselect-block
[status]="stagesStatusProvider.places"
[initData]="multiselectInilialiser"
[update$]="placesMultiselectUpdate$"
(onMultiselectClick)="Stream.places$.next($event)">
</app-multiselect-block>


<!-- age-->
<app-age-range
  [resetEvent$]="resetFilters$"
  [ageRange]="this.Model.ageRange"
  [delay]="REQUEST_DELAY"
  (ageUpdate)="StageEvent.onAgeUpdate.call(this, $event)" >
</app-age-range>


<!-- appliquer filtre -->
<div class="ds-btn-confirmation-container">
  <!-- appliquer filtre-->
<!--  (click)="Stream.filteredStages$.next('confirm-button')"
[ngClass]="{disabled: !Model.isValidFilter()}"
-->
  <button class="shadow btn btn-outline-success ds-btn-confirmation ml-auto mr-1" type="button"
          (click)="onRechercherClick()"
          [ngStyle]="!Model.isValidFilter()?{'background-color' : 'darkgray'}:{}">
    Rechercher
  </button>

  <!-- auto filtres-->
  <button class="shadow btn btn-rounded auto-filter ds-btn-confirmation mr-auto ml-1"
          [ngClass]="{'shadow-sm': !Model.isAutoApplyFilters, disabled: !Model.isValidFilter()}"
          [ngStyle]="{'background-color': !Model.isValidFilter() ? 'darkgray' : (!Model.isAutoApplyFilters ? 'lightgray' : '#48ABF6FF')}"
          (click)="StageEvent.onAutoFilterClick.call(this)">
    <input class="form-check-input ds-checkbox ml-auto position-relative" type="checkbox" [checked]="Model.isAutoApplyFilters">
    <span>Auto</span>
  </button>
</div>


<!-- espace-->
<hr class="section-separator {{scrollToStagesClass}}">


<!-- stages disponibles-->
<h2 class="field-section text-center">{{paginator.total!==undefined && (stagesStatusProvider.stages.isValid || stagesStatusProvider.stages.message === "La liste est vide.")?paginator.total:'...'}} stages trouvé(s)</h2>

<div class="card-list mb-5" [ngStyle]="{'min-height': window.innerHeight-120 + 'px'}">

  <!--PAGINATION-->
  <app-pagination *ngIf="Model.isValidFilter() && stagesStatusProvider.stages.isValid && this.paginator.total"
    [paginator]="this.paginator"
    (onClick)="StageEvent.onPaginationClick.call(this, $event)">
  </app-pagination>

  <!-- MESSAGE D'ÉTAT DE CHARGEMENT DES STAGES-->
  <span class="ds-message m-4" [ngClass]="stagesStatusProvider.stages.classes">
    <div *ngIf="stagesStatusProvider.stages.isLoading" class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Chargement...</span>
    </div> {{stagesStatusProvider.stages.message}}
  </span>

  <!-- RÉSULTAT STAGES-->
  <app-stage-card
    class="mb-3"
    [ngClass]="{'d-none': !stagesStatusProvider.stages.isValid}"
    *ngFor="let stage of this.Model.filteredStages"
    [Stage]="stage">
  </app-stage-card>
</div>

<!-- SCROLL TO STAGES-->
<app-scroll-to-icon
  [selector]="scrollToStagesClass">
</app-scroll-to-icon>
