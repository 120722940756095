import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormLoadingStatus} from "../../../models/FormLoadingStatus";

@Component({
  selector: 'app-radio-list',
  templateUrl: './radio-list.component.html',
  styleUrls: ['./radio-list.component.css', '../../common/styles/common.css']
})
export class RadioListComponent {
  @Input() values!: Map<string, boolean>;
  @Input() title: string = 'default title';
  @Input() status : FormLoadingStatus = new FormLoadingStatus('', [], true, false);
  @Input() withBorder?: boolean = true;
  @Input() cantBeChecked? : Map<string, boolean>;
  @Output() onElemClick = new EventEmitter<{key: string, value: boolean}>()

  ngOnInit() {
    // if (this.cantBeChecked) {
    //   this.cantBeChecked.forEach((v, k)=> {
    //     if (this.values.has(k) && !v) {
    //
    //     }
    //   })
    // }
  }

  protected getRecords(map: Map<string, boolean>): {key: string, value: boolean}[] {
    let tab: {key: string, value: boolean}[] = [];

    console.log(map)
    map.forEach((v, k) => {
      tab.push({key: k, value: v});
    })

    return tab;
  }
}
