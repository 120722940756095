import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from "rxjs";
import {API_BASE_URL} from './config';

@Injectable({
  providedIn: 'root'
})
export class WebPushService {
  private storeSubscriptionUrl= `${API_BASE_URL}storeSubscription`; // URL of your Laravel backend API -> Localhost for now
  private sendWebPushNotificationUrl= `${API_BASE_URL}sendWebPushNotification`; // URL of your Laravel backend API -> Localhost for now
  private applicationServerKey = 'BPLTOyZ-OsqPpEaAZrLXjrMupuRlyN8-Q9Tej2c1avmBRXTTvpzqehS8x0NvtncJImHYyKGKHCKjRM6gL-qE3VM';

  constructor(private http: HttpClient) { }

  requestNotificationPermission(): Observable<NotificationPermission> {
    return new Observable<NotificationPermission>(observer => {
      if ('Notification' in window) {
        Notification.requestPermission().then(permission => {
          observer.next(permission);
          observer.complete();
        });
      } else {
        observer.error('Angular : Notifications not supported in this browser.');
      }
    });
  }

  subscribeToPushNotifications(): Observable<PushSubscription> {
    return new Observable<PushSubscription>(observer => {
      console.log('Angular : Subscribing to push notifications...')
      if ('Notification' in window && 'PushManager' in window) {
        navigator.serviceWorker.ready
          .then(registration => registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: this.applicationServerKey }))
          .then(subscription => {
            observer.next(subscription);
            observer.complete();
          })
          .catch(error => {
            observer.error('Angular : Error subscribing to push notifications: ' + error);
          });
      } else {
        observer.error('Angular : Push notifications not supported in this browser.');
      }
    });
  }

  sendSubscriptionDetails(subscription: PushSubscription): Observable<any> {
    const endpoint = this.storeSubscriptionUrl;
    const payload = {
      subscription: {
        endpoint: subscription.endpoint,
        keys: {
          p256dh: this.arrayBufferToBase64(subscription.getKey('p256dh')),
          auth: this.arrayBufferToBase64(subscription.getKey('auth'))
        }
      }
    };
    return this.http.post(endpoint, payload);
  }

  private arrayBufferToBase64(buffer: ArrayBuffer | null): string {
    if (buffer === null) {
      return ''; // or handle the null case according to your requirements
    }

    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  sendWebPushNotification() {
    console.log('Angular : Sending web push notification...');
    const endpoint = this.sendWebPushNotificationUrl;
    this.http.post(endpoint, {})
      .subscribe(
        response => {
          console.log('Angular : Web push notification sent successfully : ', response);
        },
        error => {
          console.error('Angular : Failed to send web push notification : ', error);
        });
    console.log('Angular : Web push notification sent successfully.');
  }
}
