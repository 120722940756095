import {StageCard} from "./StageCard";
import {Place} from "./Place";

export class CourseDetails {
  id:number;
  name:string
  description:String;
  day:string;
  hour:string;
  dateStart1:Date;
  dateStart2:Date;
  dateEnd:Date;
  ageStart:number;
  ageEnd:number;
  price:number;
  semesterPrice:number;
  remainingSlots:number;
  nomLieu:string;
  rue:string;
  numero:string;
  codePostal:string;
  localite:string;
  google:string;
  isFillForced: boolean;
  isFillForcedSem1: boolean;
  isFillForcedSem2: boolean;
  isFillForcedTri: boolean;
  isFillForcedMois: boolean;

  constructor(id:number = 0,name:string = "", description:String = "", day:string = "",
              hour:string = "", dateStart1:Date = new Date(), dateStart2: Date = new Date(), dateEnd:Date = new Date(), ageStart:number = 0,
              ageEnd:number = 0, price:number = 0.00, semesterPrice:number = 0.00,
              remainingSlots: number = -1, nomLieu:string = "", rue:string = "", numero:string = "", codePostal:string = "",
              localite:string = "", google:string = "", isFillForced:boolean = false, isFillForcedSem1: boolean = false,
              isFillForcedSem2: boolean = false, isFillForcedTri: boolean = false, isFillForcedMois: boolean = false) {
    this.id = id,
    this.name = name,
    this.description = description,
    this.day = day,
    this.hour = hour,
    this.dateStart1 = new Date(dateStart1),
    this.dateStart2 = new Date(dateStart2),
    this.dateEnd = new Date(dateEnd),
    this.ageStart = ageStart,
    this.ageEnd = ageEnd,
    this.price = price,
    this.semesterPrice = semesterPrice,
    this.remainingSlots = remainingSlots,
    this.nomLieu = nomLieu,
    this.rue = rue,
    this.numero = numero,
    this.codePostal = codePostal,
    this.localite = localite,
    this.google = google,
    this.isFillForced = isFillForced,
    this.isFillForcedSem1 = isFillForcedSem1,
    this.isFillForcedSem2 = isFillForcedSem2,
    this.isFillForcedTri = isFillForcedTri,
    this.isFillForcedMois = isFillForcedMois
  }
}
