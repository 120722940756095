import {LoadingStatus} from "../../../../models/FormLoadingStatus";

const {
  LOADING,
  LOADED,
  TIME_OUT,
  NET_ERROR,
  ERROR
} = LoadingStatus;

export class GestionMembreLoadingStatus {
  stages: LoadingStatus = LOADING;
  courses: LoadingStatus = LOADING;
}
