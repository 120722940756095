import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-contact2',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  newsletterForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    // Define the form group with YMP0 as the form control name
    this.newsletterForm = this.formBuilder.group({
      YMP0: ['', [Validators.required, Validators.email]]  // Form control named 'YMP0'
    });
  }

  onSubmit() {
    if (this.newsletterForm.valid) {
      const baseUrl = 'https://ymlp.com/subscribe.php';
      const listId = 'gmwwusugmgj';
      const formData = this.newsletterForm.value;
      const queryParams = `?id=${listId}&YMP0=${formData.YMP0}`;
      const redirectUrl = `${baseUrl}${queryParams}`;

      // Redirect the user to the constructed URL
      window.location.href = redirectUrl;
    }
  }

  protected readonly FormData = FormData;

  copierCompte() {
    const numeroCompte = "BE23 0012 3458 6391";
    navigator.clipboard.writeText(numeroCompte)
      .then(() => {
        // alert("Numéro de compte copié dans le presse-papier !");
      })
      .catch(err => {
        console.error("Erreur lors de la copie : ", err);
      });
  }
}
