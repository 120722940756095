import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.css', '../../common/styles/common.css']
})
export class CheckListComponent implements OnInit{
  @Input() values!: Map<string, boolean>;
  @Input() title: string = 'default title';
  @Input() status! :{message: string, classes: any[], isValid : boolean, isLoading : boolean};
  @Output() onElemClick = new EventEmitter<{key: string, value: boolean}>()

  ngOnInit() {

  }

  protected getRecords(map: Map<string, boolean>): {key: string, value: boolean}[] {
    let tab: {key: string, value: boolean}[] = [];

    map.forEach((v, k) => {
      tab.push({key: k, value: v});
    })

    return tab;
  }
}
