<!--bouton retour-->
<app-return-link
  [text]="returnName">
</app-return-link>

<h2 class="text-center font-weight-bold mt-4">Confirmation du membre recherché</h2>

<!--FORMULAIRE-->
<form *ngIf="searchForm" novalidate class="m-3 needs-validation" [formGroup]="searchForm" (ngSubmit)="validateForm()">

  <ng-container  *ngTemplateOutlet="field; context:
    {name : 'nom', placeholder: 'Ex : DUPONT'}" />

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'prenom', placeholder: 'Ex : Jean', disabled: true}" />

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'dateNaissance', label: 'date de naissance', type: 'date', disabled: true}" />

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'email', placeholder: 'Adresse email', disabled: true}" />

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'localite', label: 'Localité', placeholder: 'Localité', disabled: true}" />

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'rue', placeholder: 'Rue', disabled: true}" />

<span
    [ngClass]="{'ds-message-success mb-4': isSuccessful, 'ds-message-error mb-4': !isSuccessful}">
  {{message}}
</span>

  <!--BOUTON ENVOYER-->
  <app-floating-button text="Confirmer" [disabled]="submitted && (!searchForm.valid || buttonStatus === 'loading')"/>

  <p class="text-center" style="margin-top: -2em">
    Autre membre à rechercher ? <a routerLink="/recherche-membre" class="text-decoration-none" >Nouvelle recherche</a>
  </p>

  <!--|=========|-->
  <!--|TEMPLATES|-->
  <!--|=========|-->

  <!--CHAMPS-->
  <ng-template #field let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
    <label [for]="name" class="fw-normal">{{(label??name)[0].toUpperCase() + (label??name).slice(1)}}</label>

    <input [type]="type" [id]="name" [formName]="name"
           [formControlName]="name" [appFieldError]="searchForm.get(name)!"
           [placeholder]="placeholder??''" >
    <app-error-message-for-field [control]="searchForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>
</form>
