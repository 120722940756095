<div class="card shadow p-3">
  <div class="d-flex justify-content-center">
    <label class="fw-bold field mb-2 mx-auto" [innerHTML]="Course.NomCours"></label>
  </div>
  <div class="row align-items-start">

    <div class="col-12">
      <Strong>Lieu: </Strong><span>{{Course.Lieu}}</span>
    </div>

    <div class="col-12">
      <Strong>Date: </Strong><span>du {{Course.DateDebutInstanceCours | date:'dd/MM/yyyy'}}
      au {{Course.DateFinInstanceCours | date:'dd/MM/yyyy'}}</span>
    </div>

    <div class="col-12">
      <Strong>Saison: </Strong><span>{{Course.Saison}}</span>
    </div>

    <div class="col-12">
      <Strong>Reste à payer : </Strong><span>{{this.prix | currency:'EUR':'symbol':'1.2-2'}}</span>
    </div>

    <div class="mt-3 d-flex justify-content-center">
      <button class="button button-medium-size blue text-decoration-none" (click)="onStageClick()">
        Détails <i class="fa-solid fa-arrow-right"></i>
      </button>
    </div>

    <div></div>
  </div>
</div>

