import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";

declare var $: any;

@Component({
  selector: 'app-scroll-to-icon',
  templateUrl: './scroll-to-icon.component.html',
  styleUrls: ['./scroll-to-icon.component.css']
})
export class ScrollToIconComponent implements OnInit, OnDestroy {
  @Input() selector!: string;
  protected visible: boolean = true;

  constructor(private zone: NgZone) {
  }

  ngOnInit() {
    $(document).ready(() => {
      window.addEventListener("scroll", () => {
        this.zone.run(() => {
          this.visible = !(window.pageYOffset > $('.'+this.selector).offset()?.top-50);
        });
      });
    });
  }

  onScrollTo() {
    setTimeout(() => $('.'+this.selector)[0].scrollIntoView({ behavior: 'smooth', block: 'start' }), 0)
  }

  ngOnDestroy(): void {
    $(document).ready(() => {
      window.removeEventListener("scroll", () => {});
    });
  }
}
