export class MedicalInformation {

    medecin: string;
    gsm: string;
    annee_tetanos: number;
    allergies: string;
    medicaments: string;
    remarques: string;
    numero_personne: number;

    constructor(medecin: string = "", gsm: string = "", annee_tetanos: number = -1, allergies: string = "", medicaments: string = "", remarques: string = "", numero_personne : number = 0) {
        this.medecin = medecin;
        this.gsm = gsm;
        this.annee_tetanos = annee_tetanos;
        this.allergies = allergies;
        this.medicaments = medicaments;
        this.remarques = remarques;
        this.numero_personne = numero_personne;
    }
}
