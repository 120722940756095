<button (click)="onStageClick(Stage.Id)" class="card shadow btn btn-secondary">

  <label class="fw-bold field mb-2 mx-auto" [innerHTML]="this.Stage.Title"></label>
  <div class="d-flex align-items-center">
    <!-- Message affiché lorsqu'un stage est complet -->
    <ng-container *ngIf="isFilled()">
<!--    <ng-container *ngIf="true">-->
      <div style="width: 1.25rem; height: 1.25rem; background-color: red; border-radius: 50%; margin-right: 0.3125rem; margin-bottom: 0.5625rem;"></div>
      <label style="color: red;">Complet (Inscription en liste d'attente)</label>
    </ng-container>
    <!-- Message affiché lorsqu'un stage est presque complet -->
    <ng-container *ngIf="isNearlyFilled()">
      <div style="width: 1.25rem; height: 1.25rem; background-color: orange; border-radius: 50%; margin-right: 0.3125rem; margin-bottom: 0.5625rem;"></div>
      <label style="color: orange;">Plus que quelques places</label>
    </ng-container>
  </div>

  <div class="d-flex row align-items-center align-self-center mx-auto">
    <div class="col-auto" style="width: 9em; padding-right: 0">
      <Strong>Age: </Strong><span>{{Stage.Age}}</span>
    </div>
    <div class="col-auto">
      <Strong>Période: </Strong><span>{{Stage.Period}}</span>
    </div>
    <div class="col-12">
      <Strong>Lieu: </Strong><span>{{Stage.Place}}</span>
    </div>
    <div class="col-12">
      <Strong>Date: </Strong><span>{{Stage.Date}}</span>
    </div>

    <div></div>
  </div>
</button>

