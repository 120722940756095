import {Component, Input} from '@angular/core';
import {CourseCard} from "../../../models/CourseCard";
import {Router} from "@angular/router";

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.css']
})
export class CourseCardComponent {
  @Input() public Course:CourseCard

  constructor(private router: Router) {
    this.Course = new CourseCard;
  }

  /**
   * When course card clicked, redirect to course details
   * @param Id id of the course
   */
  onCourseClick(Id: number) {
    setTimeout(() => this.router.navigateByUrl(`cours/${Id}`), 500)
  }

  isFilled() {
    return this.Course.RemainingSlots <= 0 || this.isForceFilled();
  }

  isNearlyFilled() {
    // not implemented yet
    return this.Course.RemainingSlots <= 3 && this.Course.RemainingSlots > 0;
  }

  private isForceFilled() {
    return this.Course.isFillForced || this.isPartiallyForceFilled()
  }

  private isPartiallyForceFilled() {
    return this.isAYoungChildCourse() && (this.Course.isFillForcedSem1 || this.Course.isFillForcedSem2)
  }

  private isAYoungChildCourse() {
    return true; // y a t'il le système de cours pour les jeunes enfants?
  }
}
