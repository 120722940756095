<main>
  <div class="back_element mx-3">
    <i class="fa-solid fa-chevron-left"></i>
    <a routerLink="/" class="link-secondary m-1 fw-bolder">Retour au menu pricipal</a>
  </div>

  <h1 class="text-center fw-bold mt-3" [innerHTML]="'Etape 3/3'"></h1>
  <h2 class="text-center">{{this.coursName}}</h2>

  <hr>

  <h2 class="text-center fw-bold mt-3">Inscription terminée !</h2>

  <section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column" *ngIf="isReservationCours === false">
    <p>
      <b>{{this.selectedMember.prenom}} {{this.selectedMember.nom}}</b> a bien été inscrit au cours <b>{{this.coursName}}</b>
    </p>
    <p class="wrap-word">
      Un email de confirmation a été envoyé à :
      <span *ngFor="let email of emails; let i = index">
        - <b>{{email}}</b><br>
      </span>
      Conservez-le, imprimez-le, il fait office
      de confirmation définitive. Si vous ne l'avez pas reçu demain, veuillez regarder dans vos spams (courrier
      indésirable) ou bien nous en faire part à info@dimension-sport.be
    </p>
  </section>

  <section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column" *ngIf="isReservationCours === true">
    <p>
      <b>{{this.selectedMember.prenom}} {{this.selectedMember.nom}}</b> a bien été ajouté à la liste d'attente du cours <b>{{this.coursName}}</b>
    </p>
    <p>
      Vous serez prévenu dès qu'une place se libérera.
    </p>
  </section>

  <app-floating-button
    [text]="'Retour au menu pricipal'"
    (onClick)="handleForm()"
  >
  </app-floating-button>

</main>
