export class StageRecap {
  public NumeroInscriptionStage: number;
  public NumeroPersonne: number;
  public NumeroInstanceStage: number;
  public NumeroCompte: number;
  public Reduction: number;
  public Acompte: number;
  public Acompte2: number;
  public Solde: number;
  public Remboursement: number;
  public DateDebutInstanceStage: Date;
  public DateFinInstanceStage: Date;
  public NombreDeJours: number;
  public NomStage: string;
  public Lieu: string;
  public TypeStage: string;
  public PrixJournee: number;
  public PrixDemiJournee: number;

  constructor(NumeroInscriptionStage: number = 0,
              NumeroPersonne: number = 0,
              NumeroInstanceStage: number = 0,
              NumeroCompte: number = 0,
              Reduction: number = 0,
              Acompte: number = 0,
              Acompte2: number = 0,
              Solde: number = 0,
              Remboursement: number = 0,
              DateDebutInstanceStage: Date = new Date(),
              DateFinInstanceStage: Date = new Date(),
              NombreDeJours: number = 0,
              NomStage: string = "",
              Lieu: string = "",
              TypeStage: string = "",
              PrixJournee: number = 0,
              PrixDemiJournee: number = 0) {
    this.NumeroInscriptionStage = Number(NumeroInscriptionStage);
    this.NumeroPersonne = Number(NumeroPersonne);
    this.NumeroInstanceStage = Number(NumeroInstanceStage);
    this.NumeroCompte = Number(NumeroCompte);
    this.Reduction = Number(Reduction);
    this.Acompte = Number(Acompte);
    this.Acompte2 = Number(Acompte2);
    this.Solde = Number(Solde);
    this.Remboursement = Number(Remboursement);
    this.DateDebutInstanceStage = DateDebutInstanceStage;
    this.DateFinInstanceStage = DateFinInstanceStage;
    this.NombreDeJours = Number(NombreDeJours);
    this.NomStage = NomStage;
    this.Lieu = Lieu;
    this.TypeStage = TypeStage;
    this.PrixJournee = Number(PrixJournee);
    this.PrixDemiJournee = Number(PrixDemiJournee);
  }
}
