<app-return-link
  [route]="'/home'"
  [text]="'Accueil'"
  (onButtonClick)="clearSessionStorage()">
</app-return-link>

<p *ngIf="sessionStorage.getItem('selectedStage') || this.redirect" class="text-center m-5">
  Vous devez d'abord vous connecter à votre compte DS pour pouvoir continuer.
</p>

<h1 class="text-center font-weight-bold mt-4"> Connexion </h1>

<hr class="section-separator">

<p *ngIf="resetPassword" class="text-center ds-message-error m-5">
  Votre nouveau mot de passe a été envoyé par mail. Consultez votre messagerie pour le récupérer.
</p>
<!--FORMULAIRE-->
<form *ngIf="loginForm" novalidate class="m-3 needs-validation" [formGroup]="loginForm" (ngSubmit)="onLogin()">

<!--CHAMPS-->
<ng-container *ngTemplateOutlet="field; context:
    {name : 'email', label : 'Email*', placeholder: ''}" />

<ng-container class=" -webkit-text-security: circle" *ngTemplateOutlet="passwordField; context:
    {name : 'password', type : 'password', label : 'Mot de passe*', placeholder: ''}" />
  <div class="error-message">
    {{ errorMessage }}
  </div>

<div class="form-group text-center mt-3">
  <a [routerLink]="'/reset-password'" class="custom-link" >Mot de passe oublié ?</a>
</div>

<button>Connexion</button>

<div class="form-group text-center mt-3">
  <p>Vous n'avez pas de compte DS ?</p>
  <a class="custom-link" [routerLink]="''" (click)="goToInscription()" >Créer un compte DS</a>
</div>

<ng-template #field let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
  <label [for]="name" class="fw-normal">{{label??name}}</label>

  <input [type]="type" [id]="name" [formName]="name"
         [formControlName]="name" [appFieldError]="loginForm.get(name)!"
         [placeholder]="placeholder??''" >


  <app-error-message-for-field [control]="loginForm.get(name)!" [submitted]="submitted"/>
  <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
</div></ng-template>
  <ng-template #passwordField let-name="name" let-label="label" let-placeholder="placeholder">
    <div class="form-group">
      <label [for]="name" class="fw-normal">{{ label ?? name }}</label>
      <div class="input-group">
        <input
          [type]="showPassword ? 'text' : 'password'"
          [id]="name"
          [formName]="name"
          [formControlName]="name"
          [appFieldError]="loginForm.get(name)!"
          [placeholder]="placeholder ?? ''"
          class="form-control"
        >
        <span class="input-group-text" (click)="togglePasswordVisibility()" style="cursor: pointer;">
          <i class="fas" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
        </span>
      </div>
      <app-error-message-for-field [control]="loginForm.get(name)!" [submitted]="submitted" />
    </div>
  </ng-template>
</form>


<!--<app-form-group-->
<!--  (onElemClick)="this.onLogin($event)"-->
<!--  [errorMessage]="errorMessage">-->
<!--</app-form-group>-->
