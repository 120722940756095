export function getStatusMessage(isLoading: boolean, message: string, isTimeOut: boolean, classes: string[], isNetError: boolean, isRequestError: boolean, isEmptyList: boolean, hasNotSearched = false) {
  if (isLoading) {
    message = 'Chargement...';
  } else if (isTimeOut) {
    message = 'Le serveur ne répond pas, veuillez réessayer plus tard.';
    classes.push('ds-message-error')
  } else if (isNetError) {
    message = 'Pas de connection à internet.';
    classes.push('ds-message-error')
  } else if (isRequestError) {
    message = 'Une erreur au niveau de la requête s\'est produite.';
    classes.push('ds-message-error')
  } else if (hasNotSearched) {
    message = 'Appliquez les filtres';
  } else if (isEmptyList) {
    message = 'La liste est vide.';
  } else {
    classes.push('hidden');
  }
  return message;
}
