<!--<p>member-edit-form works!</p>-->
<section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
  <div class="menu-container menu-toggle" (click)="toggleMenu()">
    <mat-card-title class="fw-bold field my-2 mx-1">{{member.nom}} {{member.prenom}}</mat-card-title>
    <span class="arrow" [class.up]="!isMenuOpen">&#9660;</span>
  </div>
  <div [ngClass]="{'d-none':isMenuOpen}">
    <mat-card class="form-card" [formGroup]="mainForm">
      <!-- -------------------- CHAMP DE FORMULAIRE NOM -------------------- -->
      <mat-card-subtitle>Nom*</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Nom</mat-label>
        <input type="text" matInput formControlName="nom">
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE PRENOM -------------------- -->
      <mat-card-subtitle>Prénom*</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Prénom</mat-label>
        <input type="text" matInput formControlName="prenom">
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE DATE -------------------- -->
      <mat-card-subtitle>Date de naissance*</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Date de naissance</mat-label>
        <input type="date" matInput formControlName="dateDeNaissance">
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE GSM -------------------- -->
      <mat-card-subtitle>Gsm*</mat-card-subtitle>
      <mat-form-field>
        <mat-label>GSM (0495/123456)</mat-label>
        <input type="text" matInput formControlName="gsm">
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE COMMENTAIREs -------------------- -->
      <mat-card-subtitle>Commentaires</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Commentaires</mat-label>
        <textarea matInput formControlName="commentaires"></textarea>
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE REMARQUEs -------------------- -->
      <mat-card-subtitle>Remarques</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Remarques</mat-label>
        <textarea matInput formControlName="remarques"></textarea>
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE PROFESSION PARENTS -------------------- -->
      <mat-card-subtitle>Profession parents</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Profession parents</mat-label>
        <input type="text" matInput formControlName="professionParents">
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE FONCTION -------------------- -->
      <mat-card-subtitle>Fonction</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Fonction</mat-label>
        <input type="text" matInput formControlName="fonction">
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE SPORT PRATIQUE -------------------- -->
      <mat-card-subtitle>Sport pratique</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Sport pratique</mat-label>
        <input type="text" matInput formControlName="sportPratique">
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE ECOLE -------------------- -->
      <mat-card-subtitle>Ecole*</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Ecole</mat-label>
        <input type="text" matInput formControlName="ecole">
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE NUMERO NATIONAL -------------------- -->
      <mat-card-subtitle>Numero national*</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Numero national</mat-label>
        <input type="text" matInput formControlName="numeroNational">
      </mat-form-field>
      <!-- -------------------- CHAMP DE FORMULAIRE EMAIL -------------------- -->
      <mat-card-subtitle>Email*</mat-card-subtitle>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input type="text" matInput formControlName="email">
      </mat-form-field>
    </mat-card>
  </div>
</section>
