import {Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../../../../service/data.service";
import {Member} from "../../../../models/Member";
import {CourseRecap} from "../../../../models/CourseRecap";
import {StageRecap} from "../../../../models/StageRecap";
import {GestionMembreLoadingStatus} from "./GestionMembreLoadingStatus";
import {LoadingStatus} from "../../../../models/FormLoadingStatus";

@Component({
  selector: 'app-gestion-membre',
  templateUrl: './gestion-membre.component.html',
  styleUrls: ['./gestion-membre.component.css']
})
export class GestionMembreComponent {

  isMenuOpen: boolean = false;
  @Input() member!: Member;
  protected allStages: StageRecap[] = [];
  protected allCours: CourseRecap[] = [];
  protected currentStages: StageRecap[] = [];
  protected currentCours: CourseRecap[] = [];

  searchType: string = 'stage';  // Default to 'stage'
  searchQuery: string = '';
  protected filteredStages: StageRecap[] = [];
  protected filteredCours: CourseRecap[] = [];

  loadingStatus = new GestionMembreLoadingStatus()

  protected fieldInFocus: boolean = false;
  @ViewChild('lockElement') lockElement!: ElementRef;

  constructor(private router : Router, private dataService: DataService) {

  }


  adress:any;
  async ngOnInit() {
    this.member = JSON.parse(sessionStorage.getItem("selectedMember") || '{}');
    await this.getMemberStages();
    await this.getMemberCourses();
  }

  onMemberEdit() {
    this.router.navigate(['/modification-membre']);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  private async getMemberStages() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getMemberStages(this.member.numero_personne).subscribe({
        next: (res:any) => {
          for(const element of res) {
            let stageRecap = new StageRecap(
              element.NUMERO_INSCRIPTION_STAGE,
              element.NUMERO_PERSONNE,
              element.NUMERO_INSTANCE_STAGES,
              element.NUMERO_COMPTE,
              element.REDUCTION,
              element.ACOMPTE,
              element.ACOMPTE2,
              element.SOLDE,
              element.REMBOURSEMENT,
              new Date(element.DATE_DEBUT),
              new Date(element.DATE_FIN),
              element.NOM,
              element.NOM_LIEU,
              element.TYPE_STAGE,
              element.PRIX_JOURNEE,
              element.PRIX_DEMI_JOURNEE,
            )
            const tenDaysAgo = new Date();
            tenDaysAgo.setDate(tenDaysAgo.getDate() - 10); // Subtract 10 days from the current date

            // Compare the dates
            if (stageRecap.DateFinInstanceStage > tenDaysAgo) {
              this.currentStages.push(stageRecap);
            }

            this.allStages.push(stageRecap);
          }
          this.filteredStages = this.allStages;
          this.loadingStatus.stages = LoadingStatus.LOADED;
          resolve();
        },
        error: (error) => {
          this.loadingStatus.stages = LoadingStatus.ERROR;
          reject(error);
        }
      });
    });
  }

  private async getMemberCourses() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getMemberCourses(this.member.numero_personne).subscribe({
        next: (res:any) => {
          for(const element of res) {
            let courseRecap = new CourseRecap(
              element.NUMERO_INSCRIPTION_COURS,
              element.NUMERO_PERSONNE,
              element.NUMERO_INSTANCE_COURS,
              element.NUMERO_COMPTE,
              element.PRIX,
              element.REDUCTION,
              element.ACOMPTE,
              element.ACOMPTE2,
              element.SOLDE,
              element.REMBOURSEMENT,
              element.NOM_COURS,
              element.JOUR_INSTANCE_COURS,
              element.HEURE_INSTANCE_COURS,
              new Date(element.DATE_DEBUT),
              new Date(element.DATE_FIN),
              element.NOM_LIEU,
              element.PERIODE,
              element.PRIX_ANNEE,
              element.PRIX_PREMIER_SEMESTRE,
              element.PRIX_SECOND_SEMESTRE,
              element.SAISON,
            )
            const tenDaysAgo = new Date();
            tenDaysAgo.setDate(tenDaysAgo.getDate() - 10); // Subtract 10 days from the current date

            // Compare the dates
            if (courseRecap.DateFinInstanceCours > tenDaysAgo) {
              this.currentCours.push(courseRecap);
            }

            this.allCours.push(courseRecap)
          }
          this.filteredCours = this.allCours;
          this.loadingStatus.courses = LoadingStatus.LOADED;
          resolve();
        },
        error: (error) => {
          this.loadingStatus.courses = LoadingStatus.ERROR;
          reject(error);
        }
      });
    });
  }

  protected readonly LoadingStatus = LoadingStatus;

  // Hot-reload filter function called on every input
  onSearchQueryChange(): void {
    this.performFilter();
  }

  performFilter(): void {
    if (this.searchType === 'stage') {
      this.filteredStages = this.allStages.filter(stage =>
        (stage.NomStage.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          stage.Lieu.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          stage.DateDebutInstanceStage.toString().includes(this.searchQuery) ||
          stage.DateFinInstanceStage.toString().includes(this.searchQuery))
      );
    } else if (this.searchType === 'cours') {
      this.filteredCours = this.allCours.filter(cours =>
        (cours.NomCours.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          cours.Lieu.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          cours.JourInstanceCours.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          cours.DateDebutInstanceCours.toString().includes(this.searchQuery) ||
          cours.DateFinInstanceCours.toString().includes(this.searchQuery) ||
          cours.Periode.toLowerCase().includes(this.searchQuery.toLowerCase()))
      );
    }
  }

  onFocus() {
    this.fieldInFocus = true;
    const targetElementPosition = this.lockElement.nativeElement.offsetTop;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Scroll the target element to the top of the page if user scrolls above it
    if (scrollTop < targetElementPosition) {
      window.scrollTo({top: targetElementPosition, behavior: 'smooth'});
    }
  }

  @HostListener('window:scroll', [])
  onBlur() {
    this.fieldInFocus = false;
  }
}
