import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-floating-button',
  template: `
    <!--  <button (click)="OnRegister()" type="button" class="shadow btn btn-outline-success ds-btn-confirmation mb-5 ds-static-confirm-btn">Je m'inscris</button>-->
    <button (click)="onClick.emit()" [disabled]="disabled"
            type="submit" class="shadow btn mb-5 ds-floating-confirm-btn" [ngClass]="{'ds-btn-confirmation' : isGreen, 'ds-btn-continuation' : !isGreen}"
    >
      {{text}}
    </button>
  `,
  styleUrls: ['./floating-button.component.css', '../../common/styles/common.css']
})
export class FloatingButtonComponent {
  @Input() text: string = "default text";
  @Input() isGreen: boolean = true;
  @Input() disabled: boolean = false;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
}
