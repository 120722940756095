import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent {
  @Input() errorMsg!: Map<string, string>;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  closePopup(): void {
    this.close.emit();
  }

}
