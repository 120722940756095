import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExternalLinkService {
  private openedLink: string | null = null;

  constructor() {}

  isOpened(link: string): boolean {
    return this.openedLink === link;
  }

  setOpened(link: string) {
    this.openedLink = link;
  }

  clearOpened() {
    this.openedLink = null;
  }
}
