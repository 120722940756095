import {CoursComponent} from "./cours.component";
import * as G from "../cours/CoursGlobalValues";
import {AgeRange} from "../../common/age-range/age-range";

export class CoursFiltersLocalStorage {
  // nom espace stockage local
  static LOCAL_COURSE_TYPES: string = 'cours.course-types';
  static LOCAL_PLACES: string = 'cours.places';
  static LOCAL_AGE: string = 'cours.age';
  static LOCAL_DAYS: string = 'cours.days';
  static LOCAL_AUTO_FILTER: string = 'cours.auto-filter';
  static LOCAL_COURSE_NAMES: string = 'cours.course-names';

  static loadFromLocal(): [string|null, {min: number, max: number}|null, Map<string, boolean>|null, Map<string, boolean>|null, boolean|null, Map<string, boolean>|null] {
    // get from local
    let SelectedCourseType = localStorage.getItem(this.LOCAL_COURSE_TYPES);
    let SelectedAge = localStorage.getItem(this.LOCAL_AGE);
    let SelectedDays = localStorage.getItem(this.LOCAL_DAYS);
    let SelectedPlaces = localStorage.getItem(this.LOCAL_PLACES);
    let SelectedAutoFilter = localStorage.getItem(this.LOCAL_AUTO_FILTER);
    let SelectedCourseNames = localStorage.getItem(this.LOCAL_COURSE_NAMES);

    // condition NULL + CAST
    let types = SelectedCourseType!==null?JSON.parse(SelectedCourseType):null;
    let ageRange = SelectedAge!==null?JSON.parse(SelectedAge):null;
    let days = SelectedDays?new Map<string, boolean>(JSON.parse(SelectedDays)):null;
    let places = SelectedPlaces?new Map<string, boolean>(JSON.parse(SelectedPlaces)):null;
    let autoFilter = SelectedAutoFilter!==null?JSON.parse(SelectedAutoFilter):null;
    let courseNames = SelectedCourseNames?new Map<string, boolean>(JSON.parse(SelectedCourseNames)):null;

    return [types, ageRange, days, places, autoFilter, courseNames];
  }

  static saveToLocal({type, ageRange, days, places, autoFilter, courseNames}: {type?: string, ageRange?: any, days?: Map<string, boolean>, places?: Map<string, boolean>, autoFilter?: boolean, courseNames?: Map<string, boolean>}) {
    // la sauvegarde est effectué si les arguments ne sont pas nuls
    if(type)                   this.saveCoursTypeToLocal(type);
    if(ageRange)                this.saveAgeToLocal(ageRange);
    if(days)                    this.saveDaysToLocal(days);
    if(places)                  this.savePlacesToLocal(places);
    if(courseNames)             this.saveCourseNamesToLocal(courseNames);
    if(autoFilter!==undefined)  this.saveAutoFilterToLocal(autoFilter);
  }

  private static saveCoursTypeToLocal(type: string) {
    localStorage.setItem(this.LOCAL_COURSE_TYPES, JSON.stringify(type));
  }

  private static saveDaysToLocal(days: Map<string, boolean>) {
    localStorage.setItem(this.LOCAL_DAYS, JSON.stringify(Array.from(days.entries())));
  }

  private static saveAgeToLocal(ageRange: {min: number, max: number}) {
    localStorage.setItem(this.LOCAL_AGE, JSON.stringify(ageRange));
  }

  private static savePlacesToLocal(places: Map<string, boolean>) {
    // en plus de la sélection actuelle des lieux, stocke aussi les périodes précédentes.
    let oldValues = (CoursFiltersLocalStorage.loadFromLocal()[3]??new Map());
    places.forEach((v, k) => oldValues.set(k, v));
    localStorage.setItem(this.LOCAL_PLACES, JSON.stringify(Array.from(oldValues.entries())));
  }

  private static saveCourseNamesToLocal(courseNames: Map<string, boolean>) {
    let oldValues = (CoursFiltersLocalStorage.loadFromLocal()[5]??new Map());
    courseNames.forEach((v, k) => oldValues.set(k, v));
    localStorage.setItem(this.LOCAL_COURSE_NAMES, JSON.stringify(Array.from(oldValues.entries())));
  }
  private static saveAutoFilterToLocal(autoFilter: boolean) {
    localStorage.setItem(this.LOCAL_AUTO_FILTER, JSON.stringify(autoFilter));
  }

  // METHODE D'EXTENTION
  /**
   * Charge les filtres précédents enregistrés en local, si non une valeur par défaut est définit. Les données chargées
   * sont affectés aux attributs de cette classe, ce qui a un effet dynamique sur le visuel, là où les attributs sont
   * utilisés dans la vue.
   * @private
   */
  static async loadLocalFilters(this: CoursComponent) {
    let [
      type,
      ageRange,
      days,
      _,
      autoFilter
    ] = CoursFiltersLocalStorage.loadFromLocal();

    // load age
    this.Model.ageRange = new AgeRange(ageRange?.min??G.AGE_MIN, ageRange?.max??G.AGE_MAX, G.AGE_MIN, G.AGE_MAX);

    // load auto-filter
    this.Model.isAutoApplyFilters = autoFilter??false;

    // type cours
    if(type !== null){
      this.Model.selectedType = type??'';
    }else{
      const courseTypes = this.getCourseTypes();
      courseTypes.forEach(item => {
        this.Model.courseTypes.set(item, false);
      });
    }
    // days
    this.Model.days = days??this.Model.getInitDaysMap();
    // if (selectedPeriode) await this.initPeriods(); // TODO lancer initialisation
  }

  static resetLocal() {
    localStorage.removeItem(this.LOCAL_COURSE_TYPES);
    localStorage.removeItem(this.LOCAL_PLACES);
    localStorage.removeItem(this.LOCAL_DAYS);
    localStorage.removeItem(this.LOCAL_AGE);
    localStorage.removeItem(this.LOCAL_AUTO_FILTER);
    localStorage.removeItem(this.LOCAL_COURSE_NAMES);
  }
}
