import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-popup-modal',
  template: `
    <div class="loading-dialog-overlay">
      <div class="loading-dialog-container">
        <div class="loading-spinner"></div>
        <h2>{{ data.titre }}</h2>
      </div>
    </div>

  `,
  styleUrls: ['./popup-modal.component.css'],
})
export class PopupModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    titre: string,
    width: number
  }) {}
}
