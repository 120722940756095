import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-confirmation-pop-up',
  templateUrl: './confirmation-pop-up.component.html',
  styleUrls: ['./confirmation-pop-up.component.css', '../styles/common.css']
})
export class ConfirmationPopUpComponent {
  @Input() title: string = '';
  @Input() message: string = '';
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();


  closePopup(): void {
    this.close.emit();
  }

  confirmChoice(): void {
    this.confirm.emit();
  }

  cancelChoice(): void {
    this.close.emit();
  }
}
