export class FormLoadingStatus {
  _message: string;
  _classes: any[];
  _isValid : boolean;
  _isLoading : boolean;

  constructor(message: string, classes: any[], isValid : boolean, isLoading : boolean) {
    this._message = message;
    this._classes = classes;
    this._isValid = isValid;
    this._isLoading = isLoading;
  }

  get message() {return this._message}
  get classes() {return this._classes}
  get isValid() {return this._isValid}
  get isLoading() {return this._isLoading}
}

export enum LoadingStatus {
  LOADING,
  LOADED,
  TIME_OUT,
  ERROR,
  NET_ERROR
}
