import { Component, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import { environment } from '../../../../environments/environment';
import {DataService} from "../../../service/data.service";
import {Subject} from "rxjs";


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isVisible = false;
  displayedVersion = "0.0.0";
  backendVersion = "";

  constructor(private footerService: FooterService, private dataService: DataService) { }

  ngOnInit(): void {
    this.footerService.getFooterVisibility().subscribe((visible: boolean) => {
      this.isVisible = visible;
    });

    this.dataService.getBackendVersion().subscribe({
      next: (data) => {
        if (data && typeof data === 'object' && 'version' in data) {
          this.backendVersion = (data as any).version as string;
          this.displayedVersion = (environment.version === this.backendVersion)
            ? environment.version
            : `${environment.version} (backend: ${this.backendVersion})`;
        } else {
          console.error("Réponse invalide du backend:", data);
        }
      },
      error: (error) => {
        console.error("Erreur lors de la récupération de la version du back:", error);
      }
    });

  }

  protected readonly environment = environment;
}
