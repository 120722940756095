<section class="shadow-sm p-2 pb-3 mb-5 bg-white rounded mx-3 d-flex flex-column" id="facturationMenu">
  <span class="field2-section d-block mb-2">Taille T-shirt</span>
  <div class="checkboxDiv">
    <label>Veuillez renseigner la taille de t-shirt désirée pour le participant.</label>
  </div>
    <div class="custom-select2" (click)="toggleSelect()">
      <div class="select-selected">{{ values.get(selectedOption) || this.defaultMessage }}</div>
      <div class="select-items" [class.show]="isSelectOpen">
        <div *ngFor="let option of options" (click)="callBoth(option)">
          {{ option }}
        </div>
      </div>
    </div>
</section>
