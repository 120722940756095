import {Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ExternalLinkService} from "../../../external-link.service";
import {DataService} from "../../../service/data.service";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('notification', { static: true }) notification!: ElementRef;
  registration: ServiceWorkerRegistration | undefined;

  visitedHomePage: boolean = false;
  showPopup: boolean = false;

  private externalLinkService: ExternalLinkService = new ExternalLinkService();

  constructor(private router: Router, private renderer: Renderer2, private dataService: DataService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.applyBackgroundImage(event.url);
      }
    });
  }

  async ngOnInit() {
    this.visitedHomePage = sessionStorage.getItem('visitedHomePage') === 'true';
    if (!this.visitedHomePage) {
      sessionStorage.setItem('visitedHomePage', 'true');
    }

    // this.registration = await navigator.serviceWorker.getRegistration();
    // Vérifier si le service worker est disponible
    if ('serviceWorker' in navigator) {
      try {
        this.registration = await navigator.serviceWorker.getRegistration();
        if (!this.registration) {
          console.error("No service worker registration found.");
        } else {
          console.log("Service worker registered successfully:", this.registration);
        }
      } catch (error) {
        console.error("Error during service worker registration:", error);
      }
    } else {
      console.error("Service workers are not supported in this browser.");
    }
  }

  async testNotification() {
    if (Notification.permission === 'granted') {
      console.log('Notification permission granted');
      this.showNotification('Test notification');
    } else if (Notification.permission !== 'denied') {
      console.log('Requesting notification permission');
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        this.showNotification('Test notification');
      }
    }else {
      console.log('Notification permission denied');
    }
  }

  // showNotification(body: string) {
  //   const title = 'Dimension Sport';
  //   const payload = { body };
  //
  //   if (this.registration && 'showNotification' in this.registration) {
  //     console.log('Using service worker to show notification');
  //     this.registration.showNotification(title, payload);
  //   } else {
  //     console.log('Using standard notification API');
  //     new Notification(title, payload);
  //   }
  // }

  showNotification(body: string) {
    const title = 'Dimension Sport';
    const payload = { body };

    // Utiliser toujours le service worker pour afficher les notifications
    if (this.registration && 'showNotification' in this.registration) {
      console.log('Using service worker to show notification');
      this.registration.showNotification(title, payload);
    } else {
      console.error('Service worker registration is not available.');
    }
  }

  applyBackgroundImage(url: string) {
    if (url === '/' || url === '/home') {
      document.body.classList.add('background_home');
    } else {
      document.body.classList.remove('background_home');
    }
  }
    protected readonly window = window;



  // Click on app install button
  public promptEvent : any;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e : any) {
    e.preventDefault();
    this.promptEvent = e;
  }

  public installPWA() {
    this.promptEvent.prompt();
    this.promptEvent.userChoice
      .then((choiceResult : any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
          // Pop-up de confirmation
          this.togglePopup();
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.promptEvent = null;
      });
  }

  public shouldInstall(): boolean {
    // console.log('shouldInstall')
    // console.log('Running standalone: ' + this.isRunningStandalone())
    return !this.isRunningStandalone() && this.promptEvent;
  }

  public isRunningStandalone(): boolean {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }


  // External link
  openExternalLink(url: string) {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      console.log('Window opened successfully');
      // The link opened successfully in a new window or tab.
    } else {
      console.log('Window blocked');
      // A pop-up blocker prevented the link from opening.
      // You can handle this case as needed.
    }
  }

  togglePopup(): void {
    this.showPopup = !this.showPopup;
  }

  requestNotificationPermission() {
    // Request notification permission
    let promise = Notification.requestPermission();

    promise.then(permission => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // You can now show notifications
      } else {
        console.log("Notification permission denied.");
      }
    }).catch(error => {
      console.error("An error occurred while requesting notification permission:", error);
    });
  }

}
interface BackendVersion {
  version: string;
}
