import {StagesFiltersLocalStorage} from "./StagesFiltersLocalStorage";
import {Week} from "../../../models/Week";
import {StagesComponent} from "./stages.component";
import {LoadingStatus} from "../../../models/FormLoadingStatus";
const {LOADING, LOADED, TIME_OUT, NET_ERROR, ERROR} = LoadingStatus;
import {AgeRange} from "../../common/age-range/age-range";

export class StagesEvent {
  private static REMOVE_DELAY: number = 500;

  /**
   * RÉINITIALISE les filtres sauvegardés en local.
   */
  static onResetFiltersClick(this: StagesComponent) {
    StagesFiltersLocalStorage.resetLocal()

    this.Model.periods.clear();
    this.Model.weeks.clear();
    this.Model.places.clear();
    this.Model.ageRange.resetRange();
    this.clearStages();
    this.paginator.clearTotal();
    this.resetFilters$.next();
    this.loadPeriods();
  }

  /**
   * Active ou désactive les filtres automatiques et enregistre ele choix en local
   */
  static onAutoFilterClick(this: StagesComponent) {
    this.Model.isAutoApplyFilters = !this.Model.isAutoApplyFilters;
    if (this.Model.isAutoApplyFilters) this.Stream.filteredStages$.next('confirm-button');
    StagesFiltersLocalStorage.saveToLocal({autoFilter: this.Model.isAutoApplyFilters});
  }

  /**
   * Méthode appelée par un évènement utilisateur (sélection période). Une appelle de requetes en cascade est lancée
   * pour charger les semaines des périodes, les lieux des semaines, et les stages se trouvant en ces lieux.
   * @param period période sélectionné
   * @param value si coché ou pas
   * @param skipLoading si la suppression nécessite un rechargement dans le cas où une sélection vive a été faite
   * précédement.
   */
  static async onPeriodClick(this: StagesComponent, period: string, value: boolean, skipLoading : boolean) {
    this.Model.coursesCalledByButton = false;

    if(value || skipLoading){
      let selectedPeriods = [...this.Model.periods.keys()].filter(p => this.Model.periods.get(p));
      await this.loadWeeks(selectedPeriods);
    }

    if (!value) {
      let unselectedPeriods = [...this.Model.periods.keys()].filter(p => !this.Model.periods.get(p));
      // pour s'assurer que la fonction n'est pas appelé avant la requête qui a un délais.
      setTimeout(() => this.removeWeeksForPeriod(unselectedPeriods), 0);
      setTimeout(() => this.removeWeeksForPeriod(unselectedPeriods), StagesEvent.REMOVE_DELAY/2);
      setTimeout(() => this.removeWeeksForPeriod(unselectedPeriods), StagesEvent.REMOVE_DELAY);
    }

    this.Stream.filteredStages$.next('filterPeriod');
  }

  /**
   * Méthode appelée par un évènement utilisateur (sélection semaines). lorsqu'une semaine est sélectionné ou
   * désélectionné, son état est mis à jour dans le stockage local des filtres. En fonction de si la semaine cliquée
   * est sélectionné ou pas, les lieux de stage sont ajoutés ou retirés.
   * @param week
   * @param value
   * @param skipLoading
   */
  static async onWeekClick(this: StagesComponent, week: Week|any, value: boolean, skipLoading : boolean){
    this.Model.coursesCalledByButton = false;

    if(value || skipLoading){
      let selectedWeeks = [...this.Model.weeks.keys()].filter((w) => this.Model.weeks.get(w))
      await this.loadPlaces(selectedWeeks).then();
    }

    if (!value) {
      setTimeout(() => this.removePlacesForWeek(week), 0);
      setTimeout(() => this.removePlacesForWeek(week), StagesEvent.REMOVE_DELAY/2);
      setTimeout(() => this.removePlacesForWeek(week), StagesEvent.REMOVE_DELAY);
    }

    this.Stream.filteredStages$.next('filterWeek');
  }

  /**
   * Méthode appelée par un évènement utilisateur (sélection lieux).
   * @param value
   * @param checked
   * @protected
   */
  static onPlaceClick(this: StagesComponent, value: string, checked: boolean){
    if(!value) return;

    this.Model.coursesCalledByButton = false;

    // si la valeur sélectionné est <Select All> tout est sélectionné, si non seul un élement est mise à jours
    if(value === 'multiselect-all'){
      [...this.Model.places.keys()].forEach(key => {
        this.Model.places.set(key, checked);
      });
    }else{
      this.Model.places.set(value, checked);
    }
    StagesFiltersLocalStorage.saveToLocal({places: this.Model.places});

    // vidage des anciens résultats
    let hasSelectedPlaces = [...this.Model.weeks.values()].some((value) => value)
    if (!hasSelectedPlaces) {
      this.loadingStatus.place = LOADED;
    }
    this.updatePlacesMultiselect();
  }

  /**
   * Lorsque l'age change, une mise à jour est lancé.
   * @protected
   * @param ageRange
   */
  static onAgeUpdate(this: StagesComponent, ageRange: AgeRange) {
    // Si l'age ancien est identique au nouveau, pas de changement à faire
    // if (this.Model.ageRange.isSameAs(ageRange)) return;

    this.Model.coursesCalledByButton = false;
    this.Model.ageRange = ageRange;
    this.Stream.filteredStages$.next('filterAge');
    StagesFiltersLocalStorage.saveToLocal({ageRange: this.Model.ageRange});
  }

  /**
   * Lorsqu'une pagination est sélectionné, on vérifie la cohérence, si telle page de résultat est cohérent.
   * Puis la demande de stages pour telle page est placé dans la filte d'attente du flux, this.pagination est accessible
   * @param cursor indice de page de stages à afficher
   */
  static onPaginationClick(this: StagesComponent, cursor: number) {
    // check cohérence via prop de l'obj Paginator
    this.paginator.cursor = cursor;

    // rechercher les stages
    this.Stream.filteredStages$.next('paginator')
  }
}
