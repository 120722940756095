import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {StageRecap} from "../../../models/StageRecap";
import {DataService} from "../../../service/data.service";
import {Member} from "../../../models/Member";
import moment from "moment";

@Component({
  selector: 'app-stage-card-recap',
  templateUrl: './stage-card-recap.component.html',
  styleUrls: ['./stage-card-recap.component.css']
})
export class StageCardRecapComponent {
  @Input() public Stage:StageRecap
  @Input() public Member: Member

  prix: number = 0;
  constructor(private router: Router, private dataService:DataService) {
    this.Stage = new StageRecap();
    this.Member = new Member()
  }


  ngOnInit() {
    // console.log("TypeStage", this.Stage.TypeStage, typeof this.Stage.TypeStage);
    // console.log("PrixJournee", this.Stage.PrixJournee, typeof this.Stage.PrixJournee);
    // console.log("PrixDemiJournee", this.Stage.PrixDemiJournee, typeof this.Stage.PrixDemiJournee);
    // console.log("Reduction", this.Stage.Reduction, typeof this.Stage.Reduction);
    // console.log("Remboursement", this.Stage.Remboursement, typeof this.Stage.Remboursement);
    // console.log("Acompte", this.Stage.Acompte, typeof this.Stage.Acompte);
    // console.log("Acompte2", this.Stage.Acompte2, typeof this.Stage.Acompte2);
    // console.log("NbreDeJours", this.Stage.NombreDeJours, typeof this.Stage.NombreDeJours);

  // let PrixJournee : number = parseInt(this.Stage.PrixJournee);
  //     let PrixDemiJournee : number = parseInt(this.Stage.PrixDemiJournee);
  //     let Reduction : number = parseInt(this.Stage.Reduction);
  //     let Remboursement : number = parseInt(this.Stage.Remboursement);
  //     let Acompte : number = parseInt(this.Stage.Acompte);
  //     let Acompte2 : number = parseInt(this.Stage.Acompte2);
  //     let NombreDeJours : number = parseInt(this.Stage.NombreDeJours);
    this.prix = 0;

    if (this.Stage.TypeStage.toString() === "JC") {
      console.log("Stage is JC");
      this.prix = Number(this.Stage.PrixJournee);
    } else {
      console.log("Stage is AM or PM");
      this.prix = Number(this.Stage.PrixDemiJournee);
    }

    console.log("Prix : ", this.prix);
    console.log("Type of Prix : ", typeof this.prix);

    this.prix -= Number(this.Stage.Reduction) - Number(this.Stage.Remboursement)
      + Number(this.Stage.Acompte) + Number(this.Stage.Acompte2) + Number(this.Stage.Solde);

    console.log("Prix : ", this.prix);
    console.log("Type of Prix : ", typeof this.prix);
    console.log("Stage : ", this.Stage);
  }
  /**
   * When stage card clicked, redirect to stage details
   */
  onStageClick() {
    setTimeout(() => this.router.navigate(['/stages/', this.Stage.NumeroInstanceStage]), 500)
  }

  onDl_attestationFiscale() {
    this.dataService
      .getAttestationFiscale(this.Member.numero_personne, this.Stage.NumeroInstanceStage, this.Stage.NumeroInscriptionStage)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        console.log(a)
        a.download = "Dimension-Sport_AttestationFiscale_" + this.Member.nom + '_' + this.Member.prenom + '_Stage_' + moment(this.Stage.DateDebutInstanceStage).format('DD-MM-YYYY') + '_' + moment(this.Stage.DateFinInstanceStage).format('DD-MM-YYYY') + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url); // Clean up
      });
  }

  onDl_attestationMutuelle() {
    this.dataService
      .getAttestationMutuelle(this.Member.numero_personne, this.Stage.NumeroInstanceStage, this.Stage.NumeroInscriptionStage)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "Dimension-Sport_AttestationMutuelle_" + this.Member.nom + '_' + this.Member.prenom + '_Stage_' + moment(this.Stage.DateDebutInstanceStage).format('DD-MM-YYYY') + '_' + moment(this.Stage.DateFinInstanceStage).format('DD-MM-YYYY') + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url); // Clean up
      });
  }

  onDl_attestationParticipation() {
    this.dataService
      .getAttestationParticipation(this.Member.numero_personne, this.Stage.NumeroInstanceStage, this.Stage.NumeroInscriptionStage)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "Dimension-Sport_AttestationParticipation_" + this.Member.nom + '_' + this.Member.prenom + '_Stage_' + moment(this.Stage.DateDebutInstanceStage).format('DD-MM-YYYY') + '_' + moment(this.Stage.DateFinInstanceStage).format('DD-MM-YYYY') + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url); // Clean up
      });
  }

  protected readonly isPastNextMonday = isPastNextMonday;
}
function getNextMonday(date: Date): Date {
  const dayOfWeek = date.getDay(); // Sunday is 0, Monday is 1, etc.

  // Calculate days until next Monday
  const daysUntilNextMonday = (dayOfWeek === 0) ? 1 : 8 - dayOfWeek;

  // Create a new date representing the next Monday
  const nextMonday = new Date(date);
  nextMonday.setDate(date.getDate() + daysUntilNextMonday);

  return nextMonday;
}

function isPastNextMonday(date: Date): boolean {
  const nextMonday = getNextMonday(date);
  const today = new Date();

  // Check if today is past the next Monday
  return today >= nextMonday;
}

