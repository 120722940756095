import {Component, EventEmitter, Input, Output, SecurityContext} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {FIRSTNAMESurnamePipe} from "../../../pipes/firstnamesurname.pipe";
import {MedicalInformation} from "../../../models/MedicalInformation";
import {DataService} from "../../../service/data.service";
import {InscriptionStage} from "../../../models/inscriptionStage";

@Component({
    selector: 'app-medical-info-card',
    templateUrl: './medical-info-card.component.html',
    styleUrls: ['./medical-info-card.component.css', '../../common/styles/common.css']
})
export class MedicalInfoCardComponent {
    protected allInfos : InscriptionStage = JSON.parse(sessionStorage.getItem('inscriptionStage') || '{}');
    @Input() isLoading!: boolean; // = this.allInfos.info_medical == undefined;
    isMenuOpen: boolean = false;
    @Output() onElemClick = new EventEmitter<MedicalInformation>()
    @Input() medicalInfo!: MedicalInformation;
    @Input() inscritID!: number;
    error: boolean = false;
    annee: number = new Date().getFullYear();


    constructor(private sanitizer: DomSanitizer, private dataService: DataService) {
    }

    medecinNom!: string;
    medecinTel!: string;
    medecinTetanos!: number;
    medecinAllergies!: string;
    medecinMedicaments!: string;
    medecinDonneesSpe!: string;
    protected readonly FIRSTNAMESurnamePipe = FIRSTNAMESurnamePipe;

    ngOnInit() {
      console.log(this.isLoading);
      console.log(this.allInfos);
        if (this.isLoading) {
            this.getMedicalInfo();
        } else {
            this.medecinNom = this.medicalInfo.medecin ?? '';
            this.medecinTel = this.medicalInfo.gsm ?? '';
            this.medecinTetanos = this.medicalInfo.annee_tetanos;
            this.medecinAllergies = this.medicalInfo.allergies ?? '';
            this.medecinMedicaments = this.medicalInfo.medicaments ?? '';
            this.sendDataToParent();
            this.isLoading = false;
        }
    }

    private getMedicalInfo() {
        return new Promise<void>((resolve, reject) => {
            this.dataService.getMedicalInformations(this.inscritID).subscribe({
                next: (element: any) => {
                    if (element) {
                        this.medicalInfo = new MedicalInformation(
                            element.MEDECIN,
                            element.GSM,
                            element.ANNEE_TETANOS,
                            element.ALLERGIES,
                            element.MEDICAMENTS,
                            element.REMARQUES,
                        );
                        this.isLoading = false;
                        this.ngOnInit();
                        resolve();
                    } else {
                        // Handle the case when no data is returned
                        resolve();
                    }
                },
                error: (error) => {
                    this.error = true;
                    reject(error);
                },
            });
        });
    }

    sendDataToParent() {
        /*Version sécurisée en renvoyant des string */
        // console.log("(Medical-info-card) - sendDataToParent() : START");
        this.medicalInfo.medecin = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.medecinNom)) as string; // Assuming 'medecinNom' is a string
        this.medicalInfo.gsm = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.medecinTel)) as string; // Assuming 'medecinTel' is a string
        this.medicalInfo.annee_tetanos = this.medecinTetanos; // Assuming 'medecinTetanos' is a Date
        this.medicalInfo.allergies = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.medecinAllergies)) as string; // Assuming 'medecinAllergies' is a string
        this.medicalInfo.medicaments = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.medecinMedicaments)) as string; // Assuming 'medecinMedicaments' is a string
        this.medicalInfo.remarques = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.medecinDonneesSpe)) as string; // Assuming 'medecinDonneesSpe' is a string

        this.onElemClick.emit(this.medicalInfo);
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }
}
