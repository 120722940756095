import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../../service/data.service";
import {Member} from "../../../../models/Member";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CustomValidators} from "../input/CustomValidators";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-recherche-membre',
  templateUrl: './recherche-membre.component.html',
  styleUrls: ['./recherche-membre.component.css', '../../../common/styles/common.css']
})
export class RechercheMembreComponent implements OnInit {

  searchForm!:FormGroup
  protected buttonStatus : 'loading' | 'idle' = 'idle';
  protected statusMessage : string = '';
  protected submitted: boolean = false;
  selectedLink: string;
  returnName: string = 'Retour';
  member: Member = new Member();

  constructor(
    private dataService:DataService,
    private router:Router,
    private fb : FormBuilder,
    private dialog: MatDialog,
    ) {

    const selectedStage = sessionStorage.getItem('selectedStage');
    const selectedCours = sessionStorage.getItem('selectedCours');
    this.selectedLink = selectedStage ? '/inscription.stages.selection-membre-stage' :
                        selectedCours ? '/inscription.cours.selection-membre-cours' : '/gestion-compte';

    this.returnName = selectedStage ? 'Étape 1/3 : Sélectionner un membre' :
                      selectedCours ? 'Étape 1/3 : Sélectionner un membre' : 'Compte';


  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      nom: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      prenom: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      dateNaissance: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
    })
  }

  validateForm() {
    this.submitted = true;

    // Mark all controls as touched to trigger validation messages
    this.searchForm.markAllAsTouched();

    // Check if any of the form controls have invalid validators
    const invalidControls = Object.keys(this.searchForm.controls).filter(controlName =>
      this.searchForm.get(controlName)?.invalid
    );

    // If there are invalid controls, handle them accordingly
    if (invalidControls.length > 0) {
      // Handle the case when there are invalid controls
      console.error("Invalid validators found:", invalidControls);
      // You can show a message or perform any other action here
    } else {
      // All validators are valid, proceed with form submission
      this.submitForm();
    }
  }

  async submitForm() {
    // mise à jour des états
    this.buttonStatus = "loading";

    // vérifie si le numéro du compte est stocké en local
    let data = localStorage.getItem('selectedCompte');
    let user;
    if (!data || !(user = JSON.parse(data)) || !user.numero_compte) {
      this.router.navigateByUrl(`connexion`);
    } else {
      let numCompte = user.numero_compte;
      let login = user.login;
      try {
        this.member = await this.sendRequestSearchMember();
        if(this.member.numero_personne == 0) {
          this.statusMessage = 'Aucun membre trouvé. Vérifiez vos informations et lancez une autre recherche.';
        } else if(this.member.numero_compte !== 0 && this.member.numero_compte !== null) {
          this.statusMessage = 'Ce membre est déjà attaché à un autre compte.\n' +
            'Connectez vous à ce compte, créez un nouveau membre ou vérifiez vos informations et lancez une autre recherche.';
        } else {
          sessionStorage.setItem('foundMember', JSON.stringify(this.member));
          this.router.navigate(['/confirmation-membre']);
        }

      } catch (error) {

      }
    }
  }

  sendRequestSearchMember() {
    return new Promise<Member>((resolve, reject) => {
      const nom = this.searchForm.get('nom')?.value;
      const prenom = this.searchForm.get('prenom')?.value;
      const dateNaissance = this.searchForm.get('dateNaissance')?.value;
     this.dataService.getMember(nom, prenom, dateNaissance).subscribe({
         next: (element: any) => {
           let member: Member;
           if (element) {
             member = new Member(
               element.NUMERO_PERSONNE,
               element.NOM,
               element.PRENOM,
               new Date(element.DATE_NAISSANCE),
               element.SEXE,
               element.GSM,
               element.COMMENTAIRES,
               element.REMARQUES,
               element.PROFESSION_PARENTS,
               element.FONCTION,
               element.SPORT_PRATIQUE,
               element.ECOLE,
               element.NUMERO_COMPTE,
               element.NUMERO_NATIONAL,
               element.EMAIL,
               element.INFO_MEDICAL)
           } else {
             member = new Member();
           }
           resolve(member);
         },
         error: (error) => {
           reject(error);
         },
         complete: () => {
           // You can add clean-up logic here if needed
         }
       });
     });
  }
}
