import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {debounceTime, filter, Observable, Subject} from "rxjs";
import {AgeRange} from "./age-range";
import {MatSliderModule} from '@angular/material/slider';
import {FormBuilder, FormGroup} from "@angular/forms";
import {CustomValidators} from "../../pages/membre/input/CustomValidators";

declare var $:any;

@Component({
  selector: 'app-age-range',
  templateUrl: './age-range.component.html',
  styleUrls: ['./age-range.component.css', '../../common/styles/common.css']
})
export class AgeRangeComponent implements OnInit, AfterViewInit{
  @ViewChild('matSliderStartThumb') minThumb!: ElementRef;
  @ViewChild('matSliderEndThumb') maxThumb!: ElementRef;
  @ViewChild('minBadge') minBadge!: ElementRef;
  @ViewChild('maxBadge') maxBadge!: ElementRef;


  @Input() ageRange!: AgeRange;
  @Input() resetEvent$!: Subject<void>;
  @Input() delay: number = 100;
  @Output() ageUpdate = new EventEmitter<AgeRange>()

  ageForm!: FormGroup;
  selectedField: 'minAge' | 'maxAge' = 'minAge';

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef) {  }

  ngOnInit() {

    this.ageForm = this.fb.group({
      minAge: [this.ageRange.minLimit, [
        CustomValidators.min(0),
        CustomValidators.max(16)
      ]],
      maxAge: this.ageRange.maxLimit
    });
  }

  // Méthode appelée lorsque le slider est déplacé
  onSliderChange(event: any): void {
    const min = this.minThumb.nativeElement.value;
    const max = this.maxThumb.nativeElement.value;

    this.ageUpdate.emit(new AgeRange(min, max, this.ageRange.minLimit, this.ageRange.maxLimit));
  }

  onMinBadgeChange(event: any) {
    const value = parseInt(event.target.value, 10);
    const clampedValue = Math.min(Math.max(value, this.ageRange.minLimit), this.ageRange.maxLimit);
    this.ageForm.patchValue({ minAge: clampedValue });

    this.ageUpdate.emit(new AgeRange(clampedValue, this.ageForm.get('maxAge')?.value, this.ageRange.minLimit, this.ageRange.maxLimit));
  }

  onMaxBadgeChange(event: any) {
    const value = parseInt(event.target.value, 10);
    const clampedValue = Math.min(Math.max(value, this.ageRange.minLimit), this.ageRange.maxLimit);
    this.ageForm.patchValue({ maxAge: clampedValue });

    this.ageUpdate.emit(new AgeRange(this.ageForm.get('minAge')?.value, clampedValue, this.ageRange.minLimit, this.ageRange.maxLimit));
  }


  ngAfterViewInit(): void {
  }

  // onMinBadgeChange() {
  //   const min = this.minBadge.nativeElement.value;
  //   if(min < this.ageRange.minLimit || min > this.ageRange.maxLimit) {
  //     return
  //   }
  //   this.ageUpdate.emit(new AgeRange(min, this.ageRange.max, this.ageRange.minLimit, this.ageRange.maxLimit));
  // }
  //
  // onMaxBadgeChange() {
  //   const max = this.maxBadge.nativeElement.value;
  //   if(max < this.ageRange.minLimit || max > this.ageRange.maxLimit) {
  //     return
  //   }
  //   this.ageUpdate.emit(new AgeRange(this.ageRange.min, max, this.ageRange.minLimit, this.ageRange.maxLimit));
  // }

  onMinSave() {
    // const min = this.minBadge.nativeElement.value;
    // this.ageForm.patchValue({ minAge: min });
  }

  onMaxSave() {
    // const max = this.maxBadge.nativeElement.value;
    // this.ageForm.patchValue({ minAge: max });
  }

  moveCursorToEnd(field: 'minAge' | 'maxAge') {
    const inputElement = field === 'minAge' ? this.minBadge.nativeElement : this.maxBadge.nativeElement;

    // Change temporairement le type en 'text' pour permettre le placement du curseur
    inputElement.type = 'text';

    // Place le curseur à la fin
    const length = inputElement.value.length;
    inputElement.setSelectionRange(length, length);

    // Rétablit le type en 'number' pour respecter la logique du champ
    setTimeout(() => {
      inputElement.type = 'number';
    });
  }
}
