import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliderLimit'
})
export class SliderLimitPipe implements PipeTransform {

  transform(value: number | string, min: number, max: number): number {
    let numericValue = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numericValue)) {
      return min; // Par défaut, retourne la borne minimale
    }

    return Math.min(Math.max(numericValue, min), max);
  }

}
