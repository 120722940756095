<!--css-->
<link href="https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="multi-select.css">
<!--script-->
<script defer src="js/jquery.min.js"></script>
<script defer src="js/popper.js"></script>
<script defer src="js/bootstrap.min.js"></script>
<script defer src="js/bootstrap-multiselect.js"></script>
<script defer src="js/main.js"></script>

<select [id]="Id" multiple="multiple">
  <option *ngFor="let item of values; index as i" [selected]=Enables[i]>{{item}}</option>
</select>
