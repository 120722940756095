import {Component, HostListener, OnInit} from '@angular/core';
import {window} from "rxjs";
import {Router} from "@angular/router";
import {LocationStrategy} from "@angular/common";
import {FooterService} from "./components/common/footer/footer.service";
import {UpdateService} from "./service/update.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'DSMobile';
  showPopupOrientation = false;
  showPopupIOS = false;
  showPopupSafari = false;
  showPopupAndroid = false;
  showPopupOthers = false;
  public iosMessage: string;
  public safariMessage: string;
  public androidMessage: string;
  public othersMessage: string;

  constructor(private router: Router, private footerService: FooterService, private updateService: UpdateService) {
    this.iosMessage = '';
    this.safariMessage = '';
    this.androidMessage = '';
    this.othersMessage = '';
    this.createMessages();
  }

  getUrl() {
    // console.log(this.router.url);
  }

  // Si le téléphone est à l'horizontal, on affiche un message
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event:any) {
    this.showPopupOrientation = (screen.orientation.angle === 90 || screen.orientation.angle === 270);
  }

  // Si le téléphone a comme OS ios, on affiche un message
  @HostListener('window:load', ['$event']) onLoad(event:any) {
    if(matchMedia('(display-mode: standalone)').matches) {
      console.log("PWA installée");
      return;
    }

    const userAgent = navigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(userAgent)) {
      // console.log("IOS");
      this.togglePopupIOS();
    } else if (/android/.test(userAgent)) {
      // console.log("Android");
      // this.togglePopupAndroid();
    } else if (/safari/.test(userAgent) && !/chrome|windows/.test(userAgent)) {
      // console.log("Safari");
      this.togglePopupSafari();
    } else {
      // console.log("Others");
      // this.togglePopupOthers();
    }
  }

  togglePopupOrientation() {
    this.showPopupOrientation = !this.showPopupOrientation;
  }

  togglePopupIOS() {
    this.showPopupIOS = !this.showPopupIOS;
  }

  togglePopupSafari() {
    this.showPopupSafari = !this.showPopupSafari;
  }

  togglePopupAndroid() {
    this.showPopupAndroid = !this.showPopupAndroid;
  }

  togglePopupOthers() {
    this.showPopupOthers = !this.showPopupOthers;
  }

  createMessages() {
    this.iosMessage = `Vous êtes sur un appareil Apple. <br>
      Pour installer l'application :<br>
      - Rendez-vous sur Safari (à cette url)<br>
      - Cliquez sur "Partager" (Share)<br>
      - Cliquez sur "Ajouter à l'écran d'accueil"
    `
      // + navigator.userAgent.toLowerCase()
    ;

    this.safariMessage = `Vous utilisez le navigateur Safari. <br>
        Si vous utilisez un appareil Apple, voici la marche à suivre pour installer l'application :<br>
      - Allez dans les paramètres<br>
      - Cliquez sur "Partager" (Share)<br>
      - Cliquez sur "Ajouter à l'écran d'accueil"
    `
      // + navigator.userAgent.toLowerCase()
    ;

    this.androidMessage = `
      Vous êtes sur un téléphone Android. <br>
      <br>
      Pas de problème pour la PWA. (Message temporaire)
    `
      // + navigator.userAgent.toLowerCase()
    ;

    this.othersMessage = `
      Vous êtes sur un téléphone qui n'est ni un IOS, ni un Android. <br>
      <br>
      Pas de problème pour la PWA. (Message temporaire)
    `
      // + navigator.userAgent.toLowerCase()
    ;
  }
}

