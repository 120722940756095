import {AgeRange} from "../../common/age-range/age-range";
import {CoursComponent} from "./cours.component";
import {CoursFiltersLocalStorage} from "./CoursFiltersLocalStorage";
import {LoadingStatus} from "../../../models/FormLoadingStatus";
import {StagesFiltersLocalStorage} from "../stages/StagesFiltersLocalStorage";

const {LOADING, LOADED, TIME_OUT, NET_ERROR, ERROR} = LoadingStatus;

export class CoursEvent {

  static onResetFiltersClick(this: CoursComponent): void {
    CoursFiltersLocalStorage.resetLocal()

    this.Model.courseTypes.clear();
    this.Model.places.clear();
    this.Model.courseNames.clear();
    this.Model.days.clear();
    this.Model.ageRange.resetRange();
    this.clearCourses();
    this.paginator.clearTotal();
    this.resetFilters$.next();
    this.loadTypeCours();
  }

  static onAutoFilterClick(this: CoursComponent): void {
    this.Model.isAutoApplyFilters = !this.Model.isAutoApplyFilters;
    if (this.Model.isAutoApplyFilters) this.Stream.filteredCourses$.next('confirm-button');
    CoursFiltersLocalStorage.saveToLocal({autoFilter: this.Model.isAutoApplyFilters});
  }

  static async onCoursTypeClick(this: CoursComponent, type: string) {
    this.Model.stagesCalledByButton = false;

    // 1. Mise à jours Model
    this.Model.selectedType = type;

    // 2. enregistrement du filtre en local
    CoursFiltersLocalStorage.saveToLocal({type: this.Model.selectedType});

    // 3. notify next component
    this.Stream.places$.next(type);
    this.Stream.courseNames$.next(type);
  }

  static async onPlaceClick(this: CoursComponent, key: string, value: boolean){
    if(!key) return;

    this.Model.stagesCalledByButton = false;

    // si la valeur sélectionné est <Select All> tout est sélectionné, si non seul un élement est mise à jours
    if(key === 'multiselect-all'){
      [...this.Model.places.keys()].forEach(key => {
        this.Model.places.set(key, value);
      });
    }else{
      this.Model.places.set(key, value);
    }
    CoursFiltersLocalStorage.saveToLocal({places: this.Model.places});

    this.Stream.filteredCourses$.next('filter-place');
  }


  static async onCourseNameClick(this: CoursComponent, key: string, value: boolean){
    //TODO modify this
    if(!key) return;

    this.Model.stagesCalledByButton = false;

    // si la valeur sélectionné est <Select All> tout est sélectionné, si non seul un élement est mise à jours
    if(key === 'multiselect-all'){
      [...this.Model.courseNames.keys()].forEach(key => {
        this.Model.courseNames.set(key, value);
      });
    }else{
      this.Model.courseNames.set(key, value);
    }
    CoursFiltersLocalStorage.saveToLocal({courseNames: this.Model.courseNames});

    this.Stream.filteredCourses$.next('filter-course-name');
  }

  static onAgeUpdate(this: CoursComponent, ageRange: AgeRange) {
    // Si l'age ancien est identique au nouveau, pas de changement à faire
    // if (this.Model.ageRange.isSameAs(ageRange)) return;

    this.Model.stagesCalledByButton = false;
    this.Model.ageRange = ageRange;
    this.Stream.filteredCourses$.next('filterAge');
    CoursFiltersLocalStorage.saveToLocal({ageRange: this.Model.ageRange});
  }

  static onPiscineDaysClick(this: CoursComponent, day: string, value: boolean): void{
    this.Model.stagesCalledByButton = false;

    this.Model.days.set(day, !value);
    CoursFiltersLocalStorage.saveToLocal({days: this.Model.days});

    this.Stream.filteredCourses$.next('filter-place');
  }

  static onPaginationClick(this: CoursComponent, cursor: number): void{
    // check cohérence via prop de l'obj Paginator
    this.paginator.cursor = cursor;

    // rechercher les stages
    this.Stream.filteredCourses$.next('paginator')
  }
}
