<section class="shadow-sm p-3 mb-3 bg-white rounded mx-3">
  <span class="field2-section d-block mb-3">Age</span>

  <!-- Slider -->
  <div class="text-center" [formGroup]="ageForm">
    <div class="age-selector d-flex justify-content-between align-items-center">

      <span class="badge badge-secondary age-min">
        <input type="number" class="inline-input" formControlName="minAge"
               [value]="ageRange.min"
               (focus)="moveCursorToEnd('minAge')"
               (input)="onMinBadgeChange($event)"
               (blur)="onMinBadgeChange($event.target)"/>
      </span>

      <mat-slider class="ds-slider text-center" [min]="ageRange.minLimit" [max]="ageRange.maxLimit" step="1" (input)="onSliderChange($event)" style="width: 80%;">
        <input [value]="ageRange.min" class="matSliderStartThumb" #matSliderStartThumb matSliderStartThumb>
        <input [value]="ageRange.max" class="matSliderEndThumb" #matSliderEndThumb matSliderEndThumb>
      </mat-slider>

      <span class="badge badge-secondary age-min">
        <input type="number" class="inline-input" formControlName="maxAge"
               [value]="ageRange.max"
               (focus)="moveCursorToEnd('maxAge')"
               (input)="onMaxBadgeChange($event)"
               (blur)="onMaxBadgeChange($event.target)"/>
        <span *ngIf="ageRange.max >= 16" style="margin-right: 4px;">+ </span>
      </span>
    </div>
  </div>

</section>
