<nav aria-label="Page navigation example">
  <ul class="pagination flex-row justify-content-center">
    <!-- Previous Button -->
    <li class="page-item" [ngClass]="{'disabled': paginator.cursor === 0}">
      <button class="page-link" aria-label="Previous" (click)="onClick.emit(paginator.cursor - 1)">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </button>
    </li>

    <!-- First Page -->
    <li class="page-item" [ngClass]="{active: paginator.cursor === 0}">
      <button class="page-link" (click)="onClick.emit(0)">1</button>
    </li>

    <!-- Left Ellipsis -->
    <li class="page-item" *ngIf="startPage > 1">
      <span class="page-link">...</span>
    </li>

    <!-- Dynamic Page Range -->
    <li
      class="page-item"
      *ngFor="let page of pagesInRange"
      [ngClass]="{active: paginator.cursor === page}"
    >
      <button class="page-link" (click)="onClick.emit(page)">{{ page + 1 }}</button>
    </li>

    <!-- Right Ellipsis -->
    <li class="page-item" *ngIf="endPage < paginator.maxPages - 2">
      <span class="page-link">...</span>
    </li>

    <!-- Last Page -->
    <li class="page-item" *ngIf="paginator.maxPages > 1" [ngClass]="{active: paginator.cursor === paginator.maxPages - 1}">
      <button class="page-link" (click)="onClick.emit(paginator.maxPages - 1)">
        {{ paginator.maxPages }}
      </button>
    </li>

    <!-- Next Button -->
    <li class="page-item" [ngClass]="{'disabled': paginator.cursor === paginator.maxPages - 1}">
      <button class="page-link" aria-label="Next" (click)="onClick.emit(paginator.cursor + 1)">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </button>
    </li>
  </ul>
</nav>
