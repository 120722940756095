import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../../../../../service/data.service";
import he from 'he';
import {InscriptionCours} from "../../../../../models/inscriptionCours";
import {Member} from "../../../../../models/Member";
import {CourseDetails} from "../../../../../models/CourseDetails";
import {Account} from "../../../../../models/Account";
import {FooterService} from "../../../../common/footer/footer.service";
import {LoadingStatus} from "../../../../../models/FormLoadingStatus";
import {CoursRecapLoadingStatus} from "./CoursRecapLoadingStatus";

@Component({
  selector: 'app-recapitulatif-cours',
  templateUrl: './recapitulatif-cours.component.html',
  styleUrls: ['./recapitulatif-cours.component.css']
})
export class RecapitulatifCoursComponent implements OnInit {

  protected selectedCours: CourseDetails = JSON.parse(sessionStorage.getItem('selectedCours') || '{}');
  protected selectedMember: Member= JSON.parse(sessionStorage.getItem('selectedMember') || '{}');
  protected inscriptionInfos: InscriptionCours = JSON.parse(sessionStorage.getItem('inscriptionCours') || '{}');
  protected connectedAccompt: Account = JSON.parse(localStorage.getItem('selectedCompte') || '{}');

  protected priceWanted: number = -1;
  protected priceAfterAcompt?: number;

  protected coursName!: string;
  protected reduction: number | null = null;
  protected isThereAPromo: boolean = false;
  protected isReservation: boolean = this.selectedCours.remainingSlots <= 0 || this.selectedCours.isFillForced;

  protected coursOriginId: number = -1;
  protected coursOrigin: CourseDetails | undefined;
  protected coursOriginPersonPrenom: string | undefined;
  protected coursOriginPersonNom: string | undefined;

  protected loadingStatus = new CoursRecapLoadingStatus();


  constructor(private router: Router, private dataService: DataService, private footerService: FooterService) {
  }

  async ngOnInit() {
    if(Object.keys(this.selectedCours).length == 0) {
      // console.log('redirecting...')
      this.router.navigateByUrl("/");
    } else {
      this.footerService.setFooterVisibility(true);
      this.priceWanted = this.getWantedPrice();
      // console.log('Date start : ' + this.selectedStage.dateStart);
      this.coursName = he.decode(this.selectedCours.name);

      this.isThereAPromo = await this.promotionHandler();

      if (this.isThereAPromo) {
        // console.log('There\'s a promotion !');
        await this.getCoursData();
      }else{
        this.loadingStatus.gettingCourseData = LoadingStatus.LOADED;
        this.inscriptionInfos.reduction = 0;
      }

      sessionStorage.setItem('inscriptionCours', JSON.stringify(this.inscriptionInfos));
      sessionStorage.setItem('reservationCours', JSON.stringify(this.isReservation));
      this.priceAfterAcompt = this.priceWanted - 30;

      // console.log('RecapitulatifComponent - ngOnInit() : isThereAPromo : ' + this.isThereAPromo);
    }
  }

  private async promotionHandler(): Promise<boolean> {
    // console.log('RecapitulatifComponent - promotionHandler() : dateStart : ' + this.selectedCours.dateStart1);
    // console.log('RecapitulatifComponent - promotionHandler() : dateEnd : ' + this.selectedCours.dateEnd);

    return new Promise<boolean>((resolve) => {
      this.dataService.PromotionAmountCours(
        this.connectedAccompt.numero_compte,
        this.inscriptionInfos.periode_annee,
        new Date(this.selectedCours.dateStart1),
        new Date(this.selectedCours.dateEnd)
      ).subscribe({
        next: (response: CoursPromotionResponse) => {
          if (response && response.reduction) {
            // Promotion available
            // console.log('Promotion available');
            if (response.prix_plus_bas !== undefined && response.montant_reduction !== undefined && response.montant_reduction > 0) {
              // console.log('Lowest price: ', response.prix_plus_bas);
              // console.log('Reduction amount: ', response.montant_reduction);
              this.reduction = response.montant_reduction;
              this.priceWanted -= this.reduction;
              this.inscriptionInfos.reduction = this.reduction;
              this.inscriptionInfos.priceAfterReduc = this.priceWanted;
              this.coursOriginId = response.cours_correspondant;
              this.coursOriginPersonPrenom = response.personne_prenom;
              this.coursOriginPersonNom = response.personne_nom;
            }
            // Here you can perform actions based on the promotion availability
            this.loadingStatus.promotionHandling = LoadingStatus.LOADED;
            resolve(true);
          } else {
            // No promotion available or error
            // console.log('No promotion available or error');
            this.loadingStatus.promotionHandling = LoadingStatus.LOADED;
            resolve(false);
          }
        },
        error: (error) => {
          // Error occurred
          this.loadingStatus.promotionHandling = LoadingStatus.ERROR;
          // console.error('Error fetching promotion data:', error);
          resolve(false);
        }
      });
    });
  }



  getWantedPrice(): number {
    let finalprice: number;
    if (this.inscriptionInfos.periode_annee == 'Premier_semestre' || this.inscriptionInfos.periode_annee == 'Second_semestre') {
      finalprice = this.selectedCours.semesterPrice;
    } else if (this.inscriptionInfos.periode_annee == 'Annee') {
      finalprice = this.selectedCours.price;
    } else {
      return -1;
    }
    return finalprice;
  }

  async handleForm() {
    // TODO Soulever les erreurs en cas de requette ratée
    try {
      await this.addInscriptionToBD();
      this.router.navigateByUrl("/inscription.cours.inscription-confirmee");
    } catch (error) {
      console.error(error);
      // Handle the error accordingly
    }
  }

  async addInscriptionToBD(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let idCours: number = this.selectedCours.id;
      let idPersonne: number = this.selectedMember.numero_personne;
      let periodOfYear: string = this.inscriptionInfos.periode_annee;
      let prix: number = this.selectedCours.price;
      let montantReduction: number | undefined = this.inscriptionInfos.reduction;
      let tailleTshirt: string | undefined = this.inscriptionInfos.tailleTshirt;
      let commentaires: string = this.inscriptionInfos.commentaire_copain;
      // console.log('request start');

      this.dataService.addInscriptionCours(this.isReservation ,idCours, idPersonne, periodOfYear, prix, montantReduction, commentaires).subscribe({
        next: () => {
          // Handle the success data if needed
          resolve();
        },
        error: (err) => {
          // Handle the error here
          reject(err);
        },
        complete: () => {

        }
      });
    });
  }
  getCoursData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getCoursDetails(this.coursOriginId).subscribe({
        next: (res:any) => {
          // console.log(res)
          this.coursOrigin = new CourseDetails(
            this.coursOriginId,
            he.decode(res.NOM),
            res.DESCRIPTIF,
            res.JOUR,
            res.HEURE,
            new Date (res.DATE_DEBUT), // Date debut1
            new Date (res.DATE_DEBUT), // Date debut2
            new Date (res.DATE_FIN),
            res.AGE_MIN,
            res.AGE_MAX,
            res.PRIX,
            res.PRIX_AU_SEMESTRE,
            res.REMAINING_SLOTS,
            res.NOM_LIEU,
            res.RUE,
            res.NUMERO,
            res.CODE_POSTAL,
            res.LOCALITE,
            res.GOOGLE,
            res.FORCER_COMPLET === "1",
            res.FORCER_COMPLET_SEMESTRE1 === "1",
            res.FORCER_COMPLET_SEMESTRE2 === "1",
            res.FORCER_COMPLET_TRIMESTRE === "1",
            res.FORCER_COMPLET_MOIS === "1"
          )
          this.loadingStatus.gettingCourseData = LoadingStatus.LOADED;
          resolve();
        },
        error: (error) => {
          this.loadingStatus.gettingCourseData = LoadingStatus.ERROR;
          reject(error);
        }
      });
    });
  }


  getButtonMessage() {
    if (this.isReservation) {
      return "Finaliser l'inscription en reserve";
    } else {
      return "Finaliser l'inscription";
    }
  }

  isPageLoaded() {
    return this.loadingStatus.gettingCourseData !== LoadingStatus.LOADING && this.loadingStatus.promotionHandling !== LoadingStatus.LOADING;
  }
}

interface CoursPromotionResponse {
  personne_prenom: string;
  personne_nom: string;
  reduction: boolean;
  prix_plus_bas?: number; // Optional property
  montant_reduction?: number; // Optional property
  cours_correspondant: number; // Optional property
}
