<app-return-link
  [route]="'/home'"
  [text]="'Accueil'">
</app-return-link>
<h1 class="text-center mt-3">Compte famille DS</h1>

<section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">

  <h2 class="activation-reussie fw-bold green" *ngIf="activated">Votre compte a été activé avec succès ! </h2>

  <h2 class="field2-section d-block mb-2">Confirmation de votre email</h2>
  <p>Nous vous avons envoyé un email de confirmation à l'adresse <!--<a [href]="login">{{login}}</a>--><span style="color: #2EBBFF; text-decoration: underline;">{{login}}</span>, veuillez consulter vos mails et cliquer sur le lien.</p>

<!--  <section class="email-envoi">-->
<!--    <h3 class="fw-bold blue">Email envoyé à : </h3>-->
<!--    <section>-->
<!--      <p>{{this.login}}</p>-->
<!--    </section>-->
<!--  </section>-->

<!--  <section class="activation-token">-->
<!--    <h3 class="fw-bold blue">Clé d'activation : </h3>-->
<!--    <section>-->
<!--      <p>{{this.activation}}</p>-->
<!--    </section>-->
<!--  </section>-->

  <button *ngIf="activated" class="text-center button button-medium-size blue-button text-decoration-none my-4 d-flex flex-column" (click)="goBackHome()">Retour</button>

</section>




