<!--bouton retour-->
<app-return-link
  [text]="'Cours'"
  [route]="'/cours'" xmlns="http://www.w3.org/1999/html">
</app-return-link>

<span *ngIf="!initialized" class="ds-message m-4">
      <div class="spinner-border spinner-border-sm" role="status">
      </div> Chargement ...
</span>

<main *ngIf="initialized">

  <h1 class="text-center fw-bold mt-3" [innerHTML]="cours.name"></h1>

  <div class="d-flex align-items-center">
    <!-- Message affiché lorsqu'un cours est complet -->
    <ng-container *ngIf="isFilled()">
      <!--    <ng-container *ngIf="true">-->
      <div style="width: 1.25rem; height: 1.25rem; background-color: red; border-radius: 50%; margin-right: 0.3125rem; margin-bottom: 0.5625rem;"></div>
      <label style="color: red;">Complet (Inscription en liste d'attente)</label>
    </ng-container>
    <!-- Message affiché lorsqu'un cours est presque complet -->
    <ng-container *ngIf="isNearlyFilled()">
      <div style="width: 1.25rem; height: 1.25rem; background-color: orange; border-radius: 50%; margin-right: 0.3125rem; margin-bottom: 0.5625rem;"></div>
      <label style="color: orange;">Plus que quelques places</label>
    </ng-container>
  </div>

  <!-- espace-->
  <hr class="section-separator">

  <!--  descriptif-->
  <button class="btn btn-light descriptif mx-3 text-left shadow-sm mb-2" (click)="onReadMore()">
    <label class="fw-bold field my-2 mx-1">Descriptif</label>
    <p [ngClass]="{'resume':!readMore}" [innerHTML]="cours.description"></p>
    <p *ngIf="readMore"><strong>(appuyer pour voir moins)</strong></p>
    <p *ngIf="!readMore"><strong>(appuyer pour voir plus)</strong></p>
  </button>

  <!--date-->
  <div class="form-group mx-3 h-100">
    <label class="fw-bold field my-2 mo-1 me-3">Date</label>
    <p class="form-control p-2 text-left">Le <strong>{{cours.day}}</strong> à <strong>{{cours.hour}}</strong> *<br>
      du <strong>{{cours.dateStart1 | date: 'dd-MM-yyyy'}}</strong> au <strong>{{cours.dateEnd | date: 'dd-MM-yyyy'}}</strong><br>
      <br>
      <i>* Hors jours fériés et congés scolaires.</i>
    </p>
  </div>

  <!--age-->
  <div class="form-group mx-3 d-flex align-items-center">
    <label class="fw-bold field my-2 mo-1 me-3">Age</label>
    <span class="form-control mb-0 p-2 text-center"><strong>{{cours.ageStart}}</strong> à <strong>{{cours.ageEnd}}</strong> ans</span>
  </div>

  <!--prix-->
  <div class="form-group mx-3 d-flex align-items-center">
    <label class="fw-bold field my-2 mo-1 me-3 text-nowrap">Prix annuel</label>
    <span class="form-control mb-0 p-2 text-center"><strong>{{cours.price}}€</strong></span>
  </div>

  <!--prix-->
  <div class="form-group mx-3 d-flex align-items-center">
    <label class="fw-bold field my-2 mo-1 me-3 text-nowrap">Prix semestriel</label>
    <span class="form-control mb-0 p-2 text-center"><strong>{{cours.semesterPrice}}€</strong></span>
  </div>

  <!--lieux-->
  <div class="form-group mx-3 d-flex align-items-center">
<!--    <label class="fw-bold field my-2 mo-1 me-3 text-nowrap align-self-start">Lieu</label>-->
<!--    <i class="fa-solid fa-map-location-dot" >_CARTE_</i>-->
    <label class="fw-bold field my-2 mo-1 me-3 text-nowrap">Adresse : </label>

    <a class="grey_text form-control mb-0 p-2 text-center"
       href="https://maps.google.com/maps?q={{encodeURIComponent(cours.rue + ', ' + cours.numero + ', ' + cours.codePostal + ', ' + cours.localite)}}">
      {{cours.rue}}, {{cours.numero}} - {{cours.codePostal}} {{cours.localite}} - Belgique</a> <!--Pas le choix de hardcoder le pays, il n'est pas stocké en base de donnée (table LIEU)-->
  </div>

  <!-- s'inscrire au stage / FLOATING BUTTON-->
  <br class="mx-2">
  <a class="text-decoration-none shadow-lg"><app-floating-button
    [text]="'Je m\'inscris'"
    (onClick)="OnRegister()">
  </app-floating-button></a>
  <br class="mx-2">

  <div id="cours-detail-floating"></div>
</main>
