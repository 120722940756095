import {Component, Input} from '@angular/core';
import {Member} from "../../../models/Member";

@Component({
    selector: 'app-personal-info-card',
    templateUrl: './personal-info-card.component.html',
    styleUrls: ['./personal-info-card.component.css', '../../common/styles/common.css']
})
export class PersonalInfoCardComponent {
    isMenuOpen: boolean = false;
    @Input() member!: Member;
    protected connectedAccount = JSON.parse(localStorage.getItem('selectedCompte') || '{}');

    surname?: string;
    firstname?: string;
    birthDate?: Date;
    genre?: string;
    nationalNumber?: string;
    phoneNumber?: string;
    email?: string;
    adress?: string;

    ngOnInit() {
        this.surname = this.member.nom;
        this.firstname = this.member.prenom;
        this.birthDate = this.member.date_naissance
        this.genre = this.member.sexe;
        this.nationalNumber = this.member.numero_national;
        this.phoneNumber = this.member.gsm;
        this.email = this.member.email;
        this.adress = `${this.connectedAccount.rue}  ${this.connectedAccount.numero}, ${this.connectedAccount.code_postal}  ${this.connectedAccount.localite} ` + this.connectedAccount.pays.toUpperCase();
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }
}
