import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import {DataService} from "../../../service/data.service";
import {KeyValue} from "@angular/common";

// import variables globaux par défaut
import {CoursStream} from "./CoursStream";
import {CoursModel} from "./CoursModel";
import {CoursEvent} from "./CoursEvent";
import {Subject} from "rxjs";
import {Paginator} from "../../../models/Paginator";
import {CoursStatusProvider} from "./CoursStatusProvider";
import {CoursLoadingStatus} from "./CoursLoadingStatus";
import {CoursBasic} from "./interfaces";
import {REQUEST_DELAY} from "./CoursGlobalValues";
import {CoursObserverUtil} from "./CoursObserverUtil";
import {LoadingStatus} from "../../../models/FormLoadingStatus";
import {CoursFiltersLocalStorage} from "./CoursFiltersLocalStorage";

const {LOADING, LOADED, TIME_OUT, NET_ERROR, ERROR} = LoadingStatus;

declare var $: any;

@Component({
  selector: 'app-cours',
  templateUrl: './cours.component.html',
  styleUrls: ['./cours.component.css', '../../common/styles/common.css']
})
export class CoursComponent implements OnInit, AfterViewInit, CoursBasic {
  // données
  protected Model: CoursModel = new CoursModel();
  protected Stream: CoursStream = new CoursStream();
  loadingStatus = new CoursLoadingStatus();
  coursStatusProvider: CoursStatusProvider = new CoursStatusProvider({
    model: this.Model,
    loadingStatus: this.loadingStatus
  });
  protected paginator: Paginator = new Paginator(8, 0);

  // Autres variables
  protected resetFilters$: Subject<void> = new Subject<void>();
  protected multiselectUpdate$: Subject<Map<string, boolean>> = new Subject();

  protected multiselectInilialiser: { id: string, options?: object } = { // données d'initialisation du composant multiselect
    id: "cours-place-multiselect",
    options: {
      includeSelectAllOption: true,
      enableFiltering: true,
      enableCaseInsensitiveFiltering: true,
    }
  };

// _____________________________________________________________________________________________________________________
// INIT + ACCESSOR -----------------------------------------------------------------------------------------------------
  constructor(public dataService: DataService, public zone: NgZone) {
  }

  ngOnInit() {
    this.loadTypeCours(); // calcule les périodes à afficher TODO
    CoursObserverUtil.initPlacesObserver.call(this); // observe les filtres pour charger les semaines
    CoursObserverUtil.initCoursesObserver.call(this); // observe les filtres pour charger les stages
  }

  ngAfterViewInit() {
    // permet de scroll vers le début de la page au démarage.
    setTimeout(() => $('body')[0].scrollIntoView({ behavior: 'auto', block: 'start' }), 500)
  }

  updatePlacesMultiselect() {
    this.zone.run(() => {
      this.multiselectUpdate$.next(this.Model.places)
    });
  }

  //Permet de spécifier un ordre des jours de la semaine car le pipe keyvalue dans le ngFor trie auto
  //les jours dans l'ordre alphabétique et non dans leur ordre naturel dans la map
  daysOriginalOrder = (a: KeyValue<string,boolean>, b: KeyValue<string,boolean>): number => {
    return 0;
  }

  getCourseTypes() {
    return ["Piscine", "Escalade", "Cours terrestre"];
  }

  clearCourses() {
    this.zone.run(() => {
      this.Model.filteredCourses = [];
    });
  }

  loadTypeCours() {
    let map = new Map<string, boolean>();
    this.getCourseTypes().forEach(v => map.set(v, false));
    this.Model.courseTypes = map;
    CoursFiltersLocalStorage.loadLocalFilters.call(this).then(
      () => this.Stream.places$.next('start-event')
    );
  }

  async fetchPlaces() {
    await new Promise<void>((resolve, reject) => {
        this.loadingStatus.places = LOADING;

        this.dataService.getCourseTypePlaces(this.Model.selectedType)
          .subscribe(
            CoursObserverUtil.getSubscribePlaces.call(this, this.Model.selectedType, resolve, reject));
      })
        .then(() => {
          // 1. cloturer état chargement
          this.loadingStatus.places = LOADED;
        })
        .catch(error => {
          this.loadingStatus.places = ERROR;
          if (error.name === 'TimeoutError') {
            this.loadingStatus.places = TIME_OUT;
          }
          if (error instanceof TypeError) {
            this.loadingStatus.places = NET_ERROR;
          }
        });
  }
  loadDays(periods: string[]): Promise<void> {
    throw new Error('Method not implemented.');
  }

  private getCourseTypePlaces(type: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getCourseTypePlaces(type).subscribe({
        next: (res) => {
          if (Array.isArray(res) && res.length > 0) {
            const places: string[] = Array.from(new Set(res.map(place => place.toString())));
            this.Model.courseTypePlaces.set(type, places);

            // Ajouter les valeurs distinctes à la Map Places
            places.forEach(place => {
              if (!this.Model.places.has(place)) {
                this.Model.places.set(place, false);
              }
            });

            // Trier la Map Places
            this.Model.places = new Map([...this.Model.places.entries()].sort());
          }
          this.updatePlacesMultiselect();
          resolve();
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }


  protected readonly window = window;
  protected readonly CoursEvent = CoursEvent;
  protected readonly scrollToCoursClass: string = 'scroll-to-cours';
  protected readonly Array = Array;
  protected readonly REQUEST_DELAY = REQUEST_DELAY;



}
