import {Component, OnInit} from '@angular/core';
import {Member} from "../../../../models/Member";
import {DataService} from "../../../../service/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MemberContact} from "../../../../models/MemberContact";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {CustomValidators} from "../input/CustomValidators";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-confirmation-membre',
  templateUrl: './confirmation-membre.component.html',
  styleUrls: ['./confirmation-membre.component.css', '../../../common/styles/common.css']
})
export class ConfirmationMembreComponent implements OnInit {

  protected member: Member = new Member();
  protected nom: string = '';
  protected prenom: string = '';
  protected dateNaissance = '';
  protected mail: string = '';
  protected personneContact:any;
  protected localite: string = '';
  protected rue: string = '';
  protected isSuccessful: boolean = false;
  protected message: string = '';

  searchForm!:FormGroup
  protected buttonStatus : 'loading' | 'idle' = 'idle';
  protected submitted: boolean = false;
  returnName: string = 'Rechercher un membre';

  constructor(
    private dataService:DataService,
    private router:Router,
    private fb : FormBuilder,
    private toastr: ToastrService
    ) {
  }

  /*ngOnInit() {
    this.member = JSON.parse(sessionStorage.getItem("foundMember") || '{}');

    this.personneContact = this.getPersonneContact();
    this.personneContact.then((contact:MemberContact) => {
      console.log(contact);
      this.localite = contact.code_postal + ' ' + contact.localite;
      this.rue = contact.rue + ' ' + contact.numero;
      console.log(this.localite);
      console.log(this.rue);
    })

    if (this.member == null) {
      this.router.navigateByUrl("/");
    }

    this.searchForm = this.fb.group({
      nom: new FormControl({value: null, disabled: true},
        [CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]),
      prenom: new FormControl({value: null, disabled: true}, [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]),
      dateNaissance: new FormControl({value: null, disabled: true}, [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]),
      email: new FormControl({value: null, disabled: true}, [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100}),
        CustomValidators.email()]),
      localite: new FormControl({value: null, disabled: true}, [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]),
      rue: new FormControl({value: null, disabled: true}, [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]),
    })

    // -> pour remplir les champs
    this.searchForm.patchValue({
      nom: this.member.nom,
      prenom: this.member.prenom,
      dateNaissance: this.convertToRequiredFormat(this.member.date_naissance),
      email: this.member.email,
      localite: this.localite,
      rue: this.rue,
    });
  }*/

  ngOnInit() {
    this.member = JSON.parse(sessionStorage.getItem("foundMember") || '{}');

    if (this.member == null) {
      this.router.navigateByUrl("/");
      return;
    }

    this.personneContact = this.getPersonneContact();
    this.personneContact.then((contact: MemberContact) => {
      this.localite = contact.code_postal + ' ' + contact.localite;
      this.rue = contact.rue + ' ' + contact.numero;

      this.searchForm = this.fb.group({
        nom: new FormControl({value: null, disabled: true},
          [CustomValidators.required(),
            CustomValidators.maxLen({length: 100})]),
        prenom: new FormControl({value: null, disabled: true}, [
          CustomValidators.required(),
          CustomValidators.maxLen({length: 100})]),
        dateNaissance: new FormControl({value: null, disabled: true}, [
          CustomValidators.required(),
          CustomValidators.maxLen({length: 100})]),
        email: new FormControl({value: null, disabled: true}, [
          CustomValidators.required(),
          CustomValidators.maxLen({length: 100}),
          CustomValidators.email()]),
        localite: new FormControl({value: null, disabled: true}, [
          CustomValidators.required(),
          CustomValidators.maxLen({length: 100})]),
        rue: new FormControl({value: null, disabled: true}, [
          CustomValidators.required(),
          CustomValidators.maxLen({length: 100})]),
      });

      // -> pour remplir les champs
      this.searchForm.patchValue({
        nom: this.member.nom,
        prenom: this.member.prenom,
        dateNaissance: this.convertToRequiredFormat(this.member.date_naissance),
        email: this.member.email,
        localite: this.localite,
        rue: this.rue,
      });
    }).catch((error: any) => {
      console.error('Error fetching contact information:', error);
      this.router.navigateByUrl("/");
    });
  }

  convertToRequiredFormat(originalDate : any){
    const date = new Date(originalDate);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2); // Adding leading zero if needed
    const day = `0${date.getDate()}`.slice(-2); // Adding leading zero if needed

    return `${year}-${month}-${day}`;
  }

  getPersonneContact(): Promise<MemberContact> {
    return new Promise<MemberContact>((resolve, reject) => {
      this.dataService.getPersonneContact(this.member.numero_personne).subscribe({
        next: (element: any) => {
          let contact: MemberContact;
          if (element) {
            contact = new MemberContact(
              element.personne.NUMERO_PERSONNE,
              element.personne.RUE,
              element.personne.NUMERO,
              element.code_postal.CODE_POSTAL,
              element.code_postal.LOCALITE,
              element.personne.TELEPHONE,
              element.personne.FAX,
              element.personne.NEWSLETTER
              )
          } else {
            contact = new MemberContact();
          }
          resolve(contact);
        },
        error: (error) => {
          reject(error);
        },
        complete: () => {
          // You can add clean-up logic here if needed
        }
      });
    });
  }

  validateForm() {
    this.submitted = true;

    // Mark all controls as touched to trigger validation messages
    this.searchForm.markAllAsTouched();

    // Check if any of the form controls have invalid validators
    const invalidControls = Object.keys(this.searchForm.controls).filter(controlName =>
      this.searchForm.get(controlName)?.invalid
    );

    // If there are invalid controls, handle them accordingly
    if (invalidControls.length > 0) {
      // Handle the case when there are invalid controls
      console.log("Invalid validators found:", invalidControls);
      // You can show a message or perform any other action here
    } else {
      // All validators are valid, proceed with form submission
      // THIS IS NOT DOING WHAT IT'S SUPPOSED TO
      this.submitForm();
    }
  }

  async submitForm() {
    // mise à jour des états
    this.buttonStatus = "loading";

    // CHARGEMENT
    // this.loadingDialog = this.dialog.open(PopupModalComponent, {
    //   disableClose: true,
    //   panelClass: 'loading-dialog-overlay',
    //   data: {
    //     titre: 'Chargement',
    //     width: 100,
    //   },
    //
    // });

    await this.sendRequestAddToAccount();
    sessionStorage.setItem('foundMember', JSON.stringify(this.member));
    this.router.navigate(['/confirmation-membre']);
  }

  async sendRequestAddToAccount(){
    let retour = await this.addToAccount();
    if(retour == "ok"){
      this.isSuccessful = true;
      this.toastr.success("Le membre a été ajouté à votre compte avec succès.", "Ajout réussi !", {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        enableHtml: true,
        positionClass:"toast-top-full-width"
      });

      const selectedStage = sessionStorage.getItem('selectedStage');
      const selectedCours = sessionStorage.getItem('selectedCours');
/*
      if (selectedStage) {
        this.router.navigate(['/inscription.stages.selection-membre-stage']);
      } else if (selectedCours) {
        this.router.navigate(['/inscription.cours.selection-membre-cours']);
      } else {
        this.router.navigate(['/gestion-compte']);
      }*/
    } else {
      this.isSuccessful = false;
      this.toastr.error("Une erreur est survenue.", "Erreur lors de l'ajout !", {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        enableHtml: true,
        positionClass:"toast-top-full-width"
      });
    }
  }

  addToAccount(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // Récupérer le numéro de compte du compte en session storage
      let compte = JSON.parse(localStorage.getItem("selectedCompte") || '{}');
      this.dataService.linkUserToAccount(this.member.numero_personne, compte.numero_compte).subscribe({
        next: (element: any) => {
          resolve(element);
        },
        error: (error) => {
          reject(error);
        },
        complete: () => {
          // You can add clean-up logic here if needed
        }
      });
    });

  }

}
