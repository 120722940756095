import {LoadingStatus} from "../../../../../models/FormLoadingStatus";

const {
  LOADING,
  LOADED,
  TIME_OUT,
  NET_ERROR,
  ERROR
} = LoadingStatus;

export class CoursRecapLoadingStatus {
  promotionHandling: LoadingStatus = LoadingStatus.LOADING;
  gettingCourseData: LoadingStatus = LoadingStatus.LOADING;
}
