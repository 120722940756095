import {Component, HostListener} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '../../../service/auth.service';
import { Account } from "../../../models/Account";
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import { CustomValidators } from '../membre/input/CustomValidators';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.css']
})

export class ConnexionComponent {
  showPassword = false;

  redirect: boolean = false;
  errorMessage: string | null = null;
  private email!: string;
  private password!: string;
  loginForm!:FormGroup;
  protected submitted: boolean = false;
  resetPassword: boolean = false;


  constructor(private toastr: ToastrService, private formBuilder : FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.redirect = params['redirect'] === '1';
    });

    // Création du formulaire
    this.loginForm = this.formBuilder.group({
      email: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      password: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      })
    this.resetPassword = !!this.route.snapshot.queryParamMap.get('reset-password');
  }

  onLogin() {
    console.log("onLogin" + this.loginForm.get("email")!.errors);

    this.submitted = true;

    this.authService.login(this.loginForm.value).subscribe(
    response => {
      if (response.message === 'Authentification réussie') {
        /* ----------------------------------------- */
        /* ---------- CONNEXION REUSSIE ---------- */
        /* ----------------------------------------- */

        // Stockage du user en localStorage
        localStorage.setItem('selectedCompte', JSON.stringify(this.matchAccountObject(response.user)));

        if(sessionStorage.getItem('selectedStage')){

          // Si un stage a été sélectionné, rediriger vers l'inscription à ce dernier
          this.router.navigateByUrl('inscription.stages.selection-membre-stage');
        }
        else if (sessionStorage.getItem('selectedCours')){

          // Si un cours a été sélectionné, rediriger vers l'inscription à ce dernier
          this.router.navigateByUrl('inscription.cours.selection-membre-cours')
        }
        else{
          // Sinon, rediriger vers la page de gestion de compte
          this.router.navigateByUrl('gestion-compte');
        }
      } else {

        // Erreur d'authentification
        this.errorMessage = ('Erreur d\'authentification :' + response.message);
      }
    },
    error => {
      if(error['error']['message'] === 'Compte non activé'){
        this.toastr.error( "Votre compte n\'est pas encore activé. Veuillez vérifier vos emails pour activer votre compte.", "Compte non-activé", {
          timeOut: 5000,
          progressBar: true,
          closeButton: true,
          enableHtml: true,
          positionClass:"toast-top-full-width"
        });
      }
      this.errorMessage = (error['error']['message']);
    });
  }
  private matchAccountObject($userData: any): Account {
    return new Account({
      numero_compte: $userData[0]['NUMERO_COMPTE'] ?? 0,
      login: $userData[0]['LOGIN'] ?? '',
      email: $userData[0]['EMAIL'] ?? '',
      rue: $userData[0]['RUE'] ?? '',
      numero: $userData[0]['NUMERO'] ?? '',
      code_postal: $userData[0]['CODE_POSTAL'] ?? '',
      numero_cp: $userData[0]['NUMERO_CP'] ?? '',
      localite: $userData[0]['LOCALITE'] ?? '',
      pays: $userData[0]['PAYS'] ?? '',
      telephone: $userData[0]['TELEPHONE'] ?? '',
      gsm: $userData[0]['GSM'] ?? '',
      nom: $userData[0]['NOM'] ?? '',
      gsm2: $userData[0]['GSM_2'] ?? '',
      nom2: $userData[0]['NOM_2'] ?? '',
      newsletter: $userData[0]['NEWSLETTER'] ?? '0',
      prenom: $userData[0]['PRENOM'] ?? '',
      prenom2: $userData[0]['PRENOM_2'] ?? '',
      email2: $userData[0]['EMAIL_2'] ?? '',
      numeroNational: $userData[0]['NUMERO_NATIONAL'] ?? '',
    });
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  clearSessionStorage(): void {
    sessionStorage.removeItem('selectedStage');
  }

  goToInscription() {
    this.router.navigateByUrl('/creation-compte')
  }

    protected readonly localStorage = localStorage;
  protected readonly sessionStorage = sessionStorage;
}
