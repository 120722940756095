<app-return-link
  [route]="'/gestion-compte'"
  [text]="'Compte'">
</app-return-link>

<h2 class="text-center font-weight-bold mt-4">Modifier un membre</h2>

<h3 class="m-3 fw-semibold mt-4 blue">Informations personnelles</h3>

<!--FORMULAIRE-->
<form *ngIf="registerForm" novalidate class="m-3 needs-validation" [formGroup]="registerForm" (ngSubmit)="validateForm()">

  <!--CHAMPS-->
  <ng-container *ngTemplateOutlet="nom; context:
    {name : 'nom', label: 'Nom*', placeholder: 'Ex : DUPONT'}" />

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'prenom', label : 'Prénom*', placeholder: 'Ex : jacques'}" />

<!--  <ng-container *ngTemplateOutlet="field; context:-->
<!--    {name : 'dateNaissance', label: 'Date de naissance*', type: 'date'}" />-->

  <ng-container *ngTemplateOutlet="customDate; context:
  {name: 'dateNaissance', label: 'Date de naissance'}" />

  <ng-container *ngTemplateOutlet="radioList; context:
    {name : 'genre', label:'genre*', values: [{label: 'Masculin', value: 'M'}, {label: 'Féminin', value: 'F'}]}" />

  <ng-container *ngTemplateOutlet="numnat; context:
    {name : 'numNat', label: 'Numéro national*', placeholder: 'Ex : 20.01.01-123.45'}">
  </ng-container>

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'email', label: 'E-mail', placeholder: 'Adresse mail'}" />

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'gsm', label: 'GSM*', type: 'tel', placeholder: 'Ex : 0495/123456'}" />

  <ng-container *ngTemplateOutlet="textarea; context:
    {name : 'sportPratique', label: 'Sport(s) pratiqué(s)', placeholder: 'Football, Golf, ... (à compléter uniquement si information utile, laisser vide sinon)'}" />

  <ng-container *ngTemplateOutlet="textarea; context:
    {name : 'commentaire', label: 'Commentaires additionnels', placeholder: 'Suggestions, ... (à compléter uniquement si information utile, laisser vide sinon)'}" />

  <hr class="section-separator">

  <h3 class="fw-semibold mt-4 blue">Informations médicales</h3>

  <span class="fw-normal mb-1 d-flex">Médecin traitant</span>
  <div class="card p-3 form-group d-flex flex-column">
    <ng-container *ngTemplateOutlet="field; context:
      {name : 'medecinTraitant_nom', label: 'Nom du médecin*', placeholder: 'Ex : MARIE CURIE'}" />

    <ng-container *ngTemplateOutlet="field; context:
      {name : 'medecinTraitant_tel', label: 'GSM*', type: 'tel', placeholder: 'Ex : 0495/123456'}" />
  </div>

  <ng-container *ngTemplateOutlet="numfield; context:
    {name : 'anneeDernierRappelTetanos', label: 'Année du dernier rappel tétanos*', min: 1900, max: getCurrentYear(), placeholder: 'Ex : 2019'}" />

  <ng-container *ngTemplateOutlet="textarea; context:
    {name : 'allergies', label: 'Allergies', placeholder: 'Alimentaires, animaux, médicaments, ... (à compléter uniquement si information utile, laisser vide sinon)'}" />

  <ng-container *ngTemplateOutlet="textarea; context:
    {name : 'medicaments', label: 'Médicaments', placeholder: 'Médicaments à prendre (à compléter uniquement si information utile, laisser vide sinon)'}" />

  <ng-container *ngTemplateOutlet="textarea; context:
    {name : 'donnees_medicales_specifiques', label: 'Données médicales spécifiques', height: 10, placeholder: 'Problèmes cardiaques, épilepsie, asthme, diabète, mal des transports, rhumatisme, somnambulisme, affections cutanées, handicap moteur ou mental, interventions médicales, ... (à compléter uniquement si information utile, laisser vide sinon)'}" />

  <br>
  <!--BOUTON ENVOYER-->
<!--  <button>Modifier</button>-->
  <app-floating-button text="Modifier" [disabled]="!registerForm.valid || buttonStatus === 'loading'" [isGreen]="false"/>
  <br><br>
  <!--
    <hr>

    <p>Dirty : {{registerForm.dirty}}</p>
    <p>Valid : {{registerForm.valid}}</p>
    <pre>Value : {{registerForm.value | json}}</pre>
  -->

  <!--|=========|-->
  <!--|TEMPLATES|-->
  <!--|=========|-->

  <!--CHAMPS-->
  <ng-template #field let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
    <label [for]="name" class="fw-normal">{{label??name}}</label>

    <input [type]="type" [id]="name" [formName]="name"
           [formControlName]="name" [appFieldError]="registerForm.get(name)!"
           [placeholder]="placeholder??''" >
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

  <ng-template #numnat let-name="name" let-type="type" let-label="label" let-placeholder="placeholder">
    <div class="form-group">
      <label [for]="name" class="fw-normal">{{label ?? name}}</label>

      <!-- Removed [value], simply using formControlName -->
      <input [type]="'text'" [id]="name" formControlName="{{name}}" [appFieldError]="registerForm.get(name)!" [placeholder]="placeholder ?? ''">
      <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted" />
    </div>
  </ng-template>

  <ng-template #nom let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
    <label [for]="name" class="fw-normal">{{label??name}}</label>

    <input [type]="'text'" [id]="name" [formName]="name"
           [formControlName]="name" [appFieldError]="registerForm.get(name)!"
           [placeholder]="placeholder??''" [value]="registerForm.get(name)?.value | uppercase">
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

  <!--TEXTAREA-->
  <ng-template #textarea let-name="name" let-label="label" let-placeholder="placeholder" let-height="height"><div class="form-group">
    <label [for]="name" class="fw-normal">{{label??name}}</label>
    <textarea [placeholder]="placeholder??''" [id]="name" [formName]="name"
              [formControlName]="name" [appFieldError]="registerForm.get(name)!"
              [style]="{height: (height??6)+'em'}">
    </textarea>
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

  <!--RADIOLIST-->
  <ng-template #radioList let-name="name" let-label="label" let-values="values"><div class="form-group">
    <span class="fw-normal mb-1 d-flex">{{label??name}}</span>
    <div class="card p-3 form-group flex-row justify-content-evenly">
      <div *ngFor="let v of values" class="form-check d-flex mb-0">
        <input  type="radio" [value]="v.value" [id]="name+'_'+v.value"
                [formControlName]="name" [formName]="name" [appFieldError]="registerForm.get(name)!" >
        <label [for]="name+'_'+v.value" class="form-check-label" [title]="v.label">{{v.label}}</label>
      </div>
    </div>
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

  <!--NUMBER-FIELD-->
  <ng-template #numfield let-name="name" let-min="min" let-max="max" let-label="label" let-placeholder="placeholder"><div class="form-group">
    <label [for]="name" class="fw-normal">{{label??name}}</label>

    <input type="number" [min]="min??0" [max]="max??-1" [id]="name"
           [formControlName]="name" [formName]="name" [appFieldError]="registerForm.get(name)!"
           [placeholder]="placeholder??''">
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

  <!-- Template pour le champ de date personnalisé -->
  <ng-template #customDate let-name="name" let-label="label" let-placeholder="placeholder">
    <label [for]="name" class="fw-normal">{{label??"Date de naissance"}}</label>
    <div class="form-group" [formGroupName]="name">

      <div class="d-flex justify-content-between">
        <!-- JOUR -->
        <input type="text" [id]="name + '_day'" formControlName="day"
               [min]="1" [max]="31" class="form-control me-2" placeholder="Jour">

        <!-- MOIS -->
        <select [id]="name + '_month'" formControlName="month" class="form-control me-2">
          <option value="" disabled selected>Mois</option>
          <option value="1">Janvier</option>
          <option value="2">Février</option>
          <option value="3">Mars</option>
          <option value="4">Avril</option>
          <option value="5">Mai</option>
          <option value="6">Juin</option>
          <option value="7">Juillet</option>
          <option value="8">Août</option>
          <option value="9">Septembre</option>
          <option value="10">Octobre</option>
          <option value="11">Novembre</option>
          <option value="12">Décembre</option>
        </select>

        <!-- ANNÉE -->
        <input type="number" [id]="name + '_year'" formControlName="year"
               [min]="1900" [max]="getCurrentYear()" class="form-control" placeholder="Année">
      </div>
      <app-error-message-for-field *ngIf="registerForm" [control]="registerForm.get(name)!" [submitted]="submitted"></app-error-message-for-field>

<!--      <app-error-message-for-field *ngIf="registerForm" [control]="registerForm.get(name + '_day')!" [submitted]="submitted"></app-error-message-for-field>-->
<!--      <app-error-message-for-field *ngIf="registerForm" [control]="registerForm.get(name + '_month')!" [submitted]="submitted"></app-error-message-for-field>-->
<!--      <app-error-message-for-field *ngIf="registerForm" [control]="registerForm.get(name + '_year')!" [submitted]="submitted"></app-error-message-for-field>-->
    </div>
  </ng-template>

</form>

