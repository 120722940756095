import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-facturation',
  templateUrl: './facturation.component.html',
  styleUrls: ['./facturation.component.css', '../../common/styles/common.css']
})
export class FacturationComponent {
  @Input() values!: Map<number, string>;
  @Input() isStage!: boolean;
  @Input() selectedOption!: number; // To store the selected option
  @Input() isRequired?: boolean = false;
  isSelectOpen: boolean = false;
  isDisplayed: boolean = false;
  options: string[] = [];
  height!: string;
  defaultMessage: string = this.isRequired ? 'Veuillez choisir l\'institution concernée*' : 'Veuillez choisir l\'institution concernée';
  @Output() onElemClick = new EventEmitter<string>()

  ngOnInit() {
    if (this.selectedOption != -1) {
      this.isDisplayed = true;
    }
    this.values.forEach((v) => {
      this.options.push(v);
    })
    const selectElement = document.getElementById('facturationMenu') as HTMLSelectElement | null;
    if (selectElement)
    this.height = selectElement.style.height;
    // console.log('Facture - NgOnInit : this.isDiplayed : ' + this.isDisplayed);
  }

  toggleSelect() {
    this.isSelectOpen = !this.isSelectOpen;
    const selectElement = document.getElementById('facturationMenu') as HTMLSelectElement | null;
    if (selectElement) {
      if (this.isSelectOpen) {
        selectElement.style.height = `${this.options.length * 5.3}em`;
      } else {
        selectElement.style.height = this.height;
      }
    }
  }

  selectOption(option: string) {
    this.selectedOption = this.getKeyByValue(option) || -1;
    // console.log(this.selectedOption);
    this.isSelectOpen = false;
  }

  getKeyByValue(value: string): number | undefined {
    for (const [key, val] of this.values) {
      if (val === value) {
        return key;
      }
    }
    return undefined; // Return undefined if value is not found
  }

  callBoth(option: string) {
    this.selectOption(option);
    this.toggleSelect()
    this.sendDataToParent()
  }

  checkBox(){
    this.isDisplayed = !this.isDisplayed;
    // console.log('Facture : this.isDiplayed : ' + this.isDisplayed);
    if (this.isDisplayed) {
      // console.log('Facture : sentValue : ' + this.values.get(this.selectedOption));
      this.onElemClick.emit(this.values.get(this.selectedOption));
    }
    else {
      // console.log('Facture : sentValue : Pas de facture');
      // console.log('Facture : selectedOption : ' + this.selectedOption);
      this.onElemClick.emit("Pas de facture");
    }
  }

  sendDataToParent() {
    //   console.log('Facture : sentValue : ' + this.values.get(this.selectedOption));
      this.onElemClick.emit(this.values.get(this.selectedOption));
  }

}
