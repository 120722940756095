import {Component, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'app-error-message-for-field',
  template: `
    <div *ngIf="isErrorField(control)" class="invalid-feedback d-block">
      <span *ngFor="let error of Object.values(control.errors!)">
        - {{ error.message}} <br>
      </span>
    </div>
  `,
  styleUrls: ['./error-message-for-field.component.css']
})
export class ErrorMessageForFieldComponent {
  @Input() control!: AbstractControl;
  @Input() submitted: boolean = true;

  isErrorField(control: AbstractControl | null): boolean {
    return control ? (control.touched || this.submitted) && control.invalid : false;
  }

  protected readonly Object = Object;
}
