import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})

export class PaginationComponent {
  @Input() paginator!: { cursor: number; maxPages: number };
  @Output() onClick = new EventEmitter<number>();

  get pagesInRange(): number[] {
    const { cursor, maxPages } = this.paginator;

    const startPage = Math.max(1, cursor - range);
    const endPage = Math.min(maxPages - 2, cursor + range);

    const pages: number[] = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  }

  get startPage(): number {
    return this.paginator.cursor - range;
  }

  get endPage(): number {
    return this.paginator.cursor + range;
  }

}

const range = 1;
