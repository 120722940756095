import {Component, Input, OnInit, ElementRef} from '@angular/core';
import 'src/app/components/common/multi-select/js/bootstrap.min.js';
import 'src/app/components/common/multi-select/js/jquery.min.js';
import 'src/app/components/common/multi-select/js/popper.js';
import 'src/app/components/common/multi-select/js/main.js';
import 'src/app/components/common/multi-select/js/bootstrap-multiselect.js';

declare var $: any;
@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css', './multi-select.css']
})
export class MultiSelectComponent implements OnInit {
  @Input()
  set Values(values: string[]) {
    this.values = values;
    this.toggleVisibility();
  }
  protected values: string[] = [];
  private _visibility: boolean = true;
  @Input() Enables: boolean[] = [];
  @Input() Id: string = "multiple-checkboxes";
  @Input() set Visibility(value: boolean) {
    this._visibility = value
    this.toggleVisibility();
  }

  constructor(private elementRef: ElementRef) { }


  ngOnInit(): void {
    this.toggleVisibility();
    this.elementRef.nativeElement.style.display = 'none'
  }


  onSubmit(form: { value: any; }) {
    // console.log(form.value);
  }

  title = 'My Angular Project!';
  onClickSubmit(data:any) {
    alert("Entered Email id : " + data.emailid);
  }

  private toggleVisibility(): void {
    const element = this.elementRef.nativeElement;
    if (this.values && this._visibility) {
      element.style.display = 'block';
    } else {
      element.style.display = 'none';
    }
  }
}
