export class AgeRange {
  private _min: number;
  private _max: number;
  private readonly _minLimit: number;
  private readonly _maxLimit: number;

  get min() {return this._min}
  get max() {return this._max}
  get minLimit() {return this._minLimit}
  get maxLimit() {return this._maxLimit}

  constructor(min: number, max: number, minLimit: number, maxLimit: number) {
      this._minLimit = minLimit;
      this._maxLimit = maxLimit;
      this._min = Math.max(Math.min(min, maxLimit), minLimit);
      this._max = Math.max(Math.min(max, maxLimit), minLimit);
  }

  resetRange() {
    this._min = this._minLimit;
    this._max = this._maxLimit;
  }

  update(min: number, max: number) {
    this._min = Math.max(Math.min(min, this._maxLimit), this.minLimit);
    this._max = Math.max(Math.min(max, this._maxLimit), this.minLimit);
  }

  isSameAs(ageRange: AgeRange) {
    return ageRange._min === this._min && ageRange._max === this._max;
  }

  getAnonymeObject() {
    return {min: this._min, max: this._max};
  }

  toArray() {
    return [this._min, this.max];
  }
}
