// app-error-message.directive.ts
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appFieldErrorMessage]'
})
export class FieldErrorMessage {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set appFieldErrorMessage(control: AbstractControl | null) {
    if (control && control.invalid && control.touched) {
      this.viewContainer.clear();
      const errorDiv = document.createElement('div');
      errorDiv.className = 'invalid-feedback d-block';

      Object.values(control.errors!).forEach((error: any) => {
        const errorSpan = document.createElement('span');
        errorSpan.innerText = error.message;
        errorDiv.appendChild(errorSpan);
      });

      this.viewContainer.element.nativeElement.appendChild(errorDiv);
    } else {
      this.viewContainer.clear();
    }
  }
}
