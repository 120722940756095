<!--bouton retour-->
<app-return-link
[text]="'Stages'"
[route]="'/stages'"
>
</app-return-link>

<span *ngIf="!initialized" class="ds-message m-4">
      <div class="spinner-border spinner-border-sm" role="status">
      </div> Chargement ...
</span>

<main *ngIf="initialized">

  <h1 class="text-center fw-bold mt-3" [innerHTML]="stage.name"></h1>

  <!-- espace-->
  <hr class="section-separator">
  <div class="d-flex align-items-center">
    <!-- Message affiché lorsqu'un stage est complet -->
    <ng-container *ngIf="stage.remainingSlots <= 0;">
      <div style="width: 1.25rem; height: 1.25rem; background-color: red; border-radius: 50%; margin-right: 0.3125rem; margin-bottom: 0.5625rem;"></div>
      <label style="color: red;">Complet (Inscription en liste d'attente)</label>
    </ng-container>
    <!-- Message affiché lorsqu'un stage est presque complet -->
    <ng-container *ngIf="stage.remainingSlots <= 3 && stage.remainingSlots > 0">
      <div style="width: 1.25rem; height: 1.25rem; background-color: orange; border-radius: 50%; margin-right: 0.3125rem; margin-bottom: 0.5625rem;"></div>
      <label style="color: orange;">Plus que quelques places</label>
    </ng-container>
  </div>

  <!--  descriptif-->
  <button class="btn btn-light descriptif mx-3 text-left shadow-sm mb-2" (click)="onReadMore()">
    <label class="fw-bold field my-2 mx-1">Descriptif</label>
    <p [ngClass]="{'resume':!readMore}" [innerHTML]="stage.description"></p>
    <p *ngIf="!readMore"><strong>(appuyer pour voir plus)</strong></p>
    <br *ngIf="readMore">
    <p *ngIf="readMore"><strong>(appuyer pour voir moins)</strong></p>
  </button>

  <!--date-->
  <div class="form-group mx-3 h-100">
    <label class="fw-bold field my-2 mo-1 me-3">Date</label>
    <p class="form-control p-2 text-left">du
      <strong>{{stage.dateStart | date: 'dd-MM-yyyy'}}</strong>
      au
      <strong>{{stage.dateEnd | date: 'dd-MM-yyyy'}}</strong>
      <br> de
      <strong>{{stage.hourStart}}h</strong>
      à
      <strong>{{stage.hourEnd}}h</strong>
      (<strong>{{getNumberOfDays()}}</strong> jours)</p>
  </div>

  <!--age-->
  <div class="form-group mx-3 d-flex align-items-center">
    <label class="fw-bold field my-2 mo-1 me-3">Age</label>
    <span class="form-control mb-0 p-2 text-center">
      <strong>{{stage.ageStart}}</strong>
      à
      <strong>{{stage.ageEnd}}</strong>
      ans
    </span>
  </div>

  <!--prix-->
  <div class="form-group mx-3 d-flex align-items-center">
    <label class="fw-bold field my-2 mo-1 me-3">Prix</label>
    <span class="form-control mb-0 p-2 text-center"><strong>{{stage.price}}€</strong></span>
  </div>

  <!--prix demi journée-->
  <div *ngIf="!ifNullHalfDayPrice()" class="form-group mx-3 d-flex align-items-center">
    <label class="fw-bold field my-2 mo-1 me-3 text-nowrap">Prix demi-journée</label>
    <span class="form-control mb-0 p-2 text-center"><strong>{{stage.halfDayPrice}}€</strong></span>
  </div>

  <!--lieux-->
  <div class="form-group mx-3 d-flex align-items-center">
<!--    <label class="fw-bold field my-2 mo-1 me-3 text-nowrap align-self-start">Lieu</label>-->
<!--    <i class="fa-solid fa-map-location-dot" >_CARTE_</i>-->
    <label class="fw-bold field my-2 mo-1 me-3 text-nowrap">Adresse : </label>
      <a class="grey_text form-control mb-0 p-2 text-center"
         href="https://maps.google.com/maps?q={{encodeURIComponent(stage.rue + ', ' + stage.numero + ', ' + stage.codePostal + ', ' + stage.localite)}}">
        {{stage.rue}}, {{stage.numero}} - {{stage.codePostal}} {{stage.localite}} - Belgique</a> <!--Pas le choix de hardcoder le pays, il n'est pas stocké en base de donnée (table LIEU)-->
  </div>

  <!-- s'inscrire au stage / FLOATING BUTTON-->
  <br class="mx-2">
  <a class="text-decoration-none shadow-lg">
    <app-floating-button
        [text]="'Je m\'inscris'"
        (onClick)="OnRegister()">
    </app-floating-button>
  </a>
  <br class="mx-2">

  <div id="stage-detail-floating"></div>

</main>
