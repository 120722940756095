import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {DataService} from "../../../service/data.service";
import {PopupModalComponent} from "../../common/popup-modal/popup-modal.component";
import {CustomValidators} from "../membre/input/CustomValidators";
import {map, Observable, startWith, tap} from "rxjs";

@Component({
  selector: 'app-creation-compte',
  templateUrl: './creation-compte.component.html',
  styleUrls: ['./creation-compte.component.css']
})

export class CreationCompteComponent implements OnInit, AfterViewInit {
  showPassword = false;
  showConfirmPassword = false;

  returnName: string = 'Retour';

  registerForm!:FormGroup;
  protected buttonStatus : 'loading' | 'idle' = 'idle';
  private loadingDialog!: MatDialogRef<any, any>;

  protected readonly onsubmit = onsubmit;
  protected readonly JSON = JSON;
  selectedLink: string;

  protected postCodeControl = new FormControl();
  protected postCodeData: PostCodeData[] = [];
  protected filteredPostCodeData: PostCodeData[] = [];

  constructor(private formBuilder : FormBuilder,
              private dataService: DataService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              private location: Location,
              private router: Router) {

    this.selectedLink = '/home';
    this.returnName = 'Accueil';
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      login: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100}), CustomValidators.email()
      ]],
      password: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      cpassword: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100}),
        CustomValidators.confirmPassword('password')
      ]],
      numNat: ['', [
        CustomValidators.numNat()
      ]],
      rue: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      numero: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      postCodeControl: this.postCodeControl,
      pays: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      nom1: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      prenom1: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      gsm1: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      telFixe: ['', [
        CustomValidators.maxLen({length: 100})
      ]],
      nom2: ['', [
        CustomValidators.maxLen({length: 100})
      ]],
      prenom2: ['', [
        CustomValidators.maxLen({length: 100})
      ]],
      gsm2: ['', [
        CustomValidators.maxLen({length: 100})
      ]],
      email2: ['', [
        CustomValidators.maxLen({length: 100}), CustomValidators.email()
      ]],
      newsletter: ['', []],
    });


    this.getAllPostCodeData().subscribe(() => {
      this.postCodeControl = this.formBuilder.control('', [
            CustomValidators.required(),
            CustomValidators.possibleValue(this.postCodeData)
        ]);
      this.registerForm.setControl('postCodeControl', this.postCodeControl);

      this.filterPostCodeData();
    });
  }

  protected arePosalCodesLoaded(): boolean {
    return this.postCodeData.length > 0;
  }

  private getAllPostCodeData(): Observable<PostCodeData[]> {
      return this.dataService.getAllPostCode().pipe(
          tap((data: PostCodeData[]) => {
              this.postCodeData = data;
          })
      );
  }

  private filterPostCodeData(): void {
    this.postCodeControl.valueChanges.pipe(  // Listening to value changes on the form control
      startWith(''),
      map(value => typeof value === 'string' ? this.filter(value) : this.postCodeData.slice())
    ).subscribe(filteredData => {
      this.filteredPostCodeData = filteredData;  // Assigning filtered data to local variable
    });
  }

  private filter(value: string): PostCodeData[] {
      const filterValue = value.toLowerCase();
    return this.postCodeData.filter(entry =>
      `${entry.LOCALITE.toLowerCase()} (${entry.CODE_POSTAL})`.includes(filterValue)
    );
  }

  ngAfterViewInit() {
  }

  validateForm(){
    // Mark all controls as touched to trigger validation messages
    this.registerForm.markAllAsTouched();

    // Check if any of the form controls have invalid validators
    const invalidControls = Object.keys(this.registerForm.controls).filter(controlName =>
      this.registerForm.get(controlName)?.invalid
    );

    this.registerForm.patchValue({
      numNat: (this.registerForm.get("numNat")?.getRawValue().replace(/[.\-\s]/g, '')),
      nom1: (this.registerForm.get('nom1')?.getRawValue().toUpperCase()),
      nom2: (this.registerForm.get('nom2')?.getRawValue().toUpperCase())
    })


    // If there are invalid controls, handle them accordingly
    if (invalidControls.length > 0) {
      // Handle the case when there are invalid controls
      console.error("Invalid validators found:", invalidControls);
      // You can show a message or perform any other action here
    } else {
      // All validators are valid, proceed with form submission
      this.submitForm();
    }
  }

  submitForm() {
    // Accès à toutes les valeurs des champs du formulaire
    const formValues = this.registerForm.value;

    // mise à jour des états
    this.submitted = true;
    this.buttonStatus = "loading";
    // CHARGEMENT
    this.loadingDialog = this.dialog.open(PopupModalComponent, {
      disableClose: true,
      panelClass: 'loading-dialog-overlay',
      data: {
        titre: 'Chargement',
        width: 100,
      },
    });

    // vérifie si le numéro du compte est stocké en local
    let data = localStorage.getItem('selectedCompte');

    this.sendRequestCreateAccount(data ? parseInt(data) : 0, formValues.login);

    // not sending a mail anymore
    //this.sendActivationEmail(formValues.login);

    // this.router.navigateByUrl('/activation-compte?login='+formValues.login)
  }

  protected readonly Object = Object;
  protected readonly console = console;
  protected readonly Array = Array;
  protected readonly Date = Date;
  protected submitted: boolean = false;

  protected getCurrentYear() : number {
    return new Date(Date.now()).getFullYear();
  }

  displayFn(entry: PostCodeData): string {
      // return entry ? `${entry.LOCALITE} (${entry.CODE_POSTAL}) - ${entry.NUMERO_CP}` : ''; // For debugging
    return entry ? `${entry.LOCALITE} (${entry.CODE_POSTAL})` : ''; // Normal behavior
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  private sendRequestCreateAccount(numCompte: number, loginEmail: string) {
  console.log(this.registerForm.value);
  // ENVOIE REQUETE VERS LE BACK-END
  this.dataService.createAccount(this.registerForm.value)
    .then((value) => {
      // CRÉATION AVEC SUCCÈS
      this.toastr.success( "Le compte a été créé avec succès. Merci d'activer votre compte via le mail que nous vous avons envoyé ! ", "Compte créé", {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        enableHtml: true,
        positionClass:"toast-top-full-width"
      });

      // redireciton
      this.router.navigateByUrl('/activation-compte?login='+loginEmail)
    })
    .catch(reason => {
      this.toastr.error( reason.message, "Une erreur s'est produit", {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        enableHtml: true,
        positionClass:"toast-top-full-width"
      });
    })
    .finally(() => {
      setTimeout(() => {
        this.submitted = false;
        this.buttonStatus = "idle";
      }, 2000);
      this.loadingDialog.close();
    });
  }

  // sendActivationEmail($login: string): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     this.dataService
  //       .sendActivationMail($login)
  //       .subscribe(
  //         (response) => {
  //           // Handle success if needed
  //           resolve();
  //         },
  //         (error) => {
  //           // Handle error if needed
  //           reject(error);
  //         }
  //       );
  //   });
  // }

}

interface PostCodeData {
    NUMERO_CP: number;
    CODE_POSTAL: string;
    LOCALITE: string;
}
