import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from "@angular/router";

@Component({
  selector: 'app-return-link',
  templateUrl: './return-link.component.html',
  styleUrls: ['./return-link.component.css']
})
export class ReturnLinkComponent {
  @Input() route?: string;
  @Input() text!: string;
  @Output() onButtonClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private location: Location, private router: Router) {
  }

  goBack() {
    if (this.route) {
        this.onButtonClick.emit();
        this.router.navigate([this.route]);
    } else {
      this.location.back();
      this.onButtonClick.emit();
    }
  }
}
