import {AfterViewInit, Directive, ElementRef, HostBinding, Input, OnInit} from '@angular/core';
import {AbstractControl, NgControl} from "@angular/forms";
@Directive({
  selector: '[appFieldError]',
})
export class FieldErrorDirective implements OnInit {
  private _formController!: AbstractControl;
  @Input() set appFieldError(value: AbstractControl) {
    this._formController = value;
  };

  @Input() set formName(value: string) {
    this.input.setAttribute('formControlName', value)
  }

  @HostBinding('class.is-invalid') get isInvalid(): boolean {
    return this._formController!.touched! && this._formController!.invalid!;
  };

  constructor(private elem: ElementRef) {
    this.addClasses();
  }

  addClasses(): void {
    let type = this.input.getAttribute('type');
    if (type == 'radio') {
      this.input.classList.add('form-check-input');
    } else {
      this.input.classList.add('form-control');
    }
  }

  ngOnInit() {

  }

  get input(): any {
    return this.elem.nativeElement;
  }
}
