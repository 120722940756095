import {Component, Inject} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "../../../service/data.service";
import {Md5} from "ts-md5";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-activation-compte',
  templateUrl: './activation-compte.component.html',
  styleUrls: ['./activation-compte.component.css']
})
export class ActivationCompteComponent {

  activated: boolean = false;

  login: string = "";
  activation: string = "";

  constructor(private route: ActivatedRoute, private dataservice: DataService, private router : Router, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.login = params['login'] || "";
      this.activation = params['activation'] || "";
    });

    if(this.activation == ""){
      let token = this.generateToken(this.login);
      this.sendActivationEmail(this.login, token)
        .then(() => {console.log("Email envoyé")})
        .catch(() => {console.log("Erreur lors de l'envoi de l'email")});
    }

    if(this.login != "" && this.activation != "" && this.checkToken(this.login, this.activation)) {
      this.dataservice.activateAccount(this.login, this.activation).subscribe(
        response => {
        console.log(response);
        if (response.status === '200') {
          this.activated = true;
        }else { }
      }, error => { });
    }else{
      console.log("Données d'activation invalides");
    }



  }

  generateToken(login: string): string {
    // Utilisez l'adresse e-mail de l'utilisateur et une méthode de génération aléatoire
    const randomPart = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const hashedLogin = Md5.hashStr(login); // Hachez l'adresse e-mail avec MD5
    return hashedLogin + randomPart; // Combinez la partie hachée avec la partie aléatoire
  }

  checkToken(login: string, token: string): boolean {
    const hashedLogin = Md5.hashStr(login); // Hachez l'adresse e-mail fournie
    const extractedLogin = token.substring(0, hashedLogin.length); // Extrait la partie du token qui devrait contenir l'adresse e-mail
    return hashedLogin === extractedLogin; // Vérifie si l'adresse e-mail extraite correspond à celle fournie
  }

  sendActivationEmail(login: string, token :string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataservice
        .sendActivationMail(login, token, this.document.location.origin)
        .subscribe(
          (response) => {
            // Handle success if needed
            resolve();
          },
          (error) => {
            // Handle error if needed
            reject(error);
          }
        );
    });
  }

  goBackHome() {
    this.router.navigate(['/home']);
  }
}
