<main>
  <div class="back_element mx-3">
    <i class="fa-solid fa-chevron-left"></i>
    <a routerLink="/cours/{{this.cours.id}}" class="link-secondary m-1 fw-bolder" (click)="removeSelectedCours()">Detail du cours</a>
  </div>

  <h1 class="text-center font-weight-bold">Inscription : Etape 1/3</h1>
  <h2 class="text-center">{{this.coursName}}</h2>

  <hr class="section-separator">

  <h2 class="text-center font-weight-bold">Sélectionner un membre</h2>

  <br />

  <div class="d-flex flex-column align-items-center">
    <app-selection-list *ngIf="!isChargement" [items]="members" [age]="age" [alreadyBusy]="alreadyBusy" (selectedMember)="handleSelectedMember($event)"></app-selection-list>
  </div>

  <div class="chargement" *ngIf="isChargement">
    <p>Chargement des membres.</p> <span class=" spinner-border spinner-border-sm" ></span>
  </div>

  <br />

  <p class="text-center m-3">
    Je ne trouve pas de membre dans la liste déroulante :
    <br />
    <a routerLink="/recherche-membre" class="text-decoration-none" >rechercher le membre</a>
    ou
    <a routerLink="/inscription.cree-membre" class="text-decoration-none" >ajouter un membre</a>
  </p>

  <br />

  <button
    class="shadow btn ds-btn-confirmation"
    [ngClass]="{ 'btn-outline-success': allowNext, 'button_disabled': !allowNext }"
    (click)="handleClick()"
    [disabled]="!allowNext">Vers étape 2/3 &#8594;</button>
</main>
