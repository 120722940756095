import {Periode} from "./Periods";

export class PeriodeDate {
  periode: Periode;
  startDay: number;
  startMonth: number;
  endDay: number;
  endMonth: number;

  constructor({periode = Periode.NOEL_NA, startDay = 1, startMonth = 12, endDay = 31, endMonth = 1}: Partial<PeriodeDate> = {}) {
    this.periode = periode;
    this.startDay = startDay;
    this.startMonth = startMonth;
    this.endDay = endDay;
    this.endMonth = endMonth;
  }
}


/**
 * Définit les différentes périodes
 */
export const periodeDates: PeriodeDate[] = [
  new PeriodeDate({
    periode: Periode.CARNAVAL,
    startDay: 1,
    startMonth: 2,
    endDay: 31,
    endMonth: 3
  }),
  new PeriodeDate({
    periode: Periode.PRINTEMPS,
    startDay: 1,
    startMonth: 4,
    endDay: 31,
    endMonth: 5
  }),
  new PeriodeDate({
    periode: Periode.ETE,
    startDay: 1,
    startMonth: 6,
    endDay: 30,
    endMonth: 9
  }),
  new PeriodeDate({
    periode: Periode.TOUSSAINT,
    startDay: 1,
    startMonth: 10,
    endDay: 30,
    endMonth: 11
  }),
  new PeriodeDate({
    periode: Periode.NOEL_NA,
    startDay: 1,
    startMonth: 12,
    endDay: 31,
    endMonth: 1
  })
];

  /**
   * Récupère l'objet "periodDate" courante ainsi que le suivant (dans un tableau de 2 éléments)
   * @param date la date actuelle
   * @return [PeriodeDate, PeriodeDate] exemple [été, toussain]
   */
  export function getCurrentAndNextPeriode(date: Date): PeriodeDate[] {
    const currentMonth = (date.getMonth() + 1) % 12; // Les mois sont indexés à partir de 0

    const currentPeriode = periodeDates.find(periode => {
      return periode.startMonth % 12 <= currentMonth && currentMonth <= periode.endMonth % 12
    });
    const currentPeriodeIndex = periodeDates.indexOf(currentPeriode!);
    const nextPeriode1Index = (currentPeriodeIndex + 1) % periodeDates.length;
    const nextPeriode2Index = (currentPeriodeIndex + 2) % periodeDates.length;

    const nextPeriode1 = periodeDates[nextPeriode1Index];
    const nextPeriode2 = periodeDates[nextPeriode2Index];

    //console.log([currentPeriode!, nextPeriode1, nextPeriode2])
    //console.log(nextPeriodeIndex)
    return [currentPeriode!, nextPeriode1, nextPeriode2];
  }


  /**
   * retourne un objet periodDate en fonction du nom de la période
   * @param periodeName le nom d'une période
   */
  export function getPeriodeByName(periodeName: string): PeriodeDate {
    const periodeEnumValues = Object.values(Periode);
    const periode = periodeEnumValues.find(value => value.toString() === periodeName);

    return periodeDates.find(date => date.periode === periode)!;
  }

  /**
   * retourne un objet PeriodeDate sur base d'une date fournie
   * @param date
   */
  export function getPeriodeByDate(date: Date): PeriodeDate | undefined {
    const currentMonth = (date.getMonth() + 1) % 12; // Les mois sont indexés à partir de 0

    return periodeDates.find(periode => {
      return periode.startMonth % 12 <= currentMonth && currentMonth <= periode.endMonth % 12;
    });
  }


