import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(private updates: SwUpdate) {
    console.log('UpdateService initialized:', updates);

    if (this.updates.isEnabled) {
      console.log('Service Worker is enabled.');

      // Vérification périodique des mises à jour toutes les 6 heures
      interval(6 * 60 * 60 * 1000).subscribe(() =>
        this.updates
          .checkForUpdate()
          .then(() => console.log('Checking for updates...'))
          .catch(err => console.error('Error checking updates:', err))
      );

      // Abonnement à versionUpdates
      this.updates.versionUpdates.subscribe({
        next: event => {
          console.log('Version update event:', event);
          switch (event.type) {
            case 'VERSION_READY':
              console.log('Nouvelle version disponible :', event.latestVersion);
              console.log('Version actuelle :', event.currentVersion);
              this.promptUser();
              break;
            case 'VERSION_INSTALLATION_FAILED':
              console.error('Échec de l’installation de la mise à jour:', event.error);
              break;
            case 'NO_NEW_VERSION_DETECTED':
              console.log('Aucune nouvelle version détectée.');
              break;
            default:
              console.log('Événement inattendu:', event);
          }
        },
        error: err => console.error('Error in versionUpdates:', err),
      });
    } else {
      console.warn('Service Worker is not enabled.');
    }
  }

  private promptUser(): void {
    console.log('Prompting user to update to the new version.');
    this.updates
      .activateUpdate()
      .then(() => {
        console.log('New version activated, reloading page...');
        document.location.reload();
      })
      .catch(err => console.error('Error activating update:', err));
  }
}
