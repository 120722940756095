import {debounceTime, filter, Observer, Subject, tap} from "rxjs";
import * as he from "he";
import {LoadingStatus} from "../../../models/FormLoadingStatus";
import {Paginator} from "../../../models/Paginator";
import {CoursComponent} from "./cours.component";
import {CoursFiltersLocalStorage} from "./CoursFiltersLocalStorage";
import * as G from './CoursGlobalValues';
import {CourseCard} from "../../../models/CourseCard";

const {LOADING, LOADED, TIME_OUT, NET_ERROR, ERROR} = LoadingStatus
;

export class CoursObserverUtil  {

  /**
   * Initialise un observable qui observe les sélections des semaines par l'utilisateur et envoie des requêtes pour
   * charger les lieux, avec un petit délai pour éviter l'envoie plusieurs requêtes aux cliques rapides.
   * L'etat de la vue est mise à jour pour afficher le chargement, données, messages. Après l'évènement, les lieux
   * sont chargées et les données locales et attributs sont mises à jour.
   * @private
   */
  static initPlacesObserver(this: CoursComponent) {
    // OBSERVABLE DES DEMANDES DE CHARGEMENT DE Semaines
    this.Stream.places$ = new Subject<string>().pipe(
      tap(() => {
        // 1. état chargement de lieux
        this.loadingStatus.places = LOADING;
      }),
      debounceTime(G.REQUEST_DELAY),
    );

    this.Stream.places$.subscribe((signalType: string) => {
      this.fetchPlaces()
        .then(() => {
          console.log(signalType)
          this.Stream.filteredCourses$.next(signalType??'filter-places')
        });
    })
  }

  static initCourseNamesObserver(this: CoursComponent) {

    this.Stream.courseNames$ = new Subject<string>().pipe(
      tap(() => {
        this.loadingStatus.courseNames = LOADING;
      }),
      debounceTime(G.REQUEST_DELAY),
    );

    this.Stream.courseNames$.subscribe((signalType: string) => {
      this.fetchCourseNames()
        .then(() => {
          console.log(signalType)
          this.Stream.filteredCourses$.next(signalType??'filter-course-names')
        });
    })
  }

  /**
   * initialise un flux qui observe constament les filtres pour charger les stages quand toutes les conditions sont
   * respectés.
   * @private
   */
  static initCoursesObserver(this: CoursComponent) {
    let allowedForRunFetch = ['paginator', 'confirm-button', 'start-event'];

    // OBSERVABLE DES DEMANDES DE CHARGEMENT DE STAGES
    this.Stream.filteredCourses$ = new Subject<any>().pipe(
      // si les filtres autos sont activées, laisse passé les évènements qui déclenche l'application des filtres comme
      // changer l'age, sélectionner les semaines, les lieux
      filter((value) => (allowedForRunFetch.includes(String(value)) ? true : this.Model.isAutoApplyFilters)),
      tap((value) => {
        this.Model.stagesCalledByButton = allowedForRunFetch.includes(String(value));
        if (value !== 'paginator') {
          this.loadingStatus.courses = LOADING;
          this.paginator.total = undefined;
          this.paginator.cursor = 0;
        }
      }),
      debounceTime(G.REQUEST_DELAY),
      tap((value) => {
        this.clearCourses();
        if (value === 'paginator') {
          this.paginator = new Paginator(this.paginator.quantite, this.paginator.cursor, this.paginator.total);
        }
        this.loadingStatus.courses = this.Model.isValidFilter() ? LOADING : LOADED;
      }),
      filter(() => this.Model.isValidFilter()),
    );

    this.Stream.filteredCourses$.subscribe(() => {
      CoursObserverUtil.fetchFilteredCourses.call(this);
    })
  }
//----------------------
// objet subscribe

  /**
   * Génère un objet observeur pour la méthode subscribe, avec l'action à éffectuer au moment de l'appel de l'évènement.
   * @param type
   * @param resolve
   * @param reject
   * @private
   */
  static getSubscribePlaces(this: CoursComponent, type: string, resolve: () => void, reject: (err:Error) => void): Partial<Observer<Object>> | ((value: Object) => void) {
    return {
      next: (res) => {
        if (Array.isArray(res) && res.length > 0) {

          // Ajoute les valeurs à la Map Places
          let localplaces = CoursFiltersLocalStorage.loadFromLocal()[3]||new Map();
          this.Model.places.clear();
          res.forEach((place: string) => this.Model.places.set(place , localplaces.get(place) || false));
          // Trie les Lieux
          this.Model.places = new Map([...this.Model.places.entries()].sort());
        }

        this.updatePlacesMultiselect();
        resolve();
      },
      error: (error) => {
        reject(error);
      }
    };
  }


  static getSubscribeCourseNames(this: CoursComponent, type: string, resolve: () => void, reject: (err:Error) => void): Partial<Observer<Object>> | ((value: Object) => void) {
    console.log("getSubscribeCourseNames");

    return {
      next: (res) => {
        if (Array.isArray(res) && res.length > 0) {

          let localnames = CoursFiltersLocalStorage.loadFromLocal()[5]||new Map();
          this.Model.courseNames.clear();
          res.forEach((name: string) => this.Model.courseNames.set(he.decode(name), localnames.get(name) || false));

          // Trie les noms
          this.Model.courseNames = new Map([...this.Model.courseNames.entries()].sort());
        }

        this.updateCourseNamesMultiselect();
        resolve();
      },
      error: (error) => {
        reject(error);
      }
    };
  }



  /**
   * Génère un objet observeur pour la méthode subscribe, avec l'action à éffectuer au moment de l'appel de l'évènement.
   * @param resolve
   * @param reject
   * @private
   */
  static getSubscribeCourses(this: CoursComponent, resolve: () => void, reject: (err:Error) => void) :  Partial<Observer<any>> | ((value: any) => void) {
    return {
      next: (res) => {
        let courses: any[] = [res][0][0];
        let total: number = [res][0][1];
        if (res && courses.length > 0) {
          let instances = courses;
          this.paginator.total = total;
          // création de liste d'objet stages
          this.Model.filteredCourses = CoursObserverUtil.getInstances(instances);
        }
        resolve()
      },
      error: (error) => {
        reject(error)
      }
    }
  }

   private static updatePagination(this: CoursComponent, total: number) {
    if (total !== this.paginator.total) {
      this.paginator.cursor = 0;
      this.paginator.total = total
    }
  }

  /**
   * Méthode permetant de créer une liste d'objet Stage servant à l'affichage dans la vue.
   * @param instances
   * @private
   */
  private static getInstances(instances: any) {
    return instances.map((jsonItem: any) => {
      let nom = he.decode(jsonItem.NOM);
      let courseCard =  new CourseCard(
        jsonItem.NUMERO_INSTANCE_COURS,
        nom,
        jsonItem.TYPE,
        jsonItem.LOCALITE,
        jsonItem.NUMERO_LIEU,
        jsonItem.JOUR,
        jsonItem.HEURE,
        Math.round(jsonItem.AGE_MIN) + " - " + Math.round(jsonItem.AGE_MAX) + ' ans',
        jsonItem.REMAINING_SLOTS,
        jsonItem.FORCER_COMPLET === 1,
        jsonItem.FORCER_COMPLET_SEM1 === 1,
        jsonItem.FORCER_COMPLET_SEM2 === 1,
        jsonItem.FORCER_COMPLET_TRIMESTRE === 1,
        jsonItem.FORCER_COMPLET_MOIS === 1,
        jsonItem.SAISON
      );

      return courseCard;
    });
  }

  private static getCourseType(courseCard: CourseCard) {
    if(courseCard.PlaceNum === 33 || courseCard.PlaceNum === 41){
      return courseCard.Type = 'Piscine';
    }else if(courseCard.Title.toLowerCase().includes('escalade')){
      return courseCard.Type = 'Escalade';
    }else{
      return courseCard.Type = 'Cours terrestre';
    }
  }

  //Récupère les instance de stage via api Rest en fonction des filtres
  private static fetchFilteredCourses(this:CoursComponent) {
    let encodedCourseNames = this.Model.selectedCourseNames.map((string: string) => he.decode(string));
    encodedCourseNames = encodedCourseNames.map((string: string) => he.encode(string, {useNamedReferences : true}));
    console.log(this.Model.selectedCourseNames); // Logs the array of encoded strings
    console.log(encodedCourseNames); // Logs the array of encoded strings
    new Promise<void>((resolve, reject) => {
      this.dataService.getFilteredCourses(
        this.Model.selectedType,
        this.Model.selectedDays,
        this.Model.selectedPlaces,
        this.Model.ageRange.toArray(),
        this.paginator,
        encodedCourseNames
    )
        .subscribe(CoursObserverUtil.getSubscribeCourses.call(this, resolve, reject));
    })
      .then(() => {
        this.zone.run(() => {
          this.loadingStatus.courses = LOADED;
        });
      })
      .catch(error => {
        this.loadingStatus.courses = ERROR;
        if (error.name === 'TimeoutError') {
          this.loadingStatus.courses = TIME_OUT;
        }
        if (error instanceof TypeError) {
          this.loadingStatus.courses = NET_ERROR;
        }
      });
  }
}
