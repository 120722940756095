export class RadioElement {


    nom: string;
    readonly value: string;
    isChecked: boolean = false;
    canBeChecked: boolean = true;

    constructor(nom: string, value: string, isChecked: boolean = false, canBeChecked: boolean = true) {
        this.nom = nom;
        this.value = value;
        this.isChecked = isChecked;
        this.canBeChecked = canBeChecked;

    }
}
