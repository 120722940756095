<select class="scrollable-list" (change)="handleSelection($event)">
  <option value="" disabled selected>Choisir un membre</option>
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="isAgeInRange(item.numero_personne, item.age_start_millis); else ageNotAppropriate">
      <ng-container *ngIf="!isAlreadyBusy(item.numero_personne); else itemNotAvailable">
        <option [value]="item">
          {{ item }} ({{ item.getAge() }} ans)
        </option>
      </ng-container>
    </ng-container>
    <ng-template #ageNotAppropriate>
      <option [value]="item" disabled>
        {{ item }} ({{ item.getAge() }} ans) - Âge non approprié
      </option>
    </ng-template>
    <ng-template #itemNotAvailable>
      <option [value]="item" disabled>
        {{ item }} ({{ item.getAge() }} ans) - Déjà inscrit à une autre activité
      </option>
    </ng-template>
  </ng-container>
</select>
