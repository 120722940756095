<section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
  <span class="field2-section d-block mb-3">{{title}}</span>
  <!-- MESSAGE DE STATUS SEMAINES-->
  <span class="ds-message" [ngClass]="status.classes">
      <div *ngIf="status.isLoading" class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    {{status.message}}</span>

  <!-- affichage semaines-->
  <div *ngIf="!status.isLoading" class="weeks list-group d-flex flex-wrap align-items-start flex-row  container-fluid">
    <button *ngFor="let record of values | keyvalue" class="app-list-button list-group-item active text-left shadow-sm" style="flex-grow: 2; width: 20em;" [class.active]="record.value" (click)="onRecordClick.emit({key: record.key, value: !record.value})">
      <div class="row">
        <div class="pr-0" style="width: 40%">
          <span class="fw-bold px-0">{{weekNameToString(record.key)}}</span>
        </div>
        <div class="float-right px-0" style="width: 60%">
          <span class="px-0">{{weekDateToString(record.key)}}</span>
        </div>
      </div>
    </button>
    <button *ngFor="let i of emptySpaceObjects"  class="app-list-button list-group-item active text-left shadow-sm invisible" style="flex-grow: 2; width: 20em; ">EMPTY SPACE</button>
  </div>
</section>
