<!--bouton retour-->
<app-return-link
  [text]="returnName"
  [route]="selectedLink">
</app-return-link>

<h1 class="text-center font-weight-bold mt-4">Création d'un <br> nouveau compte DS</h1>

<hr class="section-separator">

<!--FORMULAIRE-->
<form *ngIf="registerForm" novalidate class="m-3 needs-validation" [formGroup]="registerForm" (ngSubmit)="validateForm()">

  <h3 class="m-3 fw-semibold mt-4 blue">Informations de connexion*</h3>

  <ng-container *ngTemplateOutlet="field; context: {name : 'login', type: 'email', label : 'E-mail*', placeholder: ''}" />

  <ng-container class=" -webkit-text-security: circle" *ngTemplateOutlet="passwordField; context:
  {name : 'password', type : 'password', label : 'Mot de passe*', placeholder: ''}" />

  <ng-container class=" -webkit-text-security: circle" *ngTemplateOutlet="confirmPasswordField; context:
  {name : 'cpassword', type : 'password', label : 'Confirmation du mot de passe*', placeholder: ''}" />

  <h3 class="m-3 fw-semibold mt-4 blue">Numéro national</h3>

  <ng-container *ngTemplateOutlet="numnat; context:
    {name : 'numNat', label: 'Nécessaire pour les attestations*', placeholder: '04.11.15-221.12'}">
  </ng-container>

  <h3 class="m-3 fw-semibold mt-4 blue">Adresse</h3>

  <ng-container *ngTemplateOutlet="field; context: {name : 'rue', label : 'Rue*', placeholder: ''}" />

  <ng-container *ngTemplateOutlet="field; context: {name : 'numero', label : 'Numéro*', placeholder: ''}" />

  <div class="form-group">
    <label [for]="postCodeControl" class="fw-normal">Localité*</label>
    <input type="text" class="form-control ng-pristine ng-valid ng-touched" [placeholder]="arePosalCodesLoaded()?'Localité ou code postal':'Chargement des codes postaux...'" matInput [formControl]="postCodeControl" [matAutocomplete]="auto" [readonly]="!arePosalCodesLoaded()">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let entry of filteredPostCodeData" [value]="entry">
        {{ entry.LOCALITE }} ({{ entry.CODE_POSTAL }})
      </mat-option>
    </mat-autocomplete>
  </div>

  <p><i class="grey_text">Si vous n'habitez pas en Belgique, sélectionnez l'option  'Non repris dans la liste (0000)'.</i></p>

  <ng-container *ngTemplateOutlet="field; context: {name : 'pays', label : 'Pays*', placeholder: ''}" />

  <h3 class="m-3 fw-semibold mt-4 blue">Personne de contact</h3>

  <ng-container *ngTemplateOutlet="nom; context: {name : 'nom1', label : 'Nom*', placeholder: ''}" />

  <ng-container *ngTemplateOutlet="field; context: {name : 'prenom1', label : 'Prénom*', placeholder: ''}" />

  <ng-container *ngTemplateOutlet="numTelMobile; context: {name : 'gsm1', label : 'GSM*', placeholder: ''}" />

  <ng-container *ngTemplateOutlet="numTelFixe; context: {name : 'telFixe', label : 'Téléphone fixe', placeholder: ''}" />


  <h3 class="m-3 fw-semibold mt-4 blue">2e Personne de contact (optionnel)</h3>

  <ng-container *ngTemplateOutlet="nom; context: {name : 'nom2', label : 'Nom', placeholder: ''}" />

  <ng-container *ngTemplateOutlet="field; context: {name : 'prenom2', label : 'Prénom', placeholder: ''}" />

  <ng-container *ngTemplateOutlet="numTelMobile; context: {name : 'gsm2', label : 'GSM', placeholder: ''}" />

  <ng-container *ngTemplateOutlet="field; context: {name : 'email2', label : 'E-mail', placeholder: ''}" />

  <hr class="section-separator">

  <ng-container *ngTemplateOutlet="checkboxField; context: {name : 'newsletter', label : 'Je souhaite recevoir la newletter', placeholder: ''}" />

  <!--BOUTON ENVOYER-->
<!--
  <app-floating-button text="créer un compte" [disabled]="!registerForm.valid || buttonStatus === 'loading'"/>
-->
<!--  <button>Créer un compte</button>-->
  <app-floating-button text="Créer votre compte"
                       [disabled]="!registerForm.valid || buttonStatus === 'loading'"
                       [isGreen]="false"/>

  <br><br>
  <!--
    <hr>

    <p>Dirty : {{registerForm.dirty}}</p>
    <p>Valid : {{registerForm.valid}}</p>
    <pre>Value : {{registerForm.value | json}}</pre>
  -->



  <!--|=========|-->
  <!--|TEMPLATES|-->
  <!--|=========|-->

  <!--CHAMPS-->
  <ng-template #field let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
    <label [for]="name" class="fw-normal">{{label??name}}</label>

    <input [type]="type" [id]="name" [formName]="name"
           [formControlName]="name" [appFieldError]="registerForm.get(name)!"
           [placeholder]="placeholder??''" >
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div>
  </ng-template>

  <!--  NUMERO DE TELEPHONE MOBILE -->
  <ng-template #numTelMobile let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
    <label [for]="name" class="fw-normal">{{label??name}}</label>

    <input [type]="type" [id]="name" [formName]="name"
           [formControlName]="name" [appFieldError]="registerForm.get(name)!"
           [placeholder]="placeholder??''" [value]="registerForm.get(name)?.value | numeroTelephoneMobile" >
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div>
  </ng-template>
  <!--  NUMERO DE TELEPHONE FIXE -->
  <ng-template #numTelFixe let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
  <label [for]="name" class="fw-normal">{{label??name}}</label>

  <input [type]="type" [id]="name" [formName]="name"
         [formControlName]="name" [appFieldError]="registerForm.get(name)!"
         [placeholder]="placeholder??''" [value]="registerForm.get(name)?.value | numeroTelephoneFixe" >
  <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
  <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
</div>
</ng-template>

  <ng-template #numnat let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
    <label [for]="name" class="fw-normal">{{label??name}}</label>

    <input [type]="type" [id]="name" [formName]="name"
           [formControlName]="name" [appFieldError]="registerForm.get(name)!"
           [placeholder]="placeholder??''" [value]="registerForm.get(name)?.value | nationalNumber">
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

  <ng-template #nom let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
    <label [for]="name" class="fw-normal">{{label??name}}</label>

    <input [type]="'text'" [id]="name" [formName]="name"
           [formControlName]="name" [appFieldError]="registerForm.get(name)!"
           [placeholder]="placeholder??''" [value]="registerForm.get(name)?.value | uppercase">
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

  <!--TEXTAREA-->
  <ng-template #textarea let-name="name" let-label="label" let-placeholder="placeholder" let-height="height"><div class="form-group">
    <label [for]="name" class="fw-normal">{{label??name}}</label>
    <textarea [placeholder]="placeholder??''" [id]="name" [formName]="name"
              [formControlName]="name" [appFieldError]="registerForm.get(name)!"
              [style]="{height: (height??6)+'em'}">
    </textarea>
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

  <!--RADIOLIST-->
  <ng-template #radioList let-name="name" let-label="label" let-values="values"><div class="form-group">
    <span class="fw-normal mb-1 d-flex">{{label??name}}</span>
    <div class="card p-3 form-group flex-row justify-content-evenly">
      <div *ngFor="let v of values" class="form-check d-flex mb-0">
        <input  type="radio" [value]="v.value" [id]="name+'_'+v.value"
                [formControlName]="name" [formName]="name" [appFieldError]="registerForm.get(name)!" >
        <label [for]="name+'_'+v.value" class="form-check-label" [title]="v.label">{{v.label}}</label>
      </div>
    </div>
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

  <!--NUMBER-FIELD-->
  <ng-template #numfield let-name="name" let-min="min" let-max="max" let-label="label" let-placeholder="placeholder"><div class="form-group">
    <label [for]="name" class="fw-normal">{{label??name}}</label>

    <input type="number" [min]="min??0" [max]="max??-1" [id]="name"
           [formControlName]="name" [formName]="name" [appFieldError]="registerForm.get(name)!"
           [placeholder]="placeholder??''">
    <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

  <!-- Checkbox Field Template -->
  <ng-template #checkboxField let-name="name" let-label="label">
    <div class="form-check">
      <input type="checkbox" class="form-check-input" [id]="name" [formControlName]="name">
      <label class="form-check-label" [for]="name">{{label ?? name}}</label>
    </div>
  </ng-template>

  <!-- Password Field Template -->

  <ng-template #passwordField let-name="name" let-label="label" let-placeholder="placeholder">
    <div class="form-group">
      <label [for]="name" class="fw-normal">{{ label ?? name }}</label>
      <div class="input-group">
        <input
          [type]="showPassword ? 'text' : 'password'"
          [id]="name"
          [formName]="name"
          [formControlName]="name"
          [appFieldError]="registerForm.get(name)!"
          [placeholder]="placeholder ?? ''"
          class="form-control"
        >
        <span class="input-group-text" (click)="togglePasswordVisibility()" style="cursor: pointer;">
          <i class="fas" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
        </span>
      </div>
      <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted" />
    </div>
  </ng-template>
  <ng-template #confirmPasswordField let-name="name" let-label="label" let-placeholder="placeholder">
    <div class="form-group">
      <label [for]="name" class="fw-normal">{{ label ?? name }}</label>
      <div class="input-group">
        <input
          [type]="showConfirmPassword ? 'text' : 'password'"
          [id]="name"
          [formName]="name"
          [formControlName]="name"
          [appFieldError]="registerForm.get(name)!"
          [placeholder]="placeholder ?? ''"
          class="form-control"
        >
        <span class="input-group-text" (click)="toggleConfirmPasswordVisibility()" style="cursor: pointer;">
          <i class="fas" [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
        </span>
      </div>
      <app-error-message-for-field [control]="registerForm.get(name)!" [submitted]="submitted" />
    </div>
  </ng-template>

</form>
