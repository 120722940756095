<div class="menu-container">
    <div>
        <div class="menu-toggle" (click)="toggleMenu()">
            <span class="field2-section d-block mb-2">Informations Médicales</span>
            <span class="arrow" [class.up]="isMenuOpen">&#9660;</span>
        </div>
        <ul class="aboveList" [ngClass]="{'d-none':isMenuOpen}" *ngIf="!isLoading">
            <li>
                <label>Médecin traitant :</label>
                <p>{{medecinNom | fIRSTNAMESurname }}</p>
            </li>
        </ul>
        <ul class="aboveList" *ngIf="isLoading">
            <li>
                <label>Chargement des informations ...</label>
                <div class="loading-spinner"></div>
            </li>
            <li *ngIf="error">
                <label>Il semble avoir eu une erreur lors du chargement des informations, essayez de recharger la
                    page.</label>
            </li>
        </ul>
    </div>
    <ul class="menu" [class.active]="isMenuOpen">
        <li>
            <label for="medecinNom">Médecin traitant :</label>
            <input [(ngModel)]="medecinNom" id="medecinNom" type="text" placeholder="Ex: DUPONT Alice"
                   value="{{medecinNom | fIRSTNAMESurname}}" (blur)="sendDataToParent()" required>
        </li>
        <li>
            <label for="medecinTel">Numéro de téléphone :</label>
            <input [(ngModel)]="medecinTel" id="medecinTel" type="tel" placeholder="Ex: 0495 12 34 56"
                   (blur)="sendDataToParent()" value="{{medecinTel}}" required>
        </li>
        <li>
            <label for="medecinTetanos">Année du dernier rappel tétanos :</label>
            <input [ngModel]="this.medecinTetanos" (ngModelChange)="medecinTetanos = $event"
                   id="medecinTetanos" type="number" min="2000" max="{{this.annee}}" (blur)="sendDataToParent()" placeholder="Pas indiqué">
        </li>
        <li>
            <label for="medecinAllergies">Allergies :</label>
            <textarea [(ngModel)]="medecinAllergies" id="medecinAllergies"
                      placeholder="Alimentaires, animaux, médicaments... (à compléter uniquement si information utilse, laissez vide sinon)."
                      (blur)="sendDataToParent()"></textarea>
        </li>
        <li>
            <label for="medecinMedicaments">Médicaments :</label>
            <textarea [(ngModel)]="medecinMedicaments" id="medecinMedicaments"
                      placeholder="Médicaments à prendre pendant le stage (à compléter uniquement si information utilse, laissez vide sinon)."
                      (blur)="sendDataToParent()"></textarea>
        </li>
        <li>
            <label for="medecinDonneesSpe">Données médicales spécifiques :</label>
            <textarea
                    [(ngModel)]="medecinDonneesSpe"
                    id="medecinDonneesSpe"
                    placeholder="Problèmes cardiaques, épilepsie, asthme, diabète, mal des transports, rhumatisme, somnambulisme, affections cutanées, handicap moteur ou mental, interventions médicals... (à compléter uniquement si information utilse, laissez vide sinon)."
                    class="big"
                    (blur)="sendDataToParent()">
          </textarea>
        </li>
    </ul>
</div>
